<template>
  <!-- 2022-08-18 / 정태화 / 전체확대시 레이아웃 깨져 관련 태그 수정  -->
  <div :style="getTheme" class="header">
    <!-- 상단 메뉴 네비게이션 영역 -->
    <Navigation ref="navigation"
                :web_mode="web_mode"
    ></Navigation>
  </div>
  <div :style="getTheme" class="container-fluid content-area clearfix ">
    <div class="row dotcanvas-content">
      <!-- Toolbar 컴포넌트 영역 -->
      <Toolbar ref="toolbar" :main-color="mainColor" :sub-color="subColor"></Toolbar>
      <main class="container column-wrapper">
        <div class="left-column" v-if="OPTIONS.showPage">
          <!--PageItem 컴포넌트 -->
          <PageItem ref="pageItems" v-model="pages" :cur-page="curPage" :pages="pages"
                    @load-page="loadPage"
                    @load-page-after-delete="loadPageAfterDelete"
                    @load-page-after-move="loadPageAfterMove"
                    @add-page="addNewPage()">
          </PageItem>
        </div>
        <div class="main-right-column">
          <div class="main-column" :class="COL_CENTER_SIZE">
            <!-- Fabric Canvas -->
            <div class="whiteboard d-flex justify-content-center align-items-center">
              <!--그리드 캔버스-->
              <canvas id="grid" ref="grid"></canvas>
              <!-- 점자 캔버스 -->
              <canvas id="braille" ref="braille"></canvas>
              <!-- 픽셀 캔버스 -->
              <canvas id="board" ref="board"></canvas>
            </div>
          </div>
          <div class="right-column col-lg-2" v-if="OPTIONS.showText">
            <!--  대체 텍스트 영역 -->
            <div class="d-block description-title">
              <span>{{ $t('대체텍스트') }} </span>
            </div>

            <textarea id="altText" v-model="altText"
                      class="form-control form-control-sm description-textarea"
                      name="altText" :placeholder="$t('텍스트 추가')"
                      @change="setModified"
                      @focus="removeAllEventListener"
                      @blur="addHotkeyEventListeners"
            ></textarea>

            <!--  점자 표시 영역 -->
            <div class="d-block" v-if="OPTIONS.showBrailleBtn">
              <!-- FIXME -->
              <button class="btn btn-light braille-translation" @click="textToBraille('altText', this.altText)">{{
                  $t('점자변환')
                }}
              </button>
              <textarea v-model="BrailleHtml"
                        class="form-control form-control-sm braille-html"
                        readonly
              ></textarea>
            </div>

            <!-- Print Dotpad Button -->
            <button class="btn btn-print tooltips"
                    v-if="OPTIONS.showPadPrint"
                    @click.prevent.stop="printDTM()">
              <svg class="me-1" width="31" height="34" viewBox="0 0 31 34" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.74316 9H22.8165V5.8C22.8165 2.6 21.6265 1 18.0565 1H12.5032C8.93317 1 7.74316 2.6 7.74316 5.8V9ZM21.6265 21.8V28.2C21.6265 31.4 20.0399 33 16.8665 33H13.6932C10.5198 33 8.93317 31.4 8.93317 28.2V21.8H21.6265Z"
                  stroke="#44403F" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round"/>
                <path
                  d="M23.2134 21.8H7.34668M7.34668 15.4H12.1067M29.5601 13.8V21.8C29.5601 25 27.9734 26.6 24.8001 26.6H21.6267V21.8H8.93335V26.6H5.76001C2.58667 26.6 1 25 1 21.8V13.8C1 10.6 2.58667 9 5.76001 9H24.8001C27.9734 9 29.5601 10.6 29.5601 13.8Z"
                  stroke="#44403F" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round"/>
              </svg>
              <span class="ms-1">{{ $t('프린트') }}</span>
              <div>
                <span class="tooltiptext tooltip-actions">{{ $t('프린트 (Alt + P)') }}</span>
              </div>
            </button>

            <!-- Connect Dotpad Button -->
            <button class="btn btn-connect tooltips" data-bs-target="#connectDotPadDialog" data-bs-toggle="modal"
                    style="color: blue;border: 2px solid blue;"
                    type="button" v-if="OPTIONS.showPadConnect">
              {{ $t('닷패드연결') }}
              <div>
                <span class="tooltiptext tooltip-actions" style="">Bluetooth (Alt + B)</span>
              </div>
            </button>
          </div>
        </div>
      </main>
      <!-- 2022-08-18 / 정태화 / 텍스트 파일 import 하는 기능으로 대체 -->
      <!-- <div v-if="showAltText" class="mb-3 position-absolute bottom-0 start-50 translate-middle-x">
        <label class="form-label" for="exampleFormControlTextarea1">AltText</label>
        <div class="d-flex">
          <input id="exampleFormControlTextarea1" v-model="altText" class="p-2 flex-grow-1 form-control" rows="1"/>
          <button class="p-2 btn btn-secondary" @click="textToBraille()">Save</button>
        </div>
      </div> -->
    </div>
  </div>
  <device ref="device"
    @initialize-dot-pad="initializeDotPad"/>
  <dot-pad320-key ref="dot-pad320-key"
                  @twenty-cells-left-panning="twentyCellsLeftPanning"
                  @twenty-cells-right-panning="twentyCellsRightPanning"
                  @previous-page="previousPage"
                  @next-page="nextPage"
                  @refresh="refresh"
                  @select-pen-tool="selectPenTool"
                  @select-eraser-all-tool="selectEraserAllTool"
                  @add-page="addPage"
                  @delete-page="deletePage"/>
</template>

<script>
import { $axios, $session } from "dot-framework";
import global from "global";
import { Modal } from "bootstrap";
import { fabric } from "fabric";
import { debounce } from "lodash-es";
import { defineAsyncComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import Navigation from "./Navigation";
import PageItem from "./PageItem";
import Toolbar from "./Toolbar";
import { Braille } from "./js/Braille.js";
import { DotpadList } from "./js/DotpadList.js";
import { DotpadSDK } from "./js/DotpadSDK.js";
import { Options } from "./js/Options.js";
import { SocketClient } from "./js/SocketClient.js";
import { DTMS, Page } from "./js/common.js";
import { draw } from "./js/draw.js";
import { file } from "./js/file.js";
import { index } from "./js/index.js";
import { Tools } from "./js/tools";

const Device = defineAsyncComponent(() => import("@/components/dtms-editor-v1.7/dialog/DeviceDialog"));
const DotPad320Key = defineAsyncComponent(() => import("@/views/canvas/dot-pad/DotPad320Key.vue"));
let CANVAS_WIDTH = 8;
let CANVAS_HEIGHT = 8;
let penColor = "rgba(0, 0, 0, 255)";
const eraseColor = "rgba(255, 255, 255, 0)";
let lPad = 2;
const dotpadsdk = new DotpadSDK();
const socketClient = new SocketClient();

let canvas = null;
let canvasGrid = null;
let canvasPreview = null;
let parentEl = null;
let TextBox = null;

export default {
  name: "EditorView",
  components: {
    PageItem,
    Navigation,
    Toolbar,
    Device,
    DotPad320Key,
  },
  props: {
    libData: String,
    options: Object,
    web_mode: String
  },
  data() {
    return {
      curPage: 1,
      totalPage: 0,
      /* 드로잉 관련 데이터 */
      context: null,
      painting: false,
      useRightButton: false,
      currentWidth: 0,
      currentHeight: 0,
      scale: 0, // 캔버스에 그려질 셀의 크기, 내부적으로 계산되어 크기가 지정됨.
      size: 1, // 드로잉 브러쉬 픽셀 크기 지정
      pixelSize: 1,
      mainColor: penColor,
      subColor: "rgba(255, 255, 0, 255)",
      x: 0,
      y: 0,
      mousePositionIndex: -1,
      mousePositionIndex_old: -1,
      mouseStartPositionIndex: -1,
      startPos: {
        cy: 0,
        cx: 0,
      },
      endPos: {
        cy: 0,
        cx: 0,
      },
      curPos: {
        cy: -1,
        cx: -1,
      },
      curPos_old: {
        cy: -1,
        cx: -1,
      },
      previousPos: {
        cy: -1,
        cx: -1
      },
      selectedPins: [], // 셀렉터 영역안에 선택된 핀 번호
      cachedPins: [], // 셀렉터에 복사된 핀 정보
      VOXEL_ROW_NUM: 40,  // 4개의 핀이 10줄
      VOXEL_COL_NUM: 60,  // 2개의 핀이 30칸
      pixels: [],
      PINS: null, // Fabric 핀 오브젝트
      originalDataSet: {
        state: null,
        colors: null,
      },
      isCanvasDirty: false,
      stateHistory: [],
      undoneChanges: [],
      draggingStack: [],
      lastShapePins: [], // Shape Preview 관련
      patternEmpty: true,
      lassoPixels: [],
      cachedLassoPixels: null,
      lassoGroup: null,
      rectGroup: null,
      selectedTool: Tools[0],
      figureDrawStarted: false,
      // 그리드 관련 변수
      grid: null,
      useGrid: true,
      gridReady: true,
      pages: [], // DTMS 파일에 저장할 Page 목록
      texts: "", // DTM 파일에 포함될 텍스트
      dtms: new DTMS({}),
      thumbnails: [], // 페이지별 썸네일 캔버스 오브젝트 배열
      text: "",
      altText: "",
      // 점자 관련
      BrailleTextIndex: [],
      BrailleText: "",
      BrailleContent: "",
      BrailleHtml: "", // 점역표시 HTML
      textPins: [],
      currentTextItem: null,
      isNewline: false,
      /* UI 관련 */
      printButtonText: "",
      textEditor: false,
      // currentText: "Text",
      modified: false,
      dtmsOpened: false, // Not Used.
      // 셀렉션 툴 관련 변수
      selectArea: null,
      selectAreaStartPos: {
        left: 0,
        top: 0
      },
      objectPrevPos: {
        left: 0,
        top: 0
      },
      selector: {
        type: 'rect',
        state: 'default',
        originX: 0,
        originY: 0
      },
      locale: 'en',
      // 2022-08-12 / 정태화 / DTMS 파일오픈 변수 추가
      dtmsOpenData: {
        dtmsGubun: "", // DTMS 구분(PUBLIC, PRIVATE)
        dtmsFileNo: "", // DTMS 파일번호
        dtmsGroupNo: "" // DTMS 그룹번호
      },
      connectCount: 0
    }
  },
  computed: {
    ...mapGetters("canvasPage", ["maxPage"]),
    brailleKind() {
      return this.$store.getters['braille/kind'];
    },
    brailleLang() {
      return this.$store.getters['braille/language'];
    },
    brailleGrade() {
      return this.$store.getters['braille/grade'];
    },
    brailleRule() {
      return this.$store.getters['braille/rule'];
    },
    braillePin() {
      return this.$store.getters['braille/pin'];
    },
    showAltTextButtonLabel() {
      return this.altText.length === 0 ? "저장" : "수정";
    },
    hasThumbnail() {
      return true;
    },
    // 2022-08-12 / 정태화 / 테마 가져오는 부분 변경
    getTheme() {
      return {
        '--primary-color': '#9d9d9d',
        '--secondary-color': '#FFFFFF',
        '--font-color': '#121212',
        '--success': '#19e100',
        '--danger': '#EA5414',
        '--light': '#FFFFFF',
        '--dark': '#000000',
        'z-index': 1
      };
    },
    // options이 변경됨에 따라 변경되게 처리
    OPTIONS() {
      // 파라미터 옵션과 디폴트 옵션 병합처리 (2022-11-11, 정태화)
      return Options.setOptions(this.options)
    },
    // 레이아웃 가운데 COL_SIZE 설정
    COL_CENTER_SIZE() {
      return index.getColSize(this.options);
    },
  },
  mounted() {
    socketClient.CallbackFtn = this.onMessageReceived;
    // 메인 캔버스 컨텍스트
    canvas = new fabric.Canvas("board");
    canvasGrid = new fabric.Canvas("grid", {skipTargetFind: true});
    canvasPreview = new fabric.Canvas("braille", {skipTargetFind: true});
    this.initPaper();
    this.resize();
    this.drawText();
    if (this.libData != undefined) {
      this.loadLibData();
    }
    window.addEventListener('resize', this.resize);
    // 단축키 관련 이벤트 리스너 등록
    this.addHotkeyEventListeners();
    this.$nextTick(() => this.initializeDotPads());
  },
  unmounted() {
    this.removeAllEventListener();
  },
  watch: {
    useGrid(value) {
      this.gridReady = false;
      // console.log(this.useGrid, this.gridReady);
      if (value) {
        this.addGridLine();
      } else {
        this.removeGridLine();
      }
      this.gridReady = true;
      // console.log(this.useGrid, this.gridReady);
    }
  },
  methods: {
    ...mapMutations("braille", ["setKind", "setLanguage", "setGrade", "setRule", "setPin"]),
    addHotkeyEventListeners() {
      window.addEventListener('keydown', this.preventDefaultShortcuts);
      window.addEventListener('keyup', this.preventDefaultShortcuts);
      window.addEventListener('keydown', this.keyInputEventListener);
    },
    removeAllEventListener() {
      window.removeEventListener('keydown', this.preventDefaultShortcuts);
      window.removeEventListener('keyup', this.preventDefaultShortcuts);
      window.removeEventListener('keydown', this.keyInputEventListener);
      window.removeEventListener('keyup', this.editorKeyInputListener);
    },
    // 페이지 초기화
    initPaper() {
      parentEl = this.$refs.board.parentElement.parentElement; // Fabric : 기존의 Canvas를 감싸는 컨테이너로 래핑됨.
      CANVAS_HEIGHT = this.VOXEL_ROW_NUM;
      CANVAS_WIDTH = this.VOXEL_COL_NUM;

      if (parentEl.clientWidth / parentEl.clientHeight > 1.5) {
        this.scale = Math.floor(parentEl.clientHeight / (CANVAS_HEIGHT));
      } else {
        this.scale = Math.floor(parentEl.clientWidth / (CANVAS_WIDTH));
      }

      this.currentWidth = CANVAS_WIDTH * this.scale;
      this.currentHeight = CANVAS_HEIGHT * this.scale;

      // Set Canvas width and height
      canvas.setWidth(this.currentWidth);
      canvas.setHeight(this.currentHeight);
      canvasGrid.setWidth(this.currentWidth);
      canvasGrid.setHeight(this.currentHeight);
      canvasPreview.setWidth(this.currentWidth);
      canvasPreview.setHeight(this.currentHeight);

      canvas.uniScaleKey = "shiftKey";
      canvas.stopContextMenu = true;
      canvas.fireRightClick = true;

      for (let r = 0; r < this.VOXEL_ROW_NUM; r++) {
        for (let c = 0; c < this.VOXEL_COL_NUM; c++) {
          const pin = new fabric.Circle({
            radius: (this.scale * this.size) / 2 * 0.75,
            fill: eraseColor,
            stroke: "",
            strokeWidth: 0,
            left: c * this.scale + lPad,
            top: r * this.scale + lPad,
            objectCaching: false,
            selectable: false,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            hoverCursor: "pointer"
          });
          canvas.add(pin);
        }
      }
      this.dtms = new DTMS({});
      this.pixels = Array.from({length: this.VOXEL_ROW_NUM * this.VOXEL_COL_NUM}, () => false);
      const page = new Page("", this.curPage, {name: "", data: this.pixels},
        {
          name: "",
          data: this.BrailleText,
          plain: this.altText
        }, [], []);
      this.pages.push(page);
      this.totalPage = this.pages.length;
      // this.altText = "";
      this.PINS = this.getPinObjects();
      // Add Event Listener
      this.addDrawingEventListeners();
      this.setCanvas(false, true);
    },
    addGridLine() {
      let gridLines = [];
      const gridOptions = {type: 'line', stroke: '#cccccc', selectable: false};

      for (let r = 0; r < this.VOXEL_ROW_NUM; r++) {
        for (let c = 0; c < this.VOXEL_COL_NUM; c++) {
          // Add Grid line Object { x0, y0, x1, y1 }
          gridLines.push(new fabric.Line([c * this.scale, 0, c * this.scale, CANVAS_HEIGHT * this.scale], gridOptions));
          gridLines.push(new fabric.Line([0, r * this.scale, CANVAS_WIDTH * this.scale, r * this.scale], gridOptions));
        }
      }

      this.grid = new fabric.Group(gridLines, {
        selectable: false,
        evented: false
      });

      //this.grid.addWithUpdate();
      canvasGrid.add(this.grid);
    },
    removeGridLine() {
      this.grid && canvasGrid.remove(this.grid);
    },
    // 썸네일 캔버스 초기화,
    initThumbnail(index) {
      let thumbnailCanvas = new fabric.Canvas("t_" + index);

      thumbnailCanvas.width = 116;
      thumbnailCanvas.height = 77;
      thumbnailCanvas.backgroundColor = "rgba(" + Math.floor(Math.random() * 255) + ", 0, 0, 1)";

      for (let r = 0; r < this.VOXEL_ROW_NUM; r++) {
        for (let c = 0; c < this.VOXEL_COL_NUM; c++) {
          const pin = new fabric.Circle({
            radius: (this.scale * this.size) / 2 * 0.75,
            fill: eraseColor,
            stroke: "rgba(255,0,0,1)",
            strokeWidth: 1,
            left: c * this.scale + lPad,
            top: r * this.scale + lPad,
            objectCaching: false,
            selectable: false,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            hoverCursor: "pointer"
          });
          thumbnailCanvas.add(pin);
        }
      }
      this.thumbnails.push(thumbnailCanvas);
    },
    // 선택가능여부 설정
    setCanvas(selection, activeDrawing) {
      canvas.selection = selection;
      canvas.activeDrawing = activeDrawing;
    },
    // 저장 REST-API 호출
    async uploadDTMS(dtmsGroupNo, saveMode) {
      const compNo = $session.getCompNo(); // 업체번호 C220512001
      const userNo = $session.getUserNo(); // 로그인 사용자번호

      // 수정 사항 없이 Save 버튼을 눌렀을 때, 기존의 페이지 항목이 그대로 유지되어 페이지가 중복되는 현상 발생.
      // 업로드 이전 items 리스트를 초기화 시켜줌.
      this.dtms.items = [];
      this.dtms.lang = this.brailleLang;
      this.dtms.lang_option = this.brailleGrade;

      for (let page of this.pages) {
        // 저장전 현재페이지 페이지 점역실행
        const hexData = await this.fetchTextToBraille("altText", page.text.plain);
        page.text.data = await this.textToBraille("", hexData);

        this.dtms.items.push(page.makeJson());
      }

      if (this.web_mode === "CLASSROOM") {
        const PARAMS_BODY = {
          "USER_NO": userNo, // 필수
          "DTMS_GROUP_NO": dtmsGroupNo,
          "DTMS_SAVE_CONTENTS": this.dtms.makeJson(), // DTMS에 대한 JSON String
          "FILE_NAME": this.dtms.title,
          "FILE_DESC": this.dtms.file_description
        };

        let dtmsGubun = this.dtmsOpenData.dtmsGubun;
        let dtmsFileNo = this.dtmsOpenData.dtmsFileNo;
        if (dtmsGubun == "PRIVATE" && dtmsFileNo != "") {
          let url = "/class-app/v1/comps/" + compNo + "/dtms/" + dtmsFileNo;
          let response = await $axios.put(url, PARAMS_BODY);
          if (response.status == 200) {
            alert("Updated successfully.");
          } else {
            alert("Update failed.");
          }
        } else {
          let url = "/class-app/v1/comps/" + compNo + "/dtms";
          let response = await $axios.post(url, PARAMS_BODY);
          if (response.status === 200) {
            this.$swal({
              title: this.$t("성공적으로 저장됨"),
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            this.$swal({
              title: "Save failed",
              showConfirmButton: false,
              timer: 3000
            });
          }
        }
      } else if (this.web_mode === "CANVAS") {
        const PARAMS_BODY = {
          "USER_NO": userNo, // 필수
          "DTM_GROUP_NO": dtmsGroupNo,
          "DTMS_JSON": this.dtms.makeJson(), // DTMS에 대한 JSON String
          "DTM_NAME": this.dtms.title,
          "DTM_DESC": this.dtms.file_description,
          "DEVICE_KIND": "300",
          "DTMS_TYPE": "dtms"
        };

        const dtmsGubun = this.dtmsOpenData.dtmsGubun;
        const dtmsFileNo = this.dtmsOpenData.dtmsFileNo;
        let url = "";
        let isNew = false;

        if (dtmsFileNo != "" && (dtmsGubun == "PRIVATE" || dtmsGubun == "PUBLIC" && this.isDotAdmin()) && saveMode != "saveAs") {
          url = "/vision-app/v1/dtm/images/" + dtmsFileNo + "/from-dtms";
        } else {
          url = "/vision-app/v1/dtm/images/from-dtms";
          isNew = true;
        }

        const promise = $axios.post(url, PARAMS_BODY);
        let response = await promise;
        if (response.status === 200 || response.status === 201) {
          this.isCanvasDirty = false;
          this.modified = false;
          if (isNew) {
            this.dtmsOpenData = {
              dtmsGubun: this.dtmsGubun || "PRIVATE",
              dtmsFileNo: response.data.DTM_NO,
              dtmsGroupNo: response.data.DTM_GROUP_NO
            };
          }
          this.$swal({
            title: this.$t("성공적으로 저장됨"),
            showConfirmButton: false,
            timer: 3000
          });
          return response;
        } else {
          this.$swal({
            title: "Save failed",
            showConfirmButton: false,
            timer: 3000
          });
          return response;
        }
      }
    },
    // 데이타 페이지에 바이딩
    loadPage(idx) {
      this.clearBraille();
      this.clearCanvas();
      this.curPage = idx + 1;
      this.pages[idx].page = this.curPage;
      this.pixels = Array.from(this.pages[idx].graphic.data);

      // DTMS 파일 포맷인 경우만 해당
      if (this.pages[idx].text) {
        this.altText = this.pages[idx].text.plain;
        this.BrailleText = this.pages[idx].text.data;
      }

      // 2022-08-18 / 정태화 / 점자 HTML 변수 초기화
      this.BrailleHtml = "";
      this.stateHistory = this.pages[idx].stateHistory;
      this.undoneChanges = this.pages[idx].undoneChanges;
      this.totalPage = this.pages.length;
      this.drawPixels();
      if (this.altText != null && this.altText.length > 0) {
        this.fetchTextToBraille("altText", this.altText)
          .then(brailleHexDataWithSpaces => this.textToBraille("altText", brailleHexDataWithSpaces))
          .then(hexData => {
            this.BrailleHtml = Braille.toChar(hexData);
          });
      }
      if (this.selectedTool.name === "RectSelector" || this.selectedTool.name === "LassoSelector") {
        if (this.selectArea != null)
          canvas.add(this.selectArea);
      }
      canvas.renderAll();
    },
    // DTM에디터에서 파일목록의 특정행 클릭시에 호출
    fileOpen(jsonObj) {
      // hexa to binary(true,false)로 변환 후 pages변수에 저장
      file.fileOpen(this.pages, jsonObj);
      // pages데이타중 첫번째 페이지 데이타를 Canvas에 표현
      this.loadPage(0);
      // loadPage에 중복처리되어 주석처리
      //this.curPage = 1;
      //this.totalPage = this.pages.length;
      //this.stateHistory = [];
      //this.undoneChanges = [];
    },
    // DTMS.JSON 조회 (DTM에디터 사이트를 위해 추가)
    getDtmsJson() {
      let result = {};
      // 현재 페이지 셀 this.pages변수에 저장
      this.savePage();
      // DTMS.JSON 생성
      this.dtms.items = [];
      for (const page of this.pages) {
        // boolean 배열을 heax문자열로 변환해서 추가
        let itemJson = page.makeJson();
        this.dtms.items.push(itemJson);
      }
      result = this.dtms.makeJson();
      return result;
    },
    loadPageAfterDelete(idx) {
      this.pages.splice(idx, 1);
      this.thumbnails.splice(idx, 1);
      this.curPage = idx;
      this.totalPage = this.pages.length;
      if (idx - 1 < 0) {
        this.loadPage(0);
      } else {
        this.loadPage(idx - 1);
      }
    },
    // 2022-08-18 / 정태화 / 페이지 항목 위아래 이동 처리
    loadPageAfterMove(from, to) {
      this.pages.splice(to, 0, this.pages.splice(from, 1)[0]);
      this.thumbnails.splice(to, 0, this.thumbnails.splice(from, 1)[0]);
      this.curPage = to;
      this.loadPage(to);
    },
    // 데이타를 직접 바인딩
    loadLibData() {
      let jsonObj = JSON.parse(this.libData);
      this.pages.splice(0, this.pages.length);
      for (const page of jsonObj.items) {
        let _graphicData = page.graphic.data; // 600자 Hex String
        let byteArr = this.hexToBytes(_graphicData); // 300 Bytes Array
        let tmpArr = [];
        for (let i = 0; i < byteArr.length; i++) {
          let start_index = parseInt(i / 30) * 60 * 4 + (i % 30) * 2;
          tmpArr[start_index] = (byteArr[i] & (0x01 << 0)) ? true : false;
          tmpArr[start_index + 60] = (byteArr[i] & (0x01 << 1)) ? true : false;
          tmpArr[start_index + 120] = (byteArr[i] & (0x01 << 2)) ? true : false;
          tmpArr[start_index + 180] = (byteArr[i] & (0x01 << 3)) ? true : false;
          tmpArr[start_index + 1] = (byteArr[i] & (0x01 << 4)) ? true : false;
          tmpArr[start_index + 61] = (byteArr[i] & (0x01 << 5)) ? true : false;
          tmpArr[start_index + 121] = (byteArr[i] & (0x01 << 6)) ? true : false;
          tmpArr[start_index + 181] = (byteArr[i] & (0x01 << 7)) ? true : false;
        }
        page.graphic.data = tmpArr;

        let textName = (this.$refs.navigation.$refs.openDialog.type == "P" ? ((page.text.name != undefined) ? page.text.name : "") : "");
        let textData = (this.$refs.navigation.$refs.openDialog.type == "P" ? ((page.text.name != undefined) ? page.text.data : "") : "");
        let textPlain = (this.$refs.navigation.$refs.openDialog.type == "P" ? ((page.text.name != undefined) ? page.text.plain : "") : "");

        let pageObject = new Page(page.title, page.page,
          {
            name: page.graphic.name,
            data: page.graphic.data
          },
          {
            name: textName,
            data: textData,
            plain: textPlain
          },
          [], []);
        this.pages.push(pageObject);
      }

      let newDtms = new DTMS({
        "title": jsonObj.title,
        "lang": jsonObj.lang,
        "lang_option": jsonObj.lang_option || jsonObj.lang_Option,
        "file_description": "",
        "device": jsonObj.device,
        "items": this.pages
      });
      this.dtms = newDtms;
      this.loadPage(0);
    },
    // 현재 화이트 보드(Page) 내용을 저장
    savePage() {
      const id = this.curPage - 1;
      this.pages[id].title = this.dtms.title ? this.dtms.title : "Untitled";
      this.pages[id].page = this.curPage;
      this.pages[id].graphic = {
        name: this.curPage + ".dtm",
        data: Array.from(this.pixels)
      };
      this.pages[id].text = {
        name: this.curPage + ".txt",
        data: this.BrailleText,
        plain: this.altText
      };
      this.pages[id].stateHistory = this.stateHistory;
      this.pages[id].undoneChanges = this.undoneChanges;
    },
    addDrawingEventListeners() {
      //마우스 이벤트
      canvas.on('mouse:down', this.startPosition);
      canvas.on('mouse:down', this.paintBucket);
      canvas.on('mouse:up', this.finishedPosition);
      canvas.on('mouse:move', this.drawPixel);
      canvas.on('mouse:out', function () {
        this.painting = false;
      });
      //터치 이벤트
      canvas.on('touchstart', this.startPosition);
      canvas.on('touchstart', this.paintBucket);
      canvas.on('touchend', this.finishedPosition);
      canvas.on('touchmove', this.drawPixel);

      canvas.on('object:moving', (event) => {
        let width = event.target.width * event.target.scaleX;
        let height = event.target.height * event.target.scaleY;
        let left = Math.round(event.target.left / this.scale);
        let right = Math.round((event.target.left + width) / this.scale);
        let top = Math.round(event.target.top / this.scale);
        let bottom = Math.round((event.target.top + height) / this.scale);

        let newPos = {
          left: left,
          top: top,
        };

        if (left < 0) {
          newPos.left = 0;
        } else if (right >= this.VOXEL_COL_NUM) {
          newPos.left = this.VOXEL_COL_NUM - Math.round(width / this.scale);
        }

        if (top < 0) {
          newPos.top = 0;
        } else if (bottom >= this.VOXEL_ROW_NUM) {
          newPos.top = this.VOXEL_ROW_NUM - Math.round(height / this.scale);
        }

        event.target.set({
          left: newPos.left * this.scale,
          top: newPos.top * this.scale
        });
        // 현재 움직이는 오브젝트가 Rect 또는 Lasso 셀렉터일 경우
        if (event.target == this.selectArea) {
          if (this.objectPrevPos.left !== left || this.objectPrevPos.top !== top) {
            this.objectPrevPos.left = left;
            this.objectPrevPos.top = top;
            if (this.selector.type === 'rect') {
              this.findSelectedPins(1);
            } else if (this.selector.type === 'lasso')
              this.findSelectedPins(2);
          }
        }
      });
      canvas.on('object:modified', (event) => {
        // console.log(event.target, event.target instanceof TextBox);
        if (event.target instanceof TextBox) {
          let left = Math.round(event.target.left / this.scale);
          let top = Math.round(event.target.top / this.scale);

          if (event.action === "resizing") {
            let w = event.target.width * event.target.scaleX,
              threshold = this.scale,
              snap = {
                left: left * this.scale,
                right: Math.round((event.target.left + w) / this.scale) * this.scale
              },
              dist = {
                left: Math.abs(snap.left - event.target.left),
                right: Math.abs(snap.right - event.target.left - w)
              },
              attrs = {
                scaleX: event.target.scaleX,
              };

            switch (event.target.__corner) {
              case 'ml':
                if (dist.left < threshold) {
                  attrs.scaleX = (w - (snap.left - event.target.left)) / event.target.width;
                  attrs.left = snap.left;
                }
                break;
              case 'mr':
                if (dist.right < threshold) {
                  attrs.scaleX = (snap.right - event.target.left) / event.target.width;
                }
                break;
            }
            event.target.set(attrs);
          }

          // 텍스트 박스 이동 완료 후, 텍스트 변경 완료 후 호출 됨
          const startIndex = (top * this.VOXEL_COL_NUM) + left;
          this.drawBraillePixel(startIndex);
        }
      });
      // 텍스트가 변경될 떄 호출
      canvas.on('text:changed', debounce(function (event) {
        const left = Math.round(event.target.left / this.scale);
        const top = Math.round(event.target.top / this.scale);

        if (event.target instanceof TextBox) {
          const text = event.target.text;
          const fixedText = this.currentTextItem.textLines.join("\n");

          if (text.length === 0) {
            canvasPreview.clear();
            return;
          }
          // 텍스트 점자 변환
          this.fetchTextToBraille("content", fixedText)
            .then(data => this.textToBraille("content", data))
            .then(data => {
              this.BrailleContent = data;
              // 점자 픽셀 드로우
              const startIndex = (top * this.VOXEL_COL_NUM) + left;
              this.drawBraillePixel(startIndex);
            });
        }
      }.bind(this), 200));
    },
    enterEditorMode() {
      this.removeAllEventListener();
      window.addEventListener('keyup', this.editorKeyInputListener);
    },
    exitEditorMode() {
      window.removeEventListener('keyup', this.editorKeyInputListener);
      this.addHotkeyEventListeners();
    },
    // 사용자 키 입력 처리
    keyInputEventListener(event) {
      if (event.target.localName !== "textarea" && event.target.localName !== "input") {
        switch (event.key) {
          case "ArrowDown":
            if (this.curPage < this.pages.length) {
              this.loadPage(this.curPage);
            }
            break;
          case "ArrowUp":
            if (this.curPage > 1) {
              this.loadPage(this.curPage - 2);
            }
            break;
          case "a":
          case "A":
            break;
          case "b":
          case "B":
            if (event.ctrlKey) {
              if (!this.$refs.navigation.brailleOpen) {
                this.$refs.navigation.brailleOpen = true;
              }
            } else if (event.altKey) {
              this.$refs.navigation.openDeviceDialog();
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "PaintBucket");
              this.resetSelector();
              this.clearBraille();
            }
            break;
          case "c":
          case "C":
            this.clearBraille();
            // 컨트롤 + C 눌림.
            if (this.selectedTool.name === "RectSelector" || this.selectedTool.name === "LassoSelector") {
              if (event.ctrlKey) {
                if (!this.selectArea) return;

                this.selector.state = 'copy';
                this.selector.originX = this.selectArea.left;
                this.selector.originY = this.selectArea.top;

                // 이전에 복사된 핀 오브젝트 제거
                if (this.selector.type === 'rect') {
                  this.selectArea._objects.splice(1, this.selectArea._objects.length - 1);
                } else if (this.selector.type === 'lasso') {
                  this.selectArea._objects.splice(this.cachedLassoPixels.length, this.selectArea._objects.length - 1);
                }
                // this.cachedPins = Array.from(this.selectedPins);
                this.cachedPins = JSON.parse(JSON.stringify(this.selectedPins));
                // console.log(`this.cachedPins: ${this.cachedPins.length}`);
                // console.log(this.cachedPins);

                let clonedObjects = [];
                const grpLeft = this.selectArea.left;
                const grpTop = this.selectArea.top;
                const grpWidth = this.selectArea.width;
                const grpHeight = this.selectArea.height;

                for (const selectedPin of this.selectedPins) {
                  selectedPin.clone(function (cloned) {
                    clonedObjects.push(cloned.set({
                      left: cloned.left - (grpLeft + grpWidth / 2),
                      top: cloned.top - (grpTop + grpHeight / 2),
                      opacity: 0.5,
                    }));
                  });
                }
                for (const clonedObject of clonedObjects) {
                  this.selectArea.add(clonedObject);
                }
                this.selectArea.addWithUpdate();
                canvas.requestRenderAll();
              }
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "Circle");
              this.setCanvas(false, false);
              this.resetSelector();
            }
            break;
          case "d":
          case "D":
            if (event.ctrlKey) {
              //
            } else {
              this.resetSelector();
              this.clearBraille();
            }
            break;
          case "e":
          case "E":
            this.clearBraille();
            if (event.ctrlKey) {
              this.setCanvas(false, false);
              this.selectedTool = Tools.find(obj => obj.name === "EraserAll");
              this.resetSelector();
              this.eraseAll();
              this.savePage();
            } else if (event.altKey) {
              this.exportDTMS();
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "Eraser");
              this.resetSelector();
              this.setCanvas(false, true);
            }
            break;
          case "f":
          case"F":
           /* this.selectedTool = Tools.find(obj => obj.name === "AltText");
            this.$refs.toolbar.openTextFile();
            this.setCanvas(false, false);
            this.resetSelector();
            this.clearBraille();*/
            break;
          case "g":
          case "G":
            this.clearBraille();
            if (event.ctrlKey) {
              this.useGrid = !this.useGrid;
            }
            break;
          case "h":
          case "H":
            this.selectedTool = Tools.find(obj => obj.name === "LassoSelector");
            this.setCanvas(false, false);
            this.resetSelector();
            this.clearBraille();
            break;
          case "i":
          case "I":
            this.selectedTool = Tools.find(obj => obj.name === "ImageEdge");
            this.originalDataSet = {
              state: [...this.pixels],
              colors: this.getPixelColors(),
            };
            this.$refs.toolbar.openImageFile();
            this.resetSelector();
            this.clearBraille();
            break;
          case "l":
          case "L":
            if (event.ctrlKey) {
              if (!this.$refs.navigation.localeOpen) {
                this.$refs.navigation.localeOpen = true;
              }
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "Line");
              this.resetSelector();
              this.clearBraille();
            }
            break;
          case "m":
          case "M":
            this.selectedTool = Tools.find(obj => obj.name === "Move");
            this.setCanvas(false, false);
            this.resetSelector();
            this.clearBraille();
            break;
          case "n":
          case "N":
            this.clearBraille();
            this.addNewPage();
            break;
          case "o":
          case "O":
            this.clearBraille();
            if (event.ctrlKey) {
              if (!this.$refs.navigation.fileBrowserOpen) {
                this.$refs.navigation.onClickOpenBtn();
              }
              // this.$refs.navigation.$refs.openDialog.getGroupList();
            }
            break;
          case "p":
          case "P":
            this.clearBraille();
            if (event.altKey) {
              this.printDTM();
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "Pen");
              this.resetSelector();
              this.setCanvas(false, true);
            }
            break;
          case "r":
          case "R":
            if (event.shiftKey) {
              this.resetColor();
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "Square");
              this.setCanvas(false, false);
              this.resetSelector();
              this.clearBraille();
            }
            break;
          case "s":
          case "S":
            if (event.ctrlKey) {
              if (event.shiftKey) {
                if (this.dtmsOpenData.dtmsFileNo) {
                  this.$refs.navigation.onClickSaveAsBtn();
                  this.$refs.navigation.openSaveAsDialog();
                }
              } else {
                this.$refs.navigation.onClickSaveBtn();
              }
            } else if (event.shiftKey) {
              this.switchColor();
            } else if (event.altKey) {
              this.shareScreen();
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "RectSelector");
              this.setCanvas(true, false);
              this.resetSelector();
              this.clearBraille();
            }
            break;
          case "t":
          case "T":
            this.selectedTool = Tools.find(obj => obj.name === "Triangle");
            this.setCanvas(false, false);
            this.resetSelector();
            break;
          case "v":
          case "V":
            // 컨트롤 + V 눌림.
            if (this.selectedTool.name == "RectSelector" || this.selectedTool.name == "LassoSelector") {
              if (event.ctrlKey) {
                // selector.state = 복사 또는 붙여넣기 상태일 때
                if (this.selector.state == 'copy' || this.selector.state == 'cut') {
                  // 기존 핀과 셀렉터에 캐시된 핀의 인덱스 변경값 계산
                  const diff = (Math.round(this.selectArea.top / this.scale) * this.VOXEL_COL_NUM + Math.round(this.selectArea.left / this.scale))
                    - (Math.round(this.selector.originY / this.scale) * this.VOXEL_COL_NUM + Math.round(this.selector.originX / this.scale));

                  // console.log(`this.cachedPins: ${this.cachedPins.length}`);
                  // console.log(this.cachedPins);

                  for (const pin of this.cachedPins) {
                    const pinIndex = (Math.round(pin.top / this.scale) * this.VOXEL_COL_NUM) + Math.round(pin.left / this.scale);
                    // 셀렉터가 캔버스 영역을 벗어날 경우 핀 개체 속성을 변경함에 따라 발생하는 null 참조 방지
                    if (pinIndex + diff < 0 ||
                      pinIndex + diff >= this.VOXEL_COL_NUM * this.VOXEL_ROW_NUM ||
                      pinIndex + diff < this.selector.originX % this.VOXEL_COL_NUM
                    ) {
                      continue;
                    }
                    // console.log("붙여넣기 색상 : ", pin.fill);
                    this.PINS[pinIndex + diff].fill = pin.fill; // 캐시된 핀의 색상을 따르도록
                    this.pixels[pinIndex + diff] = true;
                    this.draggingStack.push(pinIndex + diff);
                  }

                  const action = {
                    type: 'draw',
                    originalDataSet: {
                      colors: [...this.originalDataSet.colors],
                      state: [...this.originalDataSet.state]
                    },
                    changedDataSet: {
                      colors: this.getPixelColors(),
                      state: [...this.pixels],
                    }
                  };
                  this.isCanvasDirty = true;
                  this.stateHistory.unshift(action);
                  this.draggingStack = [];
                  canvas.renderAll();
                  this.savePage();
                }
              }
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "VerticalMirrorPen");
              this.setCanvas(false, true);
              this.resetSelector();
              this.clearBraille();
            }
            break;
          case "x":
          case "X":
            this.clearBraille();
            // 컨트롤 + X 눌림.
            if (this.selectedTool.name == "RectSelector" || this.selectedTool.name == "LassoSelector") {
              if (event.ctrlKey) {
                if (!this.selectArea) return;

                this.selector.state = 'cut';
                this.selector.originX = this.selectArea.left;
                this.selector.originY = this.selectArea.top;

                // 이전에 복사된 핀 오브젝트 제거
                if (this.selector.type === 'rect') {
                  this.selectArea._objects.splice(1, this.selectArea._objects.length - 1);
                } else if (this.selector.type === 'lasso') {
                  this.selectArea._objects.splice(this.cachedLassoPixels.length, this.selectArea._objects.length - 1);
                }
                // this.cachedPins = [...this.selectedPins]; // shallow copy issue: 잘라내기 시 캐시된 핀의 색상이 바뀌면 붙여넣기 시 바뀐 색상이 적용되는 문제 발생.
                this.cachedPins = JSON.parse(JSON.stringify(this.selectedPins));
                // console.log(`this.cachedPins: ${this.cachedPins.length}`);
                // console.log(this.cachedPins);

                let clonedObjects = [];
                const grpLeft = this.selectArea.left;
                const grpTop = this.selectArea.top;
                const grpWidth = this.selectArea.width;
                const grpHeight = this.selectArea.height;

                for (const selectedPin of this.selectedPins) {
                  const pinIndex = (Math.round(selectedPin.top / this.scale) * this.VOXEL_COL_NUM) + Math.round(selectedPin.left / this.scale);
                  selectedPin.clone(function (cloned) {
                    clonedObjects.push(cloned.set({
                      left: cloned.left - (grpLeft + grpWidth / 2),
                      top: cloned.top - (grpTop + grpHeight / 2),
                      opacity: 0.5,
                    }));
                    // console.log("카피된 색상: ", cloned.fill);
                  });
                  this.PINS[pinIndex].fill = eraseColor;
                  this.pixels[pinIndex] = false;
                  this.draggingStack.push(pinIndex);
                }
                const action = {
                  type: 'erase',
                  originalDataSet: {
                    colors: [...this.originalDataSet.colors],
                    state: [...this.originalDataSet.state]
                  },
                  changedDataSet: {
                    colors: this.getPixelColors(),
                    state: [...this.pixels],
                  }
                }
                this.isCanvasDirty = true;
                this.stateHistory.unshift(action);
                this.draggingStack = [];

                for (const clonedObject of clonedObjects) {
                  this.selectArea.add(clonedObject);
                }
                this.selectArea.addWithUpdate();
                // 2023-10-16 / 이성준 / 잘라내기 후 Esc 키 누르면 저장 안 되는 문제 수정
                this.savePage();
                canvas.renderAll();
              }
            } else {
              this.selectedTool = Tools.find(obj => obj.name === "Text");
              this.setCanvas(false, false);
              this.resetSelector();
            }
            break;
          case "y":
          case"Y":
            this.clearBraille();
            if (event.ctrlKey) {
              this.redo();
            }
            break;
          case "z":
          case "Z" :
            this.clearBraille();
            if (event.ctrlKey) {
              this.undo();
            }
            break;
          case "Escape":
            if (this.selectedTool.name === "RectSelector" || this.selectedTool.name === "LassoSelector") {
              this.resetSelector();
            }
            break;
          case "Backspace":
          case "Delete":
            if (this.selectedTool.name === "RectSelector" || this.selectedTool.name === "LassoSelector") {
              if (!this.selectArea) return;

              for (const selectedPin of this.selectedPins) {
                const pinIndex = (Math.round(selectedPin.top / this.scale) * this.VOXEL_COL_NUM) + Math.round(selectedPin.left / this.scale);

                this.PINS[pinIndex].fill = eraseColor;
                this.pixels[pinIndex] = false;
                this.draggingStack.push(pinIndex);
              }
              const action = {
                type: 'erase',
                originalDataSet: {
                  colors: [...this.originalDataSet.colors],
                  state: [...this.originalDataSet.state]
                },
                changedDataSet: {
                  colors: this.getPixelColors(),
                  state: [...this.pixels],
                }
              }
              this.isCanvasDirty = true;
              this.stateHistory.unshift(action);
              this.draggingStack = [];
              canvas.renderAll();

              this.resetSelector();
            }
            break;
          case "[":
            if (1 < this.pixelSize) {
              this.pixelSize -= 1;
            }
            break;
          case "]":
            if (this.pixelSize < 4) {
              this.pixelSize += 1;
            }
            break;
          default:
            break;
        }
      }
    },
    // 텍스트 박스 텍스트 입력 처리
    editorKeyInputListener(event) {
      switch (event.key) {
        case "Enter":
          event.preventDefault();
          if (this.currentTextItem == null) return;
          if (this.currentTextItem.isEditing) {
            this.isNewline = true;
            //this.confirmBraille();
          } else {
            this.confirmBraille();
          }
          break;
        case "Delete":
          event.preventDefault();
          if (this.currentTextItem == null) return;
          if (!this.currentTextItem.isEditing) {
            this.clearBraille();
          }
          break;
      }
    },
    // 브라우저 기본 단축키 방지
    preventDefaultShortcuts(event) {
      switch (event.key.toLowerCase()) {
        case 'a':
        case 'b':
        case 'c':
        case 'd':
        case 'e':
        case 'f':
        case 'g':
        case 'h':
        case 'i':
        case 'j':
        case 'k':
        case 'l':
        case 'm':
        case 'n':
        case 'o':
        case 'p':
        case 'q':
        case 'r':
        case 's':
        case 't':
        case 'u':
        case 'v':
        case 'w':
        case 'x':
        case 'y':
        case 'z':
          if (event.ctrlKey || event.altKey) {
            event.preventDefault();
            event.stopPropagation();
          }
      }
    },
    // 화면 크기 조정
    resize() {
      const objects = canvas.getObjects();
      let lastobj = objects[objects.length - 1];
      for (let i = 0; i < objects.length; i++) {
        canvas.remove(objects[i]);
      }
      this.removeGridLine();

      CANVAS_HEIGHT = this.VOXEL_ROW_NUM;
      CANVAS_WIDTH = this.VOXEL_COL_NUM;

      if (parentEl.clientWidth / parentEl.clientHeight > 1.5) {
        this.scale = Math.floor(parentEl.clientHeight / (CANVAS_HEIGHT));
      } else {
        this.scale = Math.floor(parentEl.clientWidth / (CANVAS_WIDTH));
      }

      this.currentWidth = CANVAS_WIDTH * this.scale;
      this.currentHeight = CANVAS_HEIGHT * this.scale;

      // Set Canvas width and height
      canvas.setWidth(this.currentWidth);
      canvas.setHeight(this.currentHeight);
      canvasGrid.setWidth(this.currentWidth);
      canvasGrid.setHeight(this.currentHeight);
      canvasPreview.setWidth(this.currentWidth);
      canvasPreview.setHeight(this.currentHeight);

      for (let r = 0; r < this.VOXEL_ROW_NUM; r++) {
        for (let c = 0; c < this.VOXEL_COL_NUM; c++) {
          const pin = new fabric.Circle({
            radius: (this.scale * this.size) / 2 * 0.75,
            fill: eraseColor,
            stroke: "",
            strokeWidth: 1,
            left: c * this.scale + lPad,
            top: r * this.scale + lPad,
            objectCaching: false,
            selectable: false,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            hoverCursor: "pointer"
          });
          canvas.add(pin);
        }
      }
      canvas.add(lastobj);
      if (this.useGrid) this.addGridLine();
      this.PINS = this.getPinObjects();
      this.drawPixels();

      // Resizing Braille Text
      if (this.currentTextItem != null) {
        this.currentTextItem.set({
          fontSize: this.scale * 3
        })
        canvasPreview.clear();
        const startIndex = (this.currentTextItem.top * this.VOXEL_COL_NUM) + this.currentTextItem.left;
        this.drawBraillePixel(startIndex);
      }

      canvas.renderAll();
      canvasPreview.renderAll();
    },
    // autoResizeDescription(e) {
    //   const textarea = e.target;
    //   textarea.style.height = 'auto';
    //   const height = textarea.scrollHeight;
    //   textarea.style.height = `${height + 8}px`;
    // },
    drawText() {
      let originalRender = fabric.Textbox.prototype._render;

      fabric.Textbox.prototype._render = function (ctx) {
        originalRender.call(this, ctx);
        var w = this.width,
          h = this.height,
          x = -this.width / 2,
          y = -this.height / 2;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x + w, y);
        ctx.lineTo(x + w, y + h);
        ctx.lineTo(x, y + h);
        ctx.lineTo(x, y);
        ctx.closePath();
        var stroke = ctx.strokeStyle;
        ctx.strokeStyle = 'rgb(0,255,26)'; // 텍스트박스가 선택되어 있지 않았을 때의 테투리 색상
        ctx.strokeWidth = 20;
        ctx.stroke();
        ctx.strokeStyle = stroke;
      }

      TextBox = fabric.util.createClass(fabric.Textbox, {
        onInput: function (e) {
          if (e.data === "\\") return;

          this.callSuper('onInput', e);
        }
      });
    },
    setAltText() {
      this.altText = document.getElementById('alt-text').value;
    },
    showDTMSFilename(file) {
      if (file.FILE_NAME.length === 0) {
        // console.log("파일명 미지정, DTMS_FILE_NO가 대신 표시됩니다.");
        return file.DTMS_FILE_NO;
      } else {
        return file.FILE_NAME;
      }
    },
    /**
     * @param {String} target
     * @param {String} targetText
     * @returns {Promise<void|null>}
     */
    async fetchTextToBraille(target, targetText) {
      let url;
      if (target === "content") {
        switch (this.brailleKind) {
          case "DOT":
            url = "/braille-app/v1/braille/translation-multiline";
            break;
          case "LIBLOUIS":
            url = "/braille-app/v1/braille/translation-liblouis-multiline";
            break;
          default:
            url = "/braille-app/v1/braille/translation-multiline";
        }
      } else if (target == "tactile") {
        url = "/braille-app/v1/tactile/translation-tactile-letters"
      } else {
        switch (this.brailleKind) {
          case "DOT":
            url = "/braille-app/v1/braille/translation-console";
            break;
          case "LIBLOUIS":
            url = "/braille-app/v1/braille/translation-liblouis";
            break;
          default:
            url = "/braille-app/v1/braille/translation-console";
        }
      }
      const params = {
        "LANGUAGE": this.brailleLang,
        "OPTION": this.brailleGrade,
        "CELL": "20",
        "TEXT": targetText
      };

      if (this.brailleKind === "LIBLOUIS") {
        params.PIN = this.braillePin?.toString();
      }

      // 변환할 텍스트 길이 체크
      if (params.TEXT.length <= 0 || params.TEXT === undefined) {
        return Promise.resolve(target === "content" ? [] : "");
      }

      let response = await $axios.post(url, params);

      if (response.status === 200) {
        return Promise.resolve(response.data.BRAILLE_RESULT);
      } else {
        throw new Error("Failed to Translation.");
      }
    },
    /**
     * @param target
     * @param targetText
     * @returns {Promise<unknown>}
     */
    textToBraille(target, targetText) {
      let hexData = targetText;

      if (!targetText) {
        return Promise.resolve("");
      }

      if (target === "content") {
        for (let i = 0; i < targetText.length; i++) {
          let targetTextElement = targetText[i];

          // Dot 일본어일 경우
          if (targetTextElement.includes("brailleCode:")) {
            targetTextElement = targetTextElement.split("brailleCode:")[1];
            targetTextElement = targetTextElement.substr(0, targetTextElement.length / 2);
            targetText[i] = targetTextElement; // Update the array element
          } else {
            break;
          }
        }

        hexData = targetText.join(' \n ');
      } else {
        // Dot 일본어일 경우
        if (hexData.includes("brailleCode:")) {
          hexData = hexData.split("brailleCode:")[1];
          hexData = hexData.substr(0, hexData.length / 2);
        }

        if (target === "") {
          hexData = hexData.replace(/\s/g, "");

          if (hexData.length % 40 !== 0) {
            const ZERO_HEX_CHAR = "0";
            const paddingCount = 40 - (hexData.length % 40);

            hexData += ZERO_HEX_CHAR.repeat(paddingCount);
          }
        }
      }

      return Promise.resolve(hexData);
    },
    // 페이지 추가
    addNewPage() {
      const pageLength = (this.pages?.length || 0);

      if (pageLength >= this.maxPage) {
        const message = this.$t("최대 100페이지까지 가능합니다.");

        this.$swal({
          title: message,
          showConfirmButton: false,
          timer: 3000
        });

        return false;
      }
      // 이전 페이지 저장
      this.savePage();
      this.clearCanvas();
      this.clearBraille();
      this.curPage = this.totalPage + 1;
      this.originalDataSet = {
        state: null,
        colors: null
      };
      this.isCanvasDirty = true;
      this.stateHistory = [];
      this.undoneChanges = [];
      this.altText = "";
      let page = new Page("Untitled", this.curPage,
          {
            name: this.curPage + ".dtm",
            data: this.pixels = Array.from({length: this.VOXEL_ROW_NUM * this.VOXEL_COL_NUM}, () => false)
          }, {
            name: this.curPage + ".dtm",
            data: this.BrailleText,
            plain: this.altText
          }, this.stateHistory, this.undoneChanges);
      this.pages.push(page);
      this.totalPage = this.pages.length;
      // 새로 추가된 페이지 저장
      // console.log('새로운 페이지 추가. 현재 페이지 갯수 : ', this.pages.length);
      if (this.selectedTool.name === "RectSelector" || this.selectedTool.name === "LassoSelector") {
        if (this.selectArea != null)
          canvas.add(this.selectArea);
      }
    },

    // 새로 만들기(New) 페이지
    async newPage() {
      const message = this.$t("현재 DTMs 파일을 저장하시겠습니까?");
      let langOption;
      if (this.dtmsOpenData.dtmsFileNo !== "" && (this.isCanvasDirty || this.modified)) // 이전 문서가 이미 저장되어 있으며 편집된 상태일 경우
      {
        // 현재 DTMs 파일을 저장하시겠습니까?되며, 저장하면 저장이되고 신규 문서가 생성된다. 저장하지 않으면 편집된 내용은 저장되지 않고 신규 문서가 생성된다.
        const result = await this.$swal({
          title: this.$t("저장"),
          text: message,
          showCancelButton: true,
          confirmButtonText: this.$t("저장"),
          cancelButtonText: this.$t("취소"),
          reverseButtons: true
        });

        if (result.isConfirmed) {
          await this.$refs.navigation.onClickSaveBtn();
        }
      } else if (this.dtmsOpenData.dtmsFileNo === "" && (this.isCanvasDirty || this.modified)) // 이전 문서가 저장되어 있지 않으며 편집된 상태일 경우
      {
        // 저장할지 말지 묻는 메시지가 표시되며, 저장하면 '다른 이름으로 저장'할 수 있다. 저장하지 않으면 편집된 내용 '다른 이름으로 저장'하지 않고 신규 문서가 생성된다.
        const result = await this.$swal({
          title: this.$t("저장"),
          text: message,
          showCancelButton: true,
          confirmButtonText: this.$t("저장"),
          cancelButtonText: this.$t("취소"),
          reverseButtons: true
        });

        if (result.isConfirmed) {
          await this.$refs.navigation.onClickSaveAsBtn();
        }
      } else  // 이전 문서가 이미 저장되어 있으며 편집되지 않은 상태일 경우, 이전 문서가 저장되어 있지 않으며 편집되지 않은 상태일 경우
      {
        // 현재 DTMs 파일을 저장하시겠습니까?하지 않고 신규 문서가 생성된다.
      }

      this.curPage = 1;
      this.pages = [];
      this.dtmsOpenData = {
        dtmsGubun: "", // DTMS 구분(PUBLIC, PRIVATE)
        dtmsFileNo: "", // DTMS 파일번호
        dtmsGroupNo: ""
      };
      langOption = this.brailleGrade;
      this.dtms = new DTMS({"title": "", "lang": this.brailleLang, "lang_option": langOption});

      this.clearCanvas();
      this.isCanvasDirty = false;
      this.stateHistory = [];
      this.undoneChanges = [];
      this.altText = "";
      let page = new Page("Untitled", this.curPage,
        {
          name: this.curPage + ".dtm",
          data: this.pixels = Array.from({length: this.VOXEL_ROW_NUM * this.VOXEL_COL_NUM}, () => false)
        }, {
          name: this.curPage + ".dtm",
          data: this.BrailleText,
          plain: this.altText
        }, this.stateHistory, this.undoneChanges);
      this.pages.push(page);
      this.totalPage = this.pages.length;
      this.clearBraille();
    },
    // 드로잉 기능
    startPosition(event) {
      if (event.button === 3) {
        this.useRightButton = false;
      }
      this.originalDataSet = {
        state: [...this.pixels],
        colors: this.getPixelColors(),
      };
      this.painting = true;
      this.modified = true;
      this.figureDrawStarted = true;
      this.lassoPixels = [];
      this.lassoGroup = new fabric.Group([], {
        hasControls: false,
        hasBorders: true
      });
      this.rectGroup = null;
      this.getCoords(event);
      this.startPos.cx = this.curPos.cx;
      this.startPos.cy = this.curPos.cy;
      // Lasso 셀렉터
      this.previousPos.cx = this.curPos.cx;
      this.previousPos.cy = this.curPos.cy;
      this.mousePositionIndex = this.curPos.cy * this.VOXEL_COL_NUM + this.curPos.cx;
      this.mouseStartPositionIndex = this.mousePositionIndex;
      this.mousePositionIndex_old = this.mousePositionIndex;
      this.drawPixel(event);
    },
    getPinObjects() {
      const objects = canvas.getObjects();
      let tmpList = [];
      for (let i = 0; i < 2400; i++) {
        tmpList.push(objects[i]);
      }
      return tmpList;
    },
    finishedPosition(event) {
      this.painting = false;
      this.endPos = this.curPos;
      if (this.selectedTool.name === "Square"
        || this.selectedTool.name === "Line"
        || this.selectedTool.name === "Circle"
        || this.selectedTool.name === "Triangle"
        || this.selectedTool.name === "Template") {
        // let res = draw.figureDraw(this.startPos, this.endPos, this.selectedTool.name, this.VOXEL_ROW_NUM, this.VOXEL_COL_NUM, this.pixelSize);
        // this.drawFigure(res);
        this.drawFigure(this.lastShapePins);
        this.clearBraille();
      } else if (this.selectedTool.name === "Move") {
        let pins = canvas.getObjects();
        for (let i = 0; i < 2400; i++) {
          if (this.pixels[i]) {
            this.draggingStack.push(i);
          }
          if (this.pixels[i] && pins[i].fill == eraseColor) {
            this.pixels[i] = false;
          }
        }
        for (let i = 0; i < 2400; i++) {
          if (pins[i].fill !== eraseColor) {
            this.pixels[i] = true;
            this.draggingStack.push(i);
          } else {
            this.pixels[i] = false;
          }
        }
      } else if (this.selectedTool.name === "RectSelector") {
        if (this.selectArea == null) {
          this.rectGroup = new fabric.Group([new fabric.Rect({
            left: this.startPos.cx * this.scale,
            top: this.startPos.cy * this.scale,
            fill: 'rgba(0,255,244,0.61)',
            width: (this.endPos.cx - this.startPos.cx) * this.scale,
            height: (this.endPos.cy - this.startPos.cy) * this.scale,
            objectCaching: false,
            selectable: true,
            hasControls: false
          })], {
            hasBorders: false,
            hasControls: false
          });
          this.selectArea = this.rectGroup;
          this.selector.type = 'rect';
          canvas.add(this.selectArea);
          this.selectAreaStartPos.left = this.selectArea.left;
          this.selectAreaStartPos.top = this.selectArea.top;
          this.findSelectedPins(1);
        }
      } else if (this.selectedTool.name === "LassoSelector") {
        if (this.selectArea == null) {
          let finishLassoPixels = draw.figureDraw(this.previousPos, this.startPos, "Line", this.VOXEL_ROW_NUM, this.VOXEL_COL_NUM, this.pixelSize);
          this.lassoPixels = this.lassoPixels.concat(finishLassoPixels);
          this.renderLasso(this.lassoPixels);
          const lassoSet = new Set(Array.from(this.lassoPixels).sort((a, b) => a - b));

          let _prevBoundary = 0;
          let isFirst = true;
          const boundaries = Array.from(lassoSet);
          for (const _boundary of boundaries) {
            if (isFirst) {
              isFirst = false;
              continue;
            }
            // 이전 경계와 현재 경계가 같은 라인에 있는 지 확인
            if (Math.floor(_boundary / this.VOXEL_COL_NUM) == Math.floor(_prevBoundary / this.VOXEL_COL_NUM)) {
              const distance = (_boundary - _prevBoundary);
              if (distance > 1) {
                for (let i = 1; i < distance; i++) {
                  //현재 경계값의 위.아래로 픽셀이 존재하는지 확인 (셀렉션 내부 영역)
                  if (this.isInSelection(_prevBoundary + i, boundaries)) {
                    lassoSet.add(_prevBoundary + i);
                  }
                }
              }
            }
            _prevBoundary = _boundary;
          }
          // 기존에 그려진 lassoGroup 제거
          this.renderLasso(lassoSet);
          canvas.remove(this.lassoGroup);
          this.selectArea = this.lassoGroup;
          this.selector.type = 'lasso';
          canvas.add(this.selectArea);
          canvas.renderAll();
          this.cachedLassoPixels = Array.from(lassoSet);
          this.selectAreaStartPos.left = this.selectArea.left;
          this.selectAreaStartPos.top = this.selectArea.top;
          this.findSelectedPins(2);
        }
      }
      this.endDrawing(this.draggingStack);
      // this.draggingStack = []; // 한 획이 그려지는 동안 변경된 pin 스택을 초기화
      this.figureDrawStarted = false;
      if (event.button === 3) {
        this.useRightButton = false;
      }
      this.savePage();
    },
    endDrawing(dragChangeList = [0]) {
      if (this.stateHistory !== undefined && dragChangeList.length !== 0) {
        // 페이지 단위로 현재 캔버스 상태를 저장.
        let action = {
          type: '',
          originalDataSet: {
            colors: [...this.originalDataSet.colors],
            state: [...this.originalDataSet.state]
          },
          changedDataSet: {
            colors: this.getPixelColors(),
            state: [...this.pixels],
          }
        };

        switch (this.selectedTool.name) {
          case "Pen":
          case "PaintBucket":
          case "VerticalMirrorPen":
            action.type = "draw";
            break;
          case "Square":
          case "Line":
          case "Circle":
          case "Triangle":
            action.type = "shape";
            break;
          case "Eraser":
          case "EraserAll":
            action.type = "erase";
            break;
          case "Move":
            action.type = "move";
            break;
          case "ImageEdge":
            action.type = "draw";
            break;
        }
        this.isCanvasDirty = true;
        this.stateHistory.unshift(action);
      }
      this.draggingStack = [];
    },
    drawShapePreview(shapePins) {
      if (shapePins.length === 0) {
        this.patternEmpty = true;
      } else {
        this.patternEmpty = false;
      }

      for (const shapePin of shapePins) {
        const pinLeft = (shapePin % this.VOXEL_COL_NUM) * this.scale + lPad;
        const pinTop = Math.floor(shapePin / this.VOXEL_COL_NUM) * this.scale + lPad;

        const pin = new fabric.Circle({
          radius: (this.scale * this.size) / 2 * 0.75,
          fill: "rgba(0,0,0,0.32)",
          stroke: "",
          strokeWidth: 0,
          left: pinLeft,
          top: pinTop,
          objectCaching: false,
          selectable: false,
          hasControls: false,
          hasBorders: false,
          hasRotatingPoint: false,
          hoverCursor: "pointer"
        });
        canvasPreview.add(pin);
      }
      this.lastShapePins = shapePins;
    },
    removeShapePreview() {
      canvasPreview.clear();
    },
    getCoords(e) {
      let rect = this.$refs.board.getBoundingClientRect();

      if (e.e.targetTouches) {
        e.e = e.e.targetTouches[0];
      }

      this.x = Math.floor((e.e.clientX - rect.left) / this.scale) * this.scale;
      this.y = Math.floor((e.e.clientY - rect.top) / this.scale) * this.scale;
      this.curPos.cx = Math.floor(this.x * this.VOXEL_COL_NUM / this.currentWidth);
      this.curPos.cy = Math.floor(this.y * this.VOXEL_ROW_NUM / this.currentHeight);
    },
    drawPixel(event) {
      if (!this.painting) {
        return;
      }
      this.getCoords(event);

      // 이전 위치와 같은 위치인지 확인
      if (this.selectedTool.name === this.$refs.toolbar.previousSelectedTool?.name &&
        this.curPos_old.cx === this.curPos.cx &&
        this.curPos_old.cy === this.curPos.cy) {
        return;
      } else if (this.curPos.cx === -1 || this.curPos.cy === -1) { // 현재 위치가 캔버스 내부인지 확인
        return;
      }
      this.mousePositionIndex = this.curPos.cy * this.VOXEL_COL_NUM + this.curPos.cx;
      let target = this.PINS[this.mousePositionIndex];
      if (this.selectedTool.name === "Pen"
        || this.selectedTool.name === "Eraser"
        || this.selectedTool.name === "VerticalMirrorPen") {
        //intervoid : 떨어진 만큼 반복을 해줘야 함
        let diff = Math.abs(this.mousePositionIndex - this.mousePositionIndex_old) % this.VOXEL_COL_NUM;
        if (diff <= 1 && (Math.abs(this.mousePositionIndex - this.mousePositionIndex_old)) > (this.VOXEL_COL_NUM + 1))
          diff = diff + 2;

        if (this.curPos.cx < 0) {
          this.curPos.cx = 0;
        } else if (this.curPos.cx > 59) {
          this.curPos.cx = 59;
        } else if (this.curPos.cy < 0) {
          this.curPos.cy = 0;
        } else if (this.curPos.cy > 40) {
          this.curPos.cy = 40;
        }

        // 너무 빨리 드로잉 시 보간을 위한 코드
        if (diff > 1) {
          let adjPins = this.interpolationMouseMove(this.curPos, this.curPos_old);

          for (let i in adjPins) {
            if (this.mousePositionIndex_old != adjPins[i]) {
              this.mousePositionIndex = adjPins[i];
              target = this.PINS[this.mousePositionIndex];

              if (this.pixelSize == 1) {
                this.draw(target, this.mousePositionIndex);
                if (this.selectedTool.name === "VerticalMirrorPen") {
                  this.curPos.cx = this.mousePositionIndex % this.VOXEL_COL_NUM;
                  this.curPos.cy = Math.floor(this.mousePositionIndex / this.VOXEL_COL_NUM);
                  this.mirrorPen(event.e.shiftKey, event.e.ctrlKey);
                }
              } else {
                // 펜 사이즈가 1이 아닐 때
                this.setPixelSize();
                if (this.selectedTool.name === "VerticalMirrorPen") {
                  this.curPos.cx = this.mousePositionIndex % this.VOXEL_COL_NUM;
                  this.curPos.cy = Math.floor(this.mousePositionIndex / this.VOXEL_COL_NUM);
                  this.mirrorPen(event.e.shiftKey, event.e.ctrlKey);
                }
              }
            }
          }
        } else {
          if (this.pixelSize == 1) {
            this.draw(target, this.mousePositionIndex);
            if (this.selectedTool.name === "VerticalMirrorPen") {
              this.mirrorPen(event.e.shiftKey, event.e.ctrlKey);
            }
          } else {
            // 펜 사이즈가 1이 아닐 때
            this.setPixelSize();
            if (this.selectedTool.name === "VerticalMirrorPen") {
              this.mirrorPen(event.e.shiftKey, event.e.ctrlKey);
            }
          }
        }
      } else if (this.selectedTool.name === "Move") {
        if (this.figureDrawStarted) {
          if (this.pixels[this.mousePositionIndex]) {
            this.figureDrawStarted = false;
            this.findSelectedPins(0);
          }
        } else {
          this.figureMove(this.mousePositionIndex);
        }
      } else if (this.selectedTool.name === "Square"
        || this.selectedTool.name === "Line"
        || this.selectedTool.name === "Circle"
        || this.selectedTool.name === "Triangle") {
        if (this.figureDrawStarted)
          this.figureDrawStarted = false;
        else {
          if (!this.patternEmpty) {
            this.removeShapePreview();
            // this.undo();
            // this.undoneChanges.shift();
          }
        }
        this.endPos = this.curPos;
        const keepRatio = event.e.shiftKey;
        let res = draw.figureDraw(this.startPos, this.endPos, this.selectedTool.name, this.VOXEL_ROW_NUM, this.VOXEL_COL_NUM, this.pixelSize, keepRatio);
        // this.drawFigure(res);
        // this.endDrawing(this.draggingStack);
        this.drawShapePreview(res);
        this.draggingStack = []; // 한 획이 그려지는 동안 변경된 pin 스택을 초기화
      } else if (this.selectedTool.name === "Template") {
        if (this.figureDrawStarted) {
          this.figureDrawStarted = false;
        } else {
          if (!this.patternEmpty) {
            this.removeShapePreview();
          }
        }
        this.endPos = this.curPos;
        const keepRatio = event.e.shiftKey;
        const res = draw.figureDraw(this.startPos, this.endPos, this.$refs.toolbar.templateShape, this.VOXEL_ROW_NUM, this.VOXEL_COL_NUM, this.pixelSize, keepRatio);
        this.drawShapePreview(res);
        this.draggingStack = []; // 한 획이 그려지는 동안 변경된 pin 스택을 초기화
      } else if (this.selectedTool.name === "Text") {
        this.addTextItem(this.curPos.cx * this.scale, this.curPos.cy * this.scale);
        this.enterEditorMode();
      } else if (this.selectedTool.name === "AltText") {
        this.enterEditorMode();
      } else if (this.selectedTool.name === "RectSelector") {
        if (this.selectArea != null) return;
      } else if (this.selectedTool.name === "LassoSelector") {
        if (this.selectArea != null) return;
        //intervoid : 떨어진 만큼 반복을 해줘야 함
        let diff = Math.abs(this.mousePositionIndex - this.mousePositionIndex_old) % this.VOXEL_COL_NUM;
        if ((Math.abs(this.mousePositionIndex - this.mousePositionIndex_old)) > (this.VOXEL_COL_NUM + 1))
          diff = diff + 2;

        if (diff > 1) {
          let adjPins = this.interpolationMouseMove(this.curPos, this.curPos_old);

          for (let i in adjPins) {
            if (this.mousePositionIndex_old != adjPins[i]) {
              this.mousePositionIndex = adjPins[i];
              target = this.PINS[this.mousePositionIndex];
              this.curPos.cx = this.mousePositionIndex % this.VOXEL_COL_NUM;
              this.curPos.cy = Math.floor(this.mousePositionIndex / this.VOXEL_COL_NUM);
              //console.log(`drawPixel : this.curPos.cx[${this.curPos.cx}], this.curPos.cy[${this.curPos.cy}]`);
              this.addPixels_(this.curPos.cx, this.curPos.cy);
            }
          }
        }  else {
          this.addPixels_(this.curPos.cx, this.curPos.cy);
        }
        let finishLassoPixels = draw.figureDraw(this.previousPos, this.startPos, "Line", this.VOXEL_ROW_NUM, this.VOXEL_COL_NUM, this.pixelSize);
        this.renderLasso(this.lassoPixels, finishLassoPixels);
      } else if (this.selectedTool.name === "TactileLetters") {
        if (this.selectArea != null) return;
      }
      this.curPos_old.cx = this.curPos.cx;
      this.curPos_old.cy = this.curPos.cy;
      canvas.renderAll();
    },
    interpolationMouseMove(curPos, curPos_old) {
      let resultArr = [];
      const index = (a, b) => {
        return b * this.VOXEL_COL_NUM + a;
      };

      let x0 = curPos_old.cx;
      let y0 = curPos_old.cy;

      let x1 = curPos.cx; //normalize(curPos.cx, 0);
      let y1 = curPos.cy; //normalize(curPos.cy, 0);

      let dx = Math.abs(x1 - x0);
      let dy = Math.abs(y1 - y0);

      let sx = (x0 < x1) ? 1 : -1;
      let sy = (y0 < y1) ? 1 : -1;

      let err = dx - dy;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if ((x0 >= 0 && x0 < this.VOXEL_COL_NUM) && (y0 >= 0 && y0 < this.VOXEL_ROW_NUM)) {
          resultArr.push(index(x0, y0));
        }
        if ((x0 === x1) && (y0 === y1)) {
          break;
        }
        let e2 = 2 * err;
        if (e2 > -dy) {
          err -= dy;
          x0 += sx;
        }
        if (e2 < dx) {
          err += dx;
          y0 += sy;
        }
      }
      return resultArr;
    },
    mirrorPen(shift = false, ctrl = false) {
      const x = 2;
      // Vertical Mirror Pen
      let leftPoint = null;
      let rightPoint = null;
      let leftPoint_Index = 0;
      let RightPoint_Index = 0;

      // Horizontal Mirror Pen
      let upPoint = null;
      let downPoint = null;
      let upPoint_Index = 0;
      let downPoint_Index = 0;

      if (ctrl) {
        if (this.curPos.cy < Math.floor(this.VOXEL_ROW_NUM / 2)) {
          if (this.pixelSize === 1) {
            upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))));
            upPoint = this.PINS[upPoint_Index];
            this.draw(upPoint, upPoint_Index);
          } else if (this.pixelSize === 2) {
            for (let i = 0; i < 2; i++) {
              for (let j = 60; j < 61; j++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - j));
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);
              }
              upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + i);
              upPoint = this.PINS[upPoint_Index];
              this.draw(upPoint, upPoint_Index);
            }
          } else if (this.pixelSize === 3) {
            for (let i = 0; i < 3; i++) {
              for (let j = 60; j < 61; j++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - j));
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);
              }
              upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + i);
              upPoint = this.PINS[upPoint_Index];
              this.draw(upPoint, upPoint_Index);
              for (let k = 120; k < 121; k++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - k));
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);
              }
            }
          } else if (this.pixelSize === 4) {
            for (let i = 0; i < 4; i++) {
              for (let j = 60; j < 61; j++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - j));
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);
              }
              upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + i);
              upPoint = this.PINS[upPoint_Index];
              this.draw(upPoint, upPoint_Index);
              for (let k = 120; k < 121; k++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - k));
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);
              }
              for (let l = 180; l < 181; l++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - l));
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);
              }
            }
          }
        } else if (this.curPos.cy >= Math.floor(this.VOXEL_ROW_NUM / 2)) {
          if (this.pixelSize === 1) {
            downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)));
            downPoint = this.PINS[downPoint_Index];
            this.draw(downPoint, downPoint_Index);
          } else if (this.pixelSize === 2) {
            for (let i = 0; i < 2; i++) {
              for (let j = 60; j < 61; j++) {
                downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - j));
                downPoint = this.PINS[downPoint_Index];
                this.draw(downPoint, downPoint_Index);
              }
              downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + i);
              downPoint = this.PINS[downPoint_Index];
              this.draw(downPoint, downPoint_Index);
            }
          } else if (this.pixelSize === 3) {
            for (let i = 0; i < 3; i++) {
              for (let j = 60; j < 61; j++) {
                downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - j));
                downPoint = this.PINS[downPoint_Index];
                this.draw(downPoint, downPoint_Index);
              }
              downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + i);
              downPoint = this.PINS[downPoint_Index];
              this.draw(downPoint, downPoint_Index);
              for (let k = 120; k < 121; k++) {
                downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - k));
                downPoint = this.PINS[downPoint_Index];
                this.draw(downPoint, downPoint_Index);
              }
            }
          } else if (this.pixelSize === 4) {
            for (let i = 0; i < 4; i++) {
              for (let j = 60; j < 61; j++) {
                downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - j));
                downPoint = this.PINS[downPoint_Index];
                this.draw(downPoint, downPoint_Index);
              }
              downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + i);
              downPoint = this.PINS[downPoint_Index];
              this.draw(downPoint, downPoint_Index);
              for (let k = 120; k < 121; k++) {
                downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - k));
                downPoint = this.PINS[downPoint_Index];
                this.draw(downPoint, downPoint_Index);
              }
              for (let l = 180; l < 181; l++) {
                downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - l));
                downPoint = this.PINS[downPoint_Index];
                this.draw(downPoint, downPoint_Index);
              }
            }
          }
        }
      } else {
        if (this.curPos.cx <= 29) {
          if (this.pixelSize == 1) {
            leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)));
            leftPoint = this.PINS[leftPoint_Index];
            this.draw(leftPoint, leftPoint_Index);

            if (shift) {
              // 원본의 Horizontal
              upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))));
              upPoint = this.PINS[upPoint_Index];
              this.draw(upPoint, upPoint_Index);

              // Vertical의 Horizontal
              leftPoint_Index = (upPoint_Index + (59 - (x * this.curPos.cx)));
              leftPoint = this.PINS[leftPoint_Index];
              this.draw(leftPoint, leftPoint_Index);
            }
          } else if (this.pixelSize == 2) {
            for (var i = 0; i < 2; i++) {
              leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 1));
              leftPoint = this.PINS[leftPoint_Index];
              this.draw(leftPoint, leftPoint_Index);
              for (var j = 60; j < 61; j++) {
                leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + ((i - 1) + j));
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);
              }
            }

            if (shift) {
              let horizontalIndex = null;
              for (let i = 0; i < 2; i++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + i);
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);

                if (!horizontalIndex) {
                  horizontalIndex = upPoint_Index;
                }

                leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 1));
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);

                for (let j = 60; j < 61; j++) {
                  upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - j));
                  upPoint = this.PINS[upPoint_Index];
                  this.draw(upPoint, upPoint_Index);
                  // console.log(`Horizontal: ${this.mousePositionIndex}, upPoint: ${upPoint_Index}`);

                  leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + ((i - 1) - j));
                  leftPoint = this.PINS[leftPoint_Index];
                  this.draw(leftPoint, leftPoint_Index);
                  // console.log(`Vertical: ${upPoint_Index}, upPoint: ${leftPoint_Index}`);
                }
              }
            }
          } else if (this.pixelSize == 3) {
            for (i = 0; i < 3; i++) {
              leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 2));
              leftPoint = this.PINS[leftPoint_Index];
              this.draw(leftPoint, leftPoint_Index);

              for (j = 60; j < 61; j++) {
                leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 2) + j);
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);
              }

              for (var k = 120; k < 121; k++) {
                leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 2) + k);
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);
              }
            }

            if (shift) {
              let horizontalIndex = null;

              for (let i = 0; i < 3; i++) {
                upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + i);
                upPoint = this.PINS[upPoint_Index];
                this.draw(upPoint, upPoint_Index);

                if (!horizontalIndex) {
                  horizontalIndex = upPoint_Index;
                }

                leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 2));
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);

                for (let j = 60; j < 61; j++) {
                  upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - j));
                  upPoint = this.PINS[upPoint_Index];
                  this.draw(upPoint, upPoint_Index);

                  leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 2) - j);
                  leftPoint = this.PINS[leftPoint_Index];
                  this.draw(leftPoint, leftPoint_Index);
                }

                for (let k = 120; k < 121; k++) {
                  upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - k));
                  upPoint = this.PINS[upPoint_Index];
                  this.draw(upPoint, upPoint_Index);

                  leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 2) - k);
                  leftPoint = this.PINS[leftPoint_Index];
                  this.draw(leftPoint, leftPoint_Index);
                }
              }
            }
          } else if (this.pixelSize == 4) {
            for (i = 0; i < 4; i++) {
              leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 3));
              leftPoint = this.PINS[leftPoint_Index];
              this.draw(leftPoint, leftPoint_Index);

              for (j = 60; j < 61; j++) {
                leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 3) + j);
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);
              }

              for (k = 120; k < 121; k++) {
                leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 3) + k);
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);
              }

              for (var l = 180; l < 181; l++) {
                leftPoint_Index = (this.mousePositionIndex + (59 - (x * this.curPos.cx)) + (i - 3) + l);
                leftPoint = this.PINS[leftPoint_Index];
                this.draw(leftPoint, leftPoint_Index);
              }

              if (shift) {
                let horizontalIndex = null;

                for (let i = 0; i < 4; i++) {
                  upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + i);
                  upPoint = this.PINS[upPoint_Index];
                  this.draw(upPoint, upPoint_Index);

                  if (!horizontalIndex) {
                    horizontalIndex = upPoint_Index;
                  }

                  leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 3));
                  leftPoint = this.PINS[leftPoint_Index];
                  this.draw(leftPoint, leftPoint_Index);

                  for (let j = 60; j < 61; j++) {
                    upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - j));
                    upPoint = this.PINS[upPoint_Index];
                    this.draw(upPoint, upPoint_Index);

                    leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 3) - j);
                    leftPoint = this.PINS[leftPoint_Index];
                    this.draw(leftPoint, leftPoint_Index);
                  }

                  for (let k = 120; k < 121; k++) {
                    upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - k));
                    upPoint = this.PINS[upPoint_Index];
                    this.draw(upPoint, upPoint_Index);

                    leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 3) - k);
                    leftPoint = this.PINS[leftPoint_Index];
                    this.draw(leftPoint, leftPoint_Index);
                  }

                  for (let l = 180; l < 181; l++) {
                    upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))) + (i - l));
                    upPoint = this.PINS[upPoint_Index];
                    this.draw(upPoint, upPoint_Index);

                    leftPoint_Index = (horizontalIndex + (59 - (x * this.curPos.cx)) + (i - 3) - l);
                    leftPoint = this.PINS[leftPoint_Index];
                    this.draw(leftPoint, leftPoint_Index);
                  }
                }
              }
            }
          }
        } else if (this.curPos.cx >= 30) {
          if (this.pixelSize == 1) {
            RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59));
            rightPoint = this.PINS[RightPoint_Index];
            this.draw(rightPoint, RightPoint_Index);

            if (shift) {
              upPoint_Index = (this.mousePositionIndex + (60 * (39 - (x * this.curPos.cy))));
              upPoint = this.PINS[upPoint_Index];
              this.draw(upPoint, upPoint_Index);

              RightPoint_Index = (upPoint_Index - ((x * this.curPos.cx) - 59));
              rightPoint = this.PINS[RightPoint_Index];
              this.draw(rightPoint, RightPoint_Index);
            }
          } else if (this.pixelSize == 2) {
            for (i = 0; i < 2; i++) {
              RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 1));
              rightPoint = this.PINS[RightPoint_Index];
              this.draw(rightPoint, RightPoint_Index);

              for (j = 60; j < 61; j++) {
                RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 1) + j);
                rightPoint = this.PINS[RightPoint_Index];
                this.draw(rightPoint, RightPoint_Index);
              }
            }

            if (shift) {
              let horizontalIndex = null;

              for (let i = 0; i < 2; i++) {
                downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + i);
                downPoint = this.PINS[downPoint_Index];
                this.draw(downPoint, downPoint_Index);

                if (!horizontalIndex) {
                  horizontalIndex = downPoint_Index;
                }

                RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 1));
                rightPoint = this.PINS[RightPoint_Index];
                this.draw(rightPoint, RightPoint_Index);

                for (let j = 60; j < 61; j++) {
                  downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - j));
                  downPoint = this.PINS[downPoint_Index];
                  this.draw(downPoint, downPoint_Index);

                  RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 1) + j);
                  rightPoint = this.PINS[RightPoint_Index];
                  this.draw(rightPoint, RightPoint_Index);
                }
              }
            }
          } else if (this.pixelSize == 3) {
            for (i = 0; i < 3; i++) {
              RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 2));
              rightPoint = this.PINS[RightPoint_Index];
              this.draw(rightPoint, RightPoint_Index);

              for (j = 60; j < 61; j++) {
                RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 2) + j);
                rightPoint = this.PINS[RightPoint_Index];
                this.draw(rightPoint, RightPoint_Index);
              }

              for (k = 120; k < 121; k++) {
                RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 2) + k);
                rightPoint = this.PINS[RightPoint_Index];
                this.draw(rightPoint, RightPoint_Index);
              }

              if (shift) {
                let horizontalIndex = null;

                for (let i = 0; i < 3; i++) {
                  downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + i);
                  downPoint = this.PINS[downPoint_Index];
                  this.draw(downPoint, downPoint_Index);

                  if (!horizontalIndex) {
                    horizontalIndex = downPoint_Index;
                  }

                  RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 2));
                  rightPoint = this.PINS[RightPoint_Index];
                  this.draw(rightPoint, RightPoint_Index);

                  for (let j = 60; j < 61; j++) {
                    downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - j));
                    downPoint = this.PINS[downPoint_Index];
                    this.draw(downPoint, downPoint_Index);

                    RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 2) - j);
                    rightPoint = this.PINS[RightPoint_Index];
                    this.draw(rightPoint, RightPoint_Index);
                  }

                  for (let k = 120; k < 121; k++) {
                    downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - k));
                    downPoint = this.PINS[downPoint_Index];
                    this.draw(downPoint, downPoint_Index);

                    RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 2) - k);
                    rightPoint = this.PINS[RightPoint_Index];
                    this.draw(rightPoint, RightPoint_Index);
                  }
                }
              }
            }
          } else if (this.pixelSize == 4) {
            for (i = 0; i < 4; i++) {
              RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 3));
              rightPoint = this.PINS[RightPoint_Index];
              this.draw(rightPoint, RightPoint_Index);
              for (j = 60; j < 61; j++) {
                RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 3) + j);
                rightPoint = this.PINS[RightPoint_Index];
                this.draw(rightPoint, RightPoint_Index);
              }
              for (k = 120; k < 121; k++) {
                RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 3) + k);
                rightPoint = this.PINS[RightPoint_Index];
                this.draw(rightPoint, RightPoint_Index);
              }
              for (l = 180; l < 181; l++) {
                RightPoint_Index = (this.mousePositionIndex - ((x * this.curPos.cx) - 59) + (i - 3) + l);
                rightPoint = this.PINS[RightPoint_Index];
                this.draw(rightPoint, RightPoint_Index);
              }

              if (shift) {
                let horizontalIndex = null;

                for (let i = 0; i < 4; i++) {
                  downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + i);
                  downPoint = this.PINS[downPoint_Index];
                  this.draw(downPoint, downPoint_Index);

                  if (!horizontalIndex) {
                    horizontalIndex = downPoint_Index;
                  }

                  RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 3));
                  rightPoint = this.PINS[RightPoint_Index];
                  this.draw(rightPoint, RightPoint_Index);

                  for (let j = 60; j < 61; j++) {
                    downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - j));
                    downPoint = this.PINS[downPoint_Index];
                    this.draw(downPoint, downPoint_Index);

                    RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 3) - j);
                    rightPoint = this.PINS[RightPoint_Index];
                    this.draw(rightPoint, RightPoint_Index);
                  }

                  for (let k = 120; k < 121; k++) {
                    downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - k));
                    downPoint = this.PINS[downPoint_Index];
                    this.draw(downPoint, downPoint_Index);

                    RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 3) - k);
                    rightPoint = this.PINS[RightPoint_Index];
                    this.draw(rightPoint, RightPoint_Index);
                  }
                  for (let l = 180; l < 181; l++) {
                    downPoint_Index = (this.mousePositionIndex - (60 * ((x * this.curPos.cy) - 39)) + (i - l));
                    downPoint = this.PINS[downPoint_Index];
                    this.draw(downPoint, downPoint_Index);

                    RightPoint_Index = (horizontalIndex - ((x * this.curPos.cx) - 59) + (i - 3) - l);
                    rightPoint = this.PINS[RightPoint_Index];
                    this.draw(rightPoint, RightPoint_Index);
                  }
                }
              }
            }
          }
        }
      }
      //canvas.renderAll();
    },
    setModified() {
      this.modified = true;
      this.pages[this.curPage - 1].text.plain = this.altText;
      this.pages[this.curPage - 1].text.data = this.BrailleText;
    },
    setPenColor(type, color) {
      if (type === 'main') {
        this.mainColor = color;
      } else if (type === 'sub') {
        this.subColor = color;
      }
      penColor = this.mainColor;
    },
    switchColor() {
      let tmp = this.mainColor.toString();
      this.mainColor = this.subColor;
      this.subColor = tmp;
      penColor = this.mainColor;
    },
    resetColor() {
      this.mainColor = "rgba(0, 0, 0, 255)";
      this.subColor = "rgba(255, 255, 0, 255)";
      penColor = this.mainColor;
    },
    // 그리기
    draw(target, index) {
      if (index < 0 || index > 2399) {
        return;
      }

      if (this.selectedTool.name === "Pen" ||
        this.selectedTool.name === "Line" ||
        this.selectedTool.name === "VerticalMirrorPen"
      ) {
        target.fill = (this.useRightButton) ? this.subColor : penColor;
        this.pixels[index] = true;
        this.mousePositionIndex_old = index;
        this.draggingStack.push(index);
      } else if (this.selectedTool.name === "PaintBucket") {
        target.fill = (this.useRightButton) ? this.subColor : penColor;
        this.pixels[index] = true;
        this.mousePositionIndex_old = index;
        this.draggingStack.push(index);
      } else if (this.selectedTool.name === "Eraser") {
        if (this.pixels[index]) {
          target.fill = eraseColor;
          this.pixels[index] = false;
          this.draggingStack.push(index);
        }
      }
    },
    drawFigure(figurePattern) {
      if (figurePattern.length === 0) {
        this.patternEmpty = true;
      } else {
        this.patternEmpty = false;
      }
      for (const pinNumber of figurePattern) {
        if (isNaN(pinNumber) || 0 > pinNumber || pinNumber >= this.VOXEL_ROW_NUM * this.VOXEL_COL_NUM) continue;
        this.draggingStack.push(pinNumber);
        this.pixels[pinNumber] = true;
        this.PINS[pinNumber].fill = (this.useRightButton) ? this.subColor : penColor;
      }
      canvas.renderAll();
    },
    drawPixels() {
      if (this.stateHistory.length > 0 && this.selectedTool.name !== "ImageEdge") {
        const lastStates = this.stateHistory[0];
        this.setPixelColors(lastStates.changedDataSet.colors);
      } else {
        let pins = this.getPinObjects();
        for (let i = 0; i < this.pixels.length; i++) {
          if (this.pixels[i]) {
            pins[i].fill = penColor;
          } else {
            pins[i].fill = eraseColor;
          }
        }
      }
      canvas.renderAll();
    },
    setPixelSize() {
      var col = 0;
      if (this.mousePositionIndex % 60 < 57) {
        col = 4;
      } else {
        col = 60 - (this.mousePositionIndex % 60);
      }

      if (this.pixelSize == 2) {
        if (col > 2) {
          col = 2;
        }
        for (a = 0; a < col; a++) {
          var targetPoint = this.PINS[this.mousePositionIndex + a];
          var targetPoint2 = this.PINS[this.mousePositionIndex + (a + 60)];

          this.draw(targetPoint, this.mousePositionIndex + a);
          this.draw(targetPoint2, this.mousePositionIndex + (a + 60));
          //console.log(`setPixelSize : a[${this.mousePositionIndex + a}], targetPoint2, (a + 60)[${targetPoint2, this.mousePositionIndex + (a + 60)}]`);
        }
      } else if (this.pixelSize == 3) {
        if (col == 4) {
          col = 3;
        }
        for (a = 0; a < col; a++) {
          targetPoint = this.PINS[this.mousePositionIndex + a];
          targetPoint2 = this.PINS[this.mousePositionIndex + (a + 60)];
          var targetPoint3 = this.PINS[this.mousePositionIndex + (a + 120)];

          this.draw(targetPoint, this.mousePositionIndex + a);
          this.draw(targetPoint2, this.mousePositionIndex + (a + 60));
          this.draw(targetPoint3, this.mousePositionIndex + (a + 120));
        }
      } else if (this.pixelSize == 4) {
        for (var a = 0; a < col; a++) {
          targetPoint = this.PINS[this.mousePositionIndex + a];
          targetPoint2 = this.PINS[this.mousePositionIndex + (a + 60)];
          targetPoint3 = this.PINS[this.mousePositionIndex + (a + 120)];
          var targetPoint4 = this.PINS[this.mousePositionIndex + (a + 180)];

          this.draw(targetPoint, this.mousePositionIndex + a);
          this.draw(targetPoint2, this.mousePositionIndex + (a + 60));
          this.draw(targetPoint3, this.mousePositionIndex + (a + 120));
          this.draw(targetPoint4, this.mousePositionIndex + (a + 180));
        }
      }
      //canvas.renderAll();
    },
    paintBucket() {
      if (this.selectedTool.name === "PaintBucket") {
        let dir = [-1, 60, 1, -60]
        let queue = [];
        let startPoint = this.mousePositionIndex;
        const paintColor = (this.useRightButton) ? this.subColor : penColor;
        // console.log(`startPoints: ${startPoint}`);

        queue.push(startPoint);
        let current = queue[0];

        // 빈 영역 안을 칠 할 경우
        if (this.pixels[startPoint]) {
          let targetColor = this.PINS[current].fill;
          // 지정한 핀색상과 현재 칠 색상이 동일한 경우 칠 X
          if (targetColor === paintColor) return;

          while (0 < queue.length + 1) {
            if (current == null) {
              break;
            }

            for (let i = 0; i < 4; i++) {
              let nextPos = current + dir[i];
              if (nextPos >= 0 && nextPos <= 2399) {
                if (this.pixels[nextPos]) {
                  if (targetColor === this.PINS[nextPos].fill) {
                    queue.push(nextPos);
                  } else {
                    continue;
                  }
                } else {
                  continue;
                }
                let targetPoint = this.PINS[nextPos];
                this.draw(targetPoint, nextPos);
                this.pixels[nextPos] = true;
              }
            }
            current = queue.shift();
          }
        } else {
          // 이미 칠해진 영역 칠할 경우
          while (0 < queue.length + 1) //queue.length
          {
            if (current == null) {
              // console.log("break");
              break;
            }
            // console.log(`current Pin: ${current}`);
            // 현재 핀 색칠
            this.draw(this.PINS[current], current);
            this.pixels[current] = true;

            // 현재 핀 기준 좌,하,우,상 순으로 핀을 순회함.
            for (let i = 0; i < 4; i++) {
              let nextPos = current + dir[i];

              let a = (current % this.VOXEL_COL_NUM) + dir[i];
              if ((i === 0 || i === 2) && (a >= this.VOXEL_COL_NUM || a < 0)) {
                continue;
              }

              if (nextPos >= 0 && nextPos <= 2399) {
                if (this.pixels[nextPos]) {
                  // console.log("Wall ", nextPos);
                  continue;
                } else {
                  queue.push(nextPos);
                  // console.log("finish");
                }
                let targetPoint = this.PINS[nextPos];
                this.draw(targetPoint, nextPos);
                this.pixels[nextPos] = true;
              }
            }
            current = queue.shift();
          }
        }
        canvas.requestRenderAll();
      }
    }
    ,
    /**
     * Rect or Lasso Selector 선택 시 선택 영역에 포함되는 핀을 탐색.
     * @param selectArea
     */
    findSelectedPins(selectArea) {
      this.selectedPins = [];
      if (selectArea === 0) //All
      {
        for (let i = 0; i < this.pixels.length; i++) {
          if (this.pixels[i]) {
            this.selectedPins.push(i);
          }
        }
      } else if (selectArea === 1) //Rect
      {
        // SelectArea 영역에 포함된 핀 등록
        const canvasTopIndex = Math.round(this.selectArea.top / this.scale);
        const canvasLeftIndex = Math.round(this.selectArea.left / this.scale);
        const canvasWidthCount = Math.round(this.selectArea.width / this.scale);
        const canvasHeightCount = Math.round(this.selectArea.height / this.scale);
        let resIndex = 0;

        for (let r = canvasTopIndex; r < canvasTopIndex + canvasHeightCount; r++) {
          for (let c = canvasLeftIndex; c < canvasLeftIndex + canvasWidthCount; c++) {
            resIndex = r * this.VOXEL_COL_NUM + c;
            if (resIndex < 0 || resIndex >= this.VOXEL_COL_NUM * this.VOXEL_ROW_NUM) continue;
            if (this.pixels[resIndex]) {
              this.PINS[resIndex].clone((cloned) => {
                this.selectedPins.push(cloned);
              });
            }
          }
        }
      } else if (selectArea === 2) //Lasso
      {
        // 초기 셀레터 위치에서 현재 셀레터 위치 만큼의 변화량 계산
        let diff = (Math.round(this.selectArea.top / this.scale) * this.VOXEL_COL_NUM + Math.round(this.selectArea.left / this.scale)) - (Math.round(this.selectAreaStartPos.top / this.scale) * this.VOXEL_COL_NUM + Math.round(this.selectAreaStartPos.left / this.scale));
        let resIndex = 0;

        for (const lassoPixel of this.cachedLassoPixels) {
          resIndex = lassoPixel + diff;
          if (resIndex < 0 || resIndex >= this.VOXEL_COL_NUM * this.VOXEL_ROW_NUM) continue;
          if (this.pixels[resIndex]) {
            this.selectedPins.push(this.PINS[resIndex]);
          }
        }
      }
      // console.log(`findSelectedPins : this.selectedPins[${this.selectedPins}]`);
    }
    ,
    renderLasso(figurePattern, linePixels) {
      if (canvas.contains(this.lassoGroup)) {
        canvas.remove(this.lassoGroup);
      }
      this.lassoGroup = new fabric.Group([], {
        hasControls: false,
        hasBorders: true
      });
      // 중복 핀 번호 제거
      const figurePatternSet = new Set(figurePattern);
      figurePattern = Array.from(figurePatternSet);

      const linePixelsSet = new Set(linePixels);
      linePixels = Array.from(linePixelsSet);

      for (const pinNumber of figurePattern) {
        if (isNaN(pinNumber) || 0 > pinNumber || pinNumber >= this.VOXEL_ROW_NUM * this.VOXEL_COL_NUM) continue;
        let left = pinNumber % this.VOXEL_COL_NUM;
        let top = Math.floor((pinNumber - left) / this.VOXEL_COL_NUM);

        const rect = new fabric.Rect({
          left: Math.round(left * this.scale),
          top: Math.round(top * this.scale),
          fill: 'rgba(0,255,244,0.61)',
          width: this.scale,
          height: this.scale,
          objectCaching: false,
          selectable: false,
          hasControls: false
        });
        this.lassoGroup.add(rect);
      }

      for (const linePixel of linePixels) {
        if (isNaN(linePixel) || 0 > linePixel || linePixel >= this.VOXEL_ROW_NUM * this.VOXEL_COL_NUM) continue;
        let left = linePixel % this.VOXEL_COL_NUM;
        let top = Math.floor((linePixel - left) / this.VOXEL_COL_NUM);

        const rect = new fabric.Rect({
          left: Math.round(left * this.scale),
          top: Math.round(top * this.scale),
          fill: 'rgba(0,255,244,0.61)',
          width: this.scale,
          height: this.scale,
          objectCaching: false,
          selectable: false,
          hasControls: false,
          originX: 'left',
          originY: 'top'
        });
        this.lassoGroup.add(rect);
      }
      this.lassoGroup.addWithUpdate();
      canvas.add(this.lassoGroup);
      canvas.requestRenderAll();
    }
    ,
    addPixels_(col, row) {
      col = this.minMax(col, 0, canvas.width - 1);
      row = this.minMax(row, 0, canvas.height - 1);
      let pos = (row * this.VOXEL_COL_NUM) + col;
      // console.log(row, col, pos);
      this.lassoPixels.push(pos);
      this.previousPos.cx = col;
      this.previousPos.cy = row;
    }
    ,
    // drawSelection() {
    //   let pixels = this.lassoPixels;
    //   for (let i = 0; i < pixels.size; i++) {
    //     let pixel = pixels[i];
    //     console.log(pixel);
    //   }
    // },
    minMax(val, min, max) {
      return Math.max(Math.min(val, max), min);
    }
    ,
    isInSelection(pinIndex, boundaries) {
      let existUpperBoundary = false;
      let existLowerBoundary = false;

      // 위쪽 경계픽셀 체크
      for (let i = pinIndex; i > 0; i -= 60) {
        if (boundaries.indexOf(i) >= 0) {
          existUpperBoundary = true;
          break;
        }
      }

      // 아래쪽 경계픽셀 체크
      for (let i = pinIndex; i < 2400; i += 60) {
        if (boundaries.indexOf(i) >= 0) {
          existLowerBoundary = true;
          break;
        }
      }
      return existUpperBoundary && existLowerBoundary;
    }
    ,
    drawBraillePixel(start) {
      canvasPreview.clear();
      this.textPins = [];
      // console.log(`Braille Hex String : ${this.BrailleContent} with Start[${start}]`);
      const cellIndex = [0, 60, 120, 1, 61, 121];
      if (this.braillePin === 8) {
        cellIndex.push(180, 181);
      }
      const brailleSpacing = 3;
      const charHexStrings = this.BrailleContent.split(' ');
      const lineDistance = 240; // 180: 줄간 여백 없이 , 240: 줄간 1개의 행만큼 여백 적용
      let cursor = {
        x: 0,
        y: 0,
      };
      let isEscape = false;
      // FIXME:
      // let prevCol = start % this.VOXEL_COL_NUM;

      // console.log(charHexStrings);
      for (let i = 0; i < charHexStrings.length; i++) {
        if (charHexStrings[i] === '\n') {
          // prevCol = start % this.VOXEL_COL_NUM;
          cursor.x = 0;
          cursor.y += 1;
          continue;
        }
        // 한 글자에 대한 핀 제어
        for (let j = 0; j < cellIndex.length; j++) {
          const dec = parseInt(charHexStrings[i], 16);
          if ((dec & Math.pow(2, j)) > 0) {
            // on 되어야 하는 j 인덱스 번째 핀
            const _index = (start + (cursor.x * brailleSpacing) + (cursor.y * lineDistance)) + cellIndex[j];
            if (_index < 0 || _index > this.VOXEL_COL_NUM * this.VOXEL_ROW_NUM) continue;

            // if (prevCol > _index % this.VOXEL_COL_NUM) {
            // isEscape = true;
            // break;
            // }

            // prevCol = _index % this.VOXEL_COL_NUM;

            this.textPins.push(_index);
            const pinLeft = (_index % this.VOXEL_COL_NUM) * this.scale + lPad;
            const pinTop = Math.floor(_index / this.VOXEL_COL_NUM) * this.scale + lPad;

            const pin = new fabric.Circle({
              radius: (this.scale * this.size) / 2 * 0.75,
              fill: "rgba(0,0,0,0.32)",
              stroke: "",
              strokeWidth: 0,
              left: pinLeft,
              top: pinTop,
              objectCaching: false,
              selectable: false,
              hasControls: false,
              hasBorders: false,
              hasRotatingPoint: false,
              hoverCursor: "pointer"
            });
            canvasPreview.add(pin);
          }
        }

        if (isEscape) {
          isEscape = false
          continue;
        }

        cursor.x += 1;
      }
      canvasPreview.renderAll();
    },
    confirmBraille() {
      for (const pin of this.textPins) {
        // 기존에 존재하는 핀인지
        if (!this.pixels[pin]) {
          this.PINS[pin].fill = penColor;
          this.pixels[pin] = true;
          this.draggingStack.push(pin);
        }
      }

      const action = {
        type: 'draw',
        originalDataSet: {
          colors: [...this.originalDataSet.colors],
          state: [...this.originalDataSet.state]
        },
        changedDataSet: {
          colors: this.getPixelColors(),
          state: [...this.pixels],
        }
      }
      this.isCanvasDirty = true;
      this.stateHistory.unshift(action);
      this.draggingStack = [];

      this.clearBraille();
      this.savePage();
      canvas.renderAll();
    }
    ,
    clearBraille() {
      canvas.remove(this.currentTextItem);
      this.currentTextItem = null;
      canvasPreview.clear();
      this.exitEditorMode();
    }
    ,
    addTextItem(x, y) {
      if (this.currentTextItem == null) {
        const fontSize = Math.abs(this.scale) * 3;
        const letterSpacing = Math.abs(this.scale);
        var parsedSpacing = fabric.util.parseUnit(letterSpacing, fontSize) / fontSize * 2000;

        const textItem = new TextBox(this.text, {
          left: x,
          top: y,
          width: 6 * (fontSize + letterSpacing), // 텍스트 박스의 가로 길이
          fontFamily: 'Helvetica',
          fontSize: fontSize,
          fill: '#333',
          lineHeight: 1,
          charSpacing: parsedSpacing,
          lockScalingX: false,
          lockRotation: true,
          lockScalingY: true,
          splitByGrapheme: false, // 자동 줄바꿈 적용(공백 개념이 없는 문자열을 분할)
          // maxLines: 10,
          borderColor: 'rgb(0,255,26)', // 텍스트박스 선택 시 색상
          borderScaleFactor: 2, // 텍스트 박스 선택 시 테두리 두께
          showTextBoxBorder: true,
          editingBorderColor: 'rgb(255,0,0)',
          cornerColor: 'rgba(0,0,0,1)',
          cornerStrokeColor: 'rgba(0,0,0,1)',
          cornerSize: 10,
          cornerStyle: 'rect', // 'rect' or 'circle'
          selectable: true,
          hasControls: true, // 오브젝트 모서리 컨트롤러 표시 여부
        });

        // TextBox 모서리 컨트롤러 표시
        for (const controlKey of ['tl', 'tr', 'br', 'bl', 'mt', 'mb', 'mtr']) {
          textItem.setControlVisible(controlKey, false);
        }

        this.currentTextItem = textItem;
        canvas.add(this.currentTextItem);
        canvas.setActiveObject(this.currentTextItem);
        this.currentTextItem.enterEditing();
      }
    }
    ,
    // 되돌리기
    undo() {
      let lastDraw = this.stateHistory.shift();
      if (!lastDraw) return;
      this.undoneChanges.unshift(lastDraw);
      this.pixels = [...lastDraw.originalDataSet.state];
      this.setPixelColors([...lastDraw.originalDataSet.colors]);
      this.savePage();
      canvas.renderAll();
      return lastDraw;
    }
    ,
    // 다시작업
    redo() {
      let lastUndoneDraw = this.undoneChanges.shift();
      if (!lastUndoneDraw) return;
      this.stateHistory.unshift(lastUndoneDraw);
      this.pixels = [...lastUndoneDraw.changedDataSet.state];
      this.setPixelColors([...lastUndoneDraw.changedDataSet.colors]);
      this.savePage();
      canvas.renderAll();
      return lastUndoneDraw;
    }
    ,
    figureMove(mousePositionIndex) {
      const CANVAS_SIZE = this.VOXEL_COL_NUM * this.VOXEL_ROW_NUM;
      //console.log(`figureMove : startIndex[${this.mouseStartPositionIndex}], mousePositionIndex[${mousePositionIndex}]`);
      const diff = (mousePositionIndex - this.mouseStartPositionIndex + CANVAS_SIZE) % CANVAS_SIZE;
      //console.log(`figureMove : diff[${diff}]`);
      //Draw Moved All Pins
      const pins = this.getPinObjects();
      pins.forEach((pin) => pin.fill = eraseColor);

      this.selectedPins.forEach((selectedPin) => {
        const newPinIndex = (selectedPin + diff + CANVAS_SIZE) % CANVAS_SIZE;
        pins[newPinIndex].fill = penColor;
        // pins[newPinIndex].fill = this.stateHistory[0].changedDataSet.colors[selectedPin];
      });
    }
    ,
    getPixelColors() {
      let colors = [];
      for (const pin of this.PINS) {
        colors.push(pin.fill);
      }
      return colors;
    }
    ,
    setPixelColors(colorSet) {
      for (let i = 0; i < this.PINS.length; i++) {
        this.PINS[i].fill = colorSet[i];
      }
    }
    ,
    copyOriginPixels(_target) {
      let copied = Array.from(_target);
      return new Promise((resolve) => {
        resolve(copied);
      });
    }
    ,
    // 닷 패드 출력
    shareScreen() {
      this.printDTM();

      if (socketClient.teacher) {
        dotpadsdk.Load_mapFile(this.pixels);
        dotpadsdk.Make_DTM_Data();

        // send dtm json
        let jsonString =
          '{"page": "' + this.curPage + '", "title": "", "device": "dotpad320",' +
          ' "graphic": {"name": "", "data":"' + dotpadsdk.writeString + '"},' +
          ' "text": { "name": "", "data": "' + this.BrailleText + '", "plain": "' + this.altText + '"}}';
        socketClient.sendDTM(jsonString);
      }
    }
    ,
    async printDTM() {
      const fetchTextToBraille = async (text) => {
        const hexData = await this.fetchTextToBraille("content", text);
        return hexData.map(hexString => {
          // 일본어일 경우 BRAILLE_RESULT 데이터 필터
          if (this.brailleLang === "japanese") {
            hexString = hexString.split("brailleCode:")[1];
          }

          const strippedHex = hexString.replace(/\s/g, "");

          if (strippedHex.length === 0 || strippedHex.length % this.VOXEL_ROW_NUM !== 0) {
            const paddingLength = (this.VOXEL_ROW_NUM - strippedHex.length % this.VOXEL_ROW_NUM) / 2;
            return strippedHex + ("00".repeat(paddingLength));
          } else {
            return strippedHex;
          }
        })?.join("");
      }

      const dotpadList = DotpadList.getList();

      if (dotpadList.length === 0) {
        const deviceDialog = Modal.getOrCreateInstance(document.getElementById("connectDotPadDialog"));
        deviceDialog.show();
        return;
      }

      // 출력 전 점역실행
      const currentPage = this.curPage;
      const totalPages = this.pages.length;
      let numberOfPages = `${currentPage}/${totalPages}`;
      let hexData = await fetchTextToBraille.call(this, `${numberOfPages} ${this.altText}`);
      this.BrailleText = hexData; // 페이지 번호 + (빈칸) + 점자

      for (let i = 0; i < dotpadList.length; i++) {
        const dotPad = dotpadList[i];

        // Description 처음부터 출력
        this.BrailleTextIndex[i] = 0;

        if (this.BrailleText.length > 0) {
          dotPad.dotpadSDK.sendText(this.BrailleText, this.BrailleTextIndex[i]);
        } else {
          dotPad.dotpadSDK.sendText("0".repeat(40), this.BrailleTextIndex[i]);
        }

        dotPad.dotpadSDK.Load_mapFile(this.pixels);
        dotPad.dotpadSDK.Make_DTM_Data();
        if (dotPad.connected) {
          dotPad.dotpadSDK.sendPixelPattern();
        } else {
          // console.log('Dotpad is not Connected');
        }
      }
    },
    onMessageReceived(graphicHexString, textHexString, textPlainString) {
      let byteArr = this.hexToBytes(graphicHexString);
      for (let i = 0; i < byteArr.length; i++) {
        let start_index = parseInt(i / 30) * 60 * 4 + (i % 30) * 2;
        this.pixels[start_index] = (byteArr[i] & (0x01 << 0)) ? true : false;
        this.pixels[start_index + 60] = (byteArr[i] & (0x01 << 1)) ? true : false;
        this.pixels[start_index + 120] = (byteArr[i] & (0x01 << 2)) ? true : false;
        this.pixels[start_index + 180] = (byteArr[i] & (0x01 << 3)) ? true : false;
        this.pixels[start_index + 1] = (byteArr[i] & (0x01 << 4)) ? true : false;
        this.pixels[start_index + 61] = (byteArr[i] & (0x01 << 5)) ? true : false;
        this.pixels[start_index + 121] = (byteArr[i] & (0x01 << 6)) ? true : false;
        this.pixels[start_index + 181] = (byteArr[i] & (0x01 << 7)) ? true : false;
        //console.log('start_index : ' + start_index);
      }
      this.BrailleText = textHexString;
      this.altText = textPlainString;
      this.drawPixels();
      this.printDTM();
      if (this.BrailleText.length > 1)
        dotpadsdk.sendText(this.BrailleText, this.BrailleTextIndex);
    }
    ,
    // Hex String을 Bytes Array로 변환합니다.
    hexToBytes(hex) {
      for (var bytes = [], c = 0; c < hex.length; c += 2) {
        bytes.push(parseInt(hex.substr(c, 2), 16));
      }
      return bytes;
    }
    ,
    // TextBox 보여주기
    showTextBox() {
      if (this.currentTextItem) {
        if (this.selectedTool.name == 'Text') {
          // TextBox.selectable = true;
          // TextBox.visible = true;
          this.currentTextItem.visible = true;
        } else {
          // TextBox.selectable = false;
          // TextBox.visible = false;
          this.currentTextItem.visible = false;
          canvas.discardActiveObject();
        }
        canvas.renderAll();
      }
    }
    ,
    // 캔버스 지우기
    eraseAll() {
      this.originalDataSet = {
        state: [...this.pixels],
        colors: this.getPixelColors(),
      };

      for (let i = 0; i < this.pixels.length; i++) {
        if (this.pixels[i]) {
          this.draggingStack.push(i);
        }
      }
      this.clearCanvas();
      this.endDrawing(this.draggingStack);
    }
    ,
    clearCanvas() {
      //this.resetSelector();
      let objects = canvas.getObjects();
      for (let i = 0; i < 2400; i++) {
        objects[i].fill = eraseColor;
        this.pixels[i] = false;
      }
      // 2400개의 베이스 핀을 제외한 나머지 오브젝트 제거
      for (let i = objects.length - 1; i > 2399; i--) {
        canvas.remove(objects[i]);
      }
      canvas.renderAll();
    }
    ,
    resetSelector() {
      canvas.remove(this.selectArea);
      canvas.remove(this.lassoGroup);
      canvas.remove(this.rectGroup);

      this.selectArea = null;
      this.selector.state = 'default';
      this.selector.originX = 0;
      this.selector.originY = 0;
      this.lassoPixels = [];
      this.lassoGroup = new fabric.Group([], {
        hasControls: false,
        hasBorders: true
      });
      this.rectGroup = null;
    }
    ,
    // 로케일 저장
    setLocale(locale) {
      this.locale = locale;
    }
    ,
    exportDTMS() {
      this.$refs.navigation.download();
    },
    isDotAdmin() {
      if (this.web_mode === "CLASSROOM") {
        return false;
      } else if (this.web_mode === "CANVAS") {
        return global.isDotAdmin();
      }
    },
    /** 자식 컴포넌트에서 데이터 가져오는 용도
     * @since 2023. 03. 09.
     */
    getCanvas() {
      return canvas;
    },
    twentyCellsLeftPanning(dotPad) {
      const dotPadList = DotpadList.getList();
      const padIndex = dotPadList.indexOf(dotPad);

      if (this.BrailleTextIndex[padIndex] > 0) {
        this.BrailleTextIndex[padIndex] = this.BrailleTextIndex[padIndex] - 1;
      }
      dotPad.dotpadSDK.sendText(this.BrailleText, this.BrailleTextIndex[padIndex]);
    },
    twentyCellsRightPanning(dotPad) {
      const dotPadList = DotpadList.getList();
      const padIndex = dotPadList.indexOf(dotPad);
      const indexMax = Math.ceil(this.BrailleText.length / 40);

      if (this.BrailleTextIndex[padIndex] < indexMax - 1) {
        this.BrailleTextIndex[padIndex] = this.BrailleTextIndex[padIndex] + 1;
      }
      dotPad.dotpadSDK.sendText(this.BrailleText, this.BrailleTextIndex[padIndex]);
    },
    previousPage() {
      const index = this.curPage - 2;
      if (index >= 0) {
        this.loadPage(index);
        this.printDTM();
      }
    },
    nextPage() {
      const index = this.curPage;
      if (index < this.pages.length) {
        this.loadPage(index);
        this.printDTM();
      }
    },
    async refresh(dotPad) {
      const fetchTextToBraille = async (text) => {
        const hexData = await this.fetchTextToBraille("content", text);
        return hexData.map(hexString => {
          // 일본어일 경우 BRAILLE_RESULT 데이터 필터
          if (this.brailleLang === "japanese") {
            hexString = hexString.split("brailleCode:")[1];
          }

          const strippedHex = hexString.replace(/\s/g, "");

          if (strippedHex.length === 0 || strippedHex.length % this.VOXEL_ROW_NUM !== 0) {
            const paddingLength = (this.VOXEL_ROW_NUM - strippedHex.length % this.VOXEL_ROW_NUM) / 2;
            return strippedHex + ("00".repeat(paddingLength));
          } else {
            return strippedHex;
          }
        })?.join("");
      }

      // 출력 전 점역실행
      const currentPage = this.curPage;
      const totalPages = this.pages.length;
      let numberOfPages = `${currentPage}/${totalPages}`;
      let hexData = await fetchTextToBraille.call(this, `${numberOfPages} ${this.altText}`);
      this.BrailleText = hexData; // 페이지 번호 + (빈칸) + 점자

      if (this.pixels) {
        dotPad.dotpadSDK.Load_mapFile(this.pixels);
        dotPad.dotpadSDK.Make_DTM_Data();
      }

      if (dotPad.connected) {
        const dotPadList = DotpadList.getList();
        const padIndex = dotPadList.indexOf(dotPad);

        dotPad.dotpadSDK.sendPixelPattern();

        // Description 처음부터 출력
        this.BrailleTextIndex[padIndex] = 0;
        dotPad.dotpadSDK.sendText(this.BrailleText, this.BrailleTextIndex[padIndex]);
      }
    },
    // 펜 툴 선택
    selectPenTool() {
      this.$refs.toolbar.onClickPenBtn();
    },
    // 전체 지우개 툴 선택
    selectEraserAllTool(dotPad) {
      this.$refs.toolbar.onClickEraserAllBtn();
      this.dotPad300CellsDown(dotPad);
    },
    // 페이지 추가
    addPage() {
      // 페이지 추가
      this.addNewPage();
      this.printDTM();
    },
    // (현재)페이지 삭제
    deletePage() {
      const index = this.curPage - 1;
      if (this.pages.length > 1) {
        this.loadPageAfterDelete(index);
        this.printDTM();
      }
    },
    initializeDotPad(dotPad) {
      const dotPadList = DotpadList.getList();
      const padIndex = dotPadList.indexOf(dotPad);

      if (dotPad.connected) {
        if (this.pixels) {
          dotPad.dotpadSDK.Load_mapFile(this.pixels);
          dotPad.dotpadSDK.Make_DTM_Data();
        }

        this.BrailleTextIndex[padIndex] = 0;
        dotPad.KeyInputCallbackFtn = this.$refs["dot-pad320-key"]?.keyEventCallback;
      }
    },
    initializeDotPads() {
      const dotPadList = DotpadList.getList();

      for (const dotPad of dotPadList) {
        this.initializeDotPad(dotPad);
      }
    },
    dotPad300CellsDown(dotPad) {
      if (dotPad.connected) {
        dotPad.dotpadSDK.sendMessage("00".repeat(300));
      }
    },
  }
}
</script>

<style scoped>
.content-area {
  position: relative;
  /*height: calc(100vh - 70px);*/
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: 126px;
  transition: margin 200ms ease-out; */
  padding: 20px 2%;
  background-color: white;
}

.content-area > div.row {
  top: 66px;
}

main {
  display: contents;
  width: 100%;
  padding: 0;
  text-align: center;
}

main .left-column {
  flex-shrink: 0;
  width: 100%;
  padding-top: 3px;
  padding-right: 3px;
  padding-left: 3px;
  padding-bottom: 10px;
  vertical-align: top;
  background-color: #F7F7F7;
}

main .main-column {
  position: relative;
  height: 100%;
}

.col-non-text {
  width: 100%;
}

.col-text {
  min-width: calc(100% - 252px);
}

main .right-column {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  min-width: 252px;
  /*width: 15vw;*/ /*18rem;*/
  height: 100%;
  /* padding-right: 10px; */
  /* padding-bottom: 40px; */
  padding-left: 10px;
  vertical-align: top;
  background-color: #F7F7F7;
  text-align: left;
}

main .right-column > div {
  flex-shrink: 0;
  min-height: 30px;
  padding: 15px 0 20px 34px;
  background-color: #FFFFFF;
}

main .right-column .braille-html {
  font-size: 2rem;
  line-height: 2rem;
  overflow-y: scroll;
  height: 40vh;
  margin-top: 0.8rem;
  background-color: #f7f7f7;
}

main .right-column .braille-translation {
  width: 100%;
  margin-top: 1vh;
  border-radius: 8px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 5px 0px;
}

hr {
  margin: 0;
}

.description-title {
  border-radius: 15px 15px 0 0;
}

.description-title > span {
  font-weight: 700;
  font-size: 20px;
  color: #44403F;
}

.description-textarea {
  height: 100%;
  padding: 0 34px;
  border: none;
  border-radius: 0 0 15px 15px;
  color: #AAABAB;
  font-family: sans-serif;
  font-size: 1rem;
  resize: none;
}

.description-textarea::placeholder {
  color: #AAABAB;
}

.whiteboard {
  height: 70vh;
  /* background-color: #4C4C4C; */
}

.whiteboard :not(:nth-child(3)) {
  position: absolute !important;
}

#board {
  color: #000;
  /*border: 4px solid black;*/
  border-right: 1px solid #d9d9d9;
  border-bottom : 1px solid #d9d9d9;
  background-color: transparent;;
}

#grid {
  color: #000;
  background-color: white;
}


.btn-print {
  /* color: blue;
  border: 2px solid blue; */
  background: #FFFFFF;
  border-radius: 15px;
  margin-top: 20px;
}

.btn-print:hover {
  opacity: 0.8;
}

.btn-print > svg {
  position: relative;
  left: -25px;
}

.btn-print > span {
  position: relative;
  left: -20px;
}

main {
  font-size: 0;
  /*position: absolute;*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 120px;
  display: flex;
  width: calc(100% - 145px);
  padding: 0;
  text-align: center;
}

.main-right-column {
  width: calc(100% - 160px);
  display: flex;
}

main .left-column {
  flex-shrink: 0;
  width: 160px;
  height: 100%;
  padding-top: 3px;
  padding-right: 3px;
  padding-left: 3px;
  vertical-align: top;
  background-color: #F7F7F7;
}

.col-lg-10 {
  flex: 0 0 auto;
  width: 83.33333333% !important;
}

.col-lg-2 {
  flex: 0 0 auto;
  width: 16.66666667% !important;
}


/**
  Tooltip Styles
 */
.tooltips {
  display: block;
  float: left;
  width: 100%;
  /* height: 100%; */
}

.tooltips .tooltiptext {
  position: absolute;
  z-index: 9999;
  visibility: hidden;
  width: max-content; /*120px;*/
  padding: 5px 10px;
  text-align: start;
  color: #fff;
  border-radius: 0px;
  background-color: black;
  left: 50%;
}

.tooltips:hover .tooltiptext {
  visibility: visible;
}

.tooltips .tooltip-right {
  top: 20px;
}

.tooltips .tooltip-right > .hint {
  font-size: small;
  font-weight: lighter;
  display: block;
  margin-top: 4px;
  color: #cbcbcb;
}

.dotcanvas-content {
  padding: 32px;
  background-color: #F7F7F7;
  margin: 0 0.1rem 0 0.1rem;
  border-radius: 30px;
}

@media (min-width: 992px) {
  .tooltips .tooltiptext {
    left: 85%;
  }
}

@media (min-width: 1650px) {
  .tooltips .tooltiptext {
    left: 90%;
  }
}
</style>
