export var Tools = [
    {name: "Pen", active: false, items: []},
    {name: "VerticalMirrorPen", active: false, items: []},
    {name: "Move", active: false, items: []},
    {name: "PaintBucket", active: false, items: []},
    {name: "Eraser", active: false, items: []},
    {name: "EraserAll", active: false, items: []},
    {name: "Line", active: false, items: []},
    {name: "Square", active: false, items: []},
    {name: "Circle", active: false, items: []},
    {name: "Triangle", active: false, items: []},
    {name: "Text", active: false, items: []},
    {name: "Template", active: false, items: []},
    {name: "AltText", active: false, items: []},
    {name: "PDFUpload", active: false, items: []},
    {name: "ImageEdge", active: false, items: []},
    {name: "RectSelector", active: false, items: []},
    {name: "LassoSelector", active: false, items: []},
    {name: "TactileLetters", active: false, items: []}
]
