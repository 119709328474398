import {Draw} from "../Draw";

class Shapes extends Draw {
    constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
        super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
    }

    figureDraw() {

    }

    /**
     * 너비와 높이가 같은 정사각형 비율
     */
    squareAspectRatio() {
        if (!this.keepRatio) {
            return;
        }

        const width = this.x1 - this.x0;
        const height = this.y1 - this.y0;

        if (width === height) {
            return;
        }

        if (width > height) {
            if (this.startPoint.cx < this.endPoint.cx) {
                this.x1 = this.x0 + height;
            } else {
                this.x0 = this.x1 - height;
            }
        } else {
            if (this.startPoint.cy < this.endPoint.cy) {
                this.y1 = this.y0 + width;
            } else {
                this.y0 = this.y1 - width;
            }
        }
    }
}

export {
    Shapes
};
