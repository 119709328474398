import {Draw} from "../Draw";

class Line extends Draw {
  #resultArr
  #isDashing

  constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
    super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
    this.#resultArr = [];
    this.#isDashing = true;
  }

  figureDraw() {

  }

  drawLine(dashLength, spaceLength) {
    let x0 = this.startPoint.cx;
    let y0 = this.startPoint.cy;
    const x1 = this.normalize(this.endPoint.cx, 0);
    const y1 = this.normalize(this.endPoint.cy, 0);
    const deltaX = Math.abs(x1 - x0);
    const deltaY = Math.abs(y1 - y0);
    const stepX = x0 < x1 ? 1 : -1;
    const stepY = y0 < y1 ? 1 : -1;
    let err = deltaX - deltaY;
    let dashCount = 0;
    let spaceCount = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this.isWithinBounds(x0)) {
        this.#pushPin(x0, y0);
      }

      if (x0 === x1 && y0 === y1) {
        break;
      }

      const e2 = 2 * err;

      if (e2 > -deltaY) {
        err -= deltaY;
        x0 += stepX;
      }

      if (e2 < deltaX) {
        err += deltaX;
        y0 += stepY;
      }

      dashCount++;
      if (dashCount < dashLength) {
        this.#isDashing = true;
      } else {
        if (spaceCount < spaceLength) {
          this.#isDashing = false;
          spaceCount++;
        } else {
          this.#isDashing = true;
          spaceCount = 0;
          dashCount = 0;
        }
      }
    }

    return this.#resultArr.filter(pin => pin !== undefined);
  }

  #pushPin(x, y) {
    const pin = this.index(x, y, this.VOXEL_COL_NUM);
    this.#resultArr.push(this.#isDashing ? pin : undefined);
  }
}

export {
  Line
};
