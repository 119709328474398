<template>
  <div ref="rootNode" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-5 bg-white">
        <div class="contents">
          <h1>{{ $t('참가자') }}</h1>
          <div class="row mx-auto">

            <div class="col px-0">
              <div class="searchbar">
                <input id="search" class="form-control form-control-lg" placeholder="Search" type="text"
                       @change="onUserFiltering">
                <a class="btn btn-search" href="#" role="button">
                  <svg class="bi bi-search" fill="currentColor" height="16" viewBox="0 0 16 16"
                       width="16" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                  </svg>
                </a>
              </div>
            </div>

            <div class="viewers col-12">
              <div class="list py-2">
                <li v-for="viewer in filteredList" :key="viewer"
                    class="viewer">
                  <span>{{ (viewer.USER_NAME) ? viewer.USER_NAME : '-' }}({{
                      (viewer.EMAIL) ? viewer.EMAIL : '-'
                    }})</span>
                </li>
              </div>
            </div>

          </div>

          <div class="row mx-auto">
            <div class="col">
              <button aria-label="Close" class="btn-cancel" data-bs-dismiss="modal">
                {{ $t('취소') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ViewerDialog",
  props: {
    participants: Object
  },
  mounted() {
  },
  watch: {
    participants(newVal) {
      this.filteredList = newVal;
    }
  },
  data() {
    return {
      filteredList: []
    }
  },
  methods: {
    onUserFiltering(e) {
      let tmp = [];

      for (const participant of this.participants) {
        if (participant.EMAIL && participant.EMAIL.toLowerCase().includes(e.target.value)) {
          tmp.push(participant);
        }

        if (participant.USER_NAME && participant.USER_NAME.toLowerCase().includes(e.target.value)) {
          tmp.push(participant);
        }

        this.filteredList = [...new Set(tmp)];
      }

    },
    isEmail(plain) {
      if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(plain)) {
        return (true)
      }
      return (false)
    }
  },
}
</script>

<style scoped>
.modal-body {
  border-radius: 20px;
}

.btn-cancel {
  font-size: 1.25rem;
  width: 100%;
  height: 5vh;
  margin-top: 20px;
  border: none;
  border-radius: 8px;
  background-color: white;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.12);
}

.btn-cancel:hover {
  color: white;
  background-color: #F93154;
}

.searchbar {
  position: relative;
}

.searchbar .btn-search {
  position: absolute;
  top: 2px;
  right: 2px;
}

#search {
  font-size: 1rem;
  height: 1.5vh;
  margin: 4px 0;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

#search::-webkit-input-placeholder {
  opacity: 1;
  color: #afafaf;
}

#search::-ms-input-placeholder {
  opacity: 1;
  color: #afafaf;
}

.viewers {
  overflow-x: hidden;
  overflow-y: auto;
  height: 40vh;
  margin-top: 2vh;
  border: 1px solid #ced4da;
}

.viewers .viewer {
  height: 4vh;
  list-style: none;
}

.viewers .viewer > span {
  display: block;
}

.viewers .viewer:after {
  display: block;
  width: 100%;
  content: "";
  border-top: 1px solid rgba(147, 147, 147, 0.72);
}
</style>
