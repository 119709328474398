class Draw {
    startPoint;
    endPoint;
    x0;
    x1;
    y0;
    y1;
    VOXEL_ROW_NUM;
    VOXEL_COL_NUM;
    keepRatio;

    constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio = false) {
        this.startPoint = startPoint;
        this.endPoint = endPoint;
        this.VOXEL_ROW_NUM = VOXEL_ROW_NUM;
        this.VOXEL_COL_NUM = VOXEL_COL_NUM;
        this.keepRatio = keepRatio;
        this.x0 = Math.min(startPoint.cx, endPoint.cx);
        this.x1 = Math.max(startPoint.cx, endPoint.cx);
        this.y0 = Math.min(startPoint.cy, endPoint.cy);
        this.y1 = Math.max(startPoint.cy, endPoint.cy);
    }

    figureDraw() {

    }

    index(a, b, VOXEL_COL_NUM) {
        return b * VOXEL_COL_NUM + a;
    }

    normalize(value, def) {
        if (typeof value === 'undefined' || value === null) {
            return def;
        } else {
            return value;
        }
    }

    isWithinBounds(x) {
        return x >= 0 && x < this.VOXEL_COL_NUM;
    }
}

export {
    Draw
}
