import {DotpadSDK} from "./DotpadSDK.js";

const dotpadsdk = new DotpadSDK();

class DTMS {
  constructor({
                title = "Untitled",
                text = "",
                lang = "",
                lang_option = "",
                file_description = "",
                device = "dotpad320",
                items = []
              }) {
    this.title = title;
    this.text = text;
    this.lang = lang;
    this.lang_option = lang_option;
    this.file_description = file_description;
    this.device = device;
    this.items = items;
  }

  makeJson() {
    return {
      title: this.title,
      lang: this.lang,
      lang_option: this.lang_option,
      device: this.device,
      items: this.items
    };
  }
}


class Page {
  constructor(title, pno, gobj, tobj, stateHistory, undoneChanges) {
      this.title = title;
      this.page = pno;
      this.graphic = gobj; // name, data:데이터는 2400 bool 배열
      this.text = tobj; // name, data, plain
      this.stateHistory = stateHistory;
      this.undoneChanges = undoneChanges;
  }

  makeJson()
  {
      dotpadsdk.Load_mapFile(this.graphic.data);
      dotpadsdk.Make_DTM_Data();
      let _gdata = dotpadsdk.writeString;

      let json = {
          page: this.page,
          title: this.title,
          graphic: {
              name: this.graphic.name,
              data: _gdata
          },
          text: {
              name: this.text.name,
              data: this.text.data,
              plain: this.text.plain
          }
      };
      return json;
  }
}

export {
  DTMS,
  Page
};
