import {Shapes} from "./Shapes";

class Square extends Shapes {

    constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
        super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
    }

    figureDraw() {
        this.squareAspectRatio();

        const resultArr = [];

        for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
            for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
                if (row === this.y0 || row === this.y1) {
                    if (col >= this.x0 && col <= this.x1) {
                        let index = this.index(col, row, this.VOXEL_COL_NUM);
                        if (resultArr.indexOf(index) === -1) {
                            resultArr.push(index);
                        }
                    }
                }
                if (col === this.x0 || col === this.x1) {
                    if (row >= this.y0 && row <= this.y1) {
                        let index = this.index(col, row, this.VOXEL_COL_NUM);
                        if (resultArr.indexOf(index) === -1) {
                            resultArr.push(index);
                        }
                    }
                }
            }
        }

        return resultArr;
    }
}

export {
    Square
};
