import {Shapes} from "./Shapes";

class Circle extends Shapes {
  #resultSet;

  constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
    super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
    this.#resultSet = new Set();
  }

  figureDraw() {
    // console.log(`Circle Selected : Start[${this.startPoint}], End[${this.endPoint}]`);
    if (Math.abs(this.startPoint.cx - this.endPoint.cx) <= 1 || Math.abs(this.startPoint.cy - this.endPoint.cy) <= 1) {
      return [];
    }

    this.squareAspectRatio();

    const centerX = Math.round((this.x0 + this.x1) / 2); // 원의 중심 X 좌표
    const centerY = Math.round((this.y0 + this.y1) / 2); // 원의 중심 Y 좌표
    const evenX = (this.x0 + this.x1) % 2;
    const evenY = (this.y0 + this.y1) % 2;
    const radiusX = this.x1 - centerX;
    const radiusY = this.y1 - centerY;

    for (let x = this.x0; x <= centerX; x++) {
      const angle = Math.acos((x - centerX) / radiusX);
      const x1 = x - evenX;
      const x2 = 2 * centerX - x;
      const y1 = Math.round(radiusY * Math.sin(angle) + centerY);
      const y2 = 2 * centerY - y1 - evenY;

      this.#addToResultSet(x1, y1, y2, x2);
    }

    for (let y = this.y0; y <= centerY; y++) {
      const angle = Math.asin((y - centerY) / radiusY);
      const x1 = Math.round(radiusX * Math.cos(angle) + centerX);
      const x2 = 2 * centerX - x1 - evenX;
      const y1 = y - evenY;
      const y2 = 2 * centerY - y;

      this.#addToResultSet(x1, y1, y2, x2);
    }

    return Array.from(this.#resultSet);
  }

  #addToResultSet(x1, y1, y2, x2) {
    if (this.isWithinBounds(x1)) {
      this.#resultSet.add(this.index(x1, y1, this.VOXEL_COL_NUM));
      this.#resultSet.add(this.index(x1, y2, this.VOXEL_COL_NUM));
    }

    if (this.isWithinBounds(x2)) {
      this.#resultSet.add(this.index(x2, y1, this.VOXEL_COL_NUM));
      this.#resultSet.add(this.index(x2, y2, this.VOXEL_COL_NUM));
    }
  }
}

export {
  Circle
}
