import {SolidLine} from "./Template/Lines/SolidLine";
import {DashedLine} from "./Template/Lines/DashedLine";
import {DottedLine} from "./Template/Lines/DottedLine";
import {Circle} from "./Template/Shapes/Circle";
import {Square} from "./Template/Shapes/Square";
import {Triangle} from "./Template/Shapes/Triangle";
import {Star} from "./Template/Shapes/Star";

const draw =
    {
        templateFactory: function (selectedFigureType, startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
            let template;

            switch (selectedFigureType) {
                case "Line":
                    template = new SolidLine(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
                    break;
                case "DashedLine":
                    template = new DashedLine(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
                    break;
                case "DottedLine":
                    template = new DottedLine(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
                    break;
                case "Circle":
                    template = new Circle(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
                    break;
                case "Square":
                    template = new Square(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
                    break;
                case "Triangle":
                    template = new Triangle(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
                    break;
                case "Star":
                    template = new Star(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
                    break;
            }
            return template;
        },
        figureDraw: function (startPoint, endPoint, selectedFigureType, VOXEL_ROW_NUM, VOXEL_COL_NUM, penSize, keepRatio = false) {
            const template = this.templateFactory(selectedFigureType, startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);

            return template.figureDraw();
        },
        /**
         * @deprecated
         */
        getLassoPixels: function (x0, x1, y0, y1, VOXEL_ROW_NUM, VOXEL_COL_NUM) {
            let dx;
            let dy;
            let sx;
            let sy;
            let err;
            let e2;
            let resultArr = [];
            let tmpArr = [];

            x1 = this.normalize(x1, 0);
            y1 = this.normalize(y1, 0);

            dx = Math.abs(x1 - x0);
            dy = Math.abs(y1 - y0);

            sx = (x0 < x1) ? 1 : -1;
            sy = (y0 < y1) ? 1 : -1;

            err = dx - dy;
            // eslint-disable-next-line no-constant-condition
            while (true) {
                resultArr.push(this.index(x0, y0, VOXEL_COL_NUM));
                if ((x0 === x1) && (y0 === y1)) {
                    break;
                }
                e2 = 2 * err;
                if (e2 > -dy) {
                    err -= dy;
                    x0 += sx;
                }
                if (e2 < dx) {
                    err += dx;
                    y0 += sy;
                }
            }
            for (const pin of Array.from(resultArr)) {
                if (pin % VOXEL_COL_NUM < x0 || pin % VOXEL_COL_NUM > x1) continue;
                tmpArr.push(pin);
            }
            resultArr = tmpArr;
            return resultArr;
        }
    }
export {
    draw
};
