import {Page} from "./common.js";
import {$axios, $session} from "dot-framework";

const file = 
{
    fileOpen(pages, jsonObj)
    {
        // 기존의 pages를 비움
        pages.splice(0, pages.length); 
        // pages에 데이타 바인딩
        for (const page of jsonObj.items) {
            let _graphicData = page.graphic.data; // 600자 Hex String (1Hex=4bit)
            let byteArr = this.hexToBytes(_graphicData); // 300 Bytes Array
            let tmpArr = [];
            // 300셀의 핀이 8개라서 아래와 같이 세팅
            for (let i = 0; i < byteArr.length; i++) {
                let start_index = parseInt(i / 30) * 60 * 4 + (i % 30) * 2;
                tmpArr[start_index] = (byteArr[i] & (0x01 << 0)) ? true : false; // 1번쨰 핀
                tmpArr[start_index + 60] = (byteArr[i] & (0x01 << 1)) ? true : false; // 2번째 핀
                tmpArr[start_index + 120] = (byteArr[i] & (0x01 << 2)) ? true : false; // 3번째 핀
                tmpArr[start_index + 180] = (byteArr[i] & (0x01 << 3)) ? true : false; // 4번째 핀
                tmpArr[start_index + 1] = (byteArr[i] & (0x01 << 4)) ? true : false; // 5번째 핀
                tmpArr[start_index + 61] = (byteArr[i] & (0x01 << 5)) ? true : false; // 6번째 핀
                tmpArr[start_index + 121] = (byteArr[i] & (0x01 << 6)) ? true : false; // 7번째 핀
                tmpArr[start_index + 181] = (byteArr[i] & (0x01 << 7)) ? true : false; // 8번째 핀
            }
            // page 인스턴스 생성
            let pageObject = new Page("", 1, 
                {name: "", data: tmpArr},
                {name: "", data: "", plain: ""}, [], []);
            // 페이지 배열에 추가
            pages.push(pageObject);
        }
    },
    // 삭제 버튼 클릭
    dtmsDataDelete(web_mode, dtmsFileNo){
        if(web_mode == "CLASSROOM"){
            return this.deleteClassDTMS(dtmsFileNo);
        } else if(web_mode == "CANVAS"){
            return this.deleteCanvasDTMS(dtmsFileNo);
        }
    },
    async deleteClassDTMS(dtmsFileNo) {
        const compNo = $session.getCompNo(); // 업체번호 C220512001
        const userNo = $session.getUserNo(); // 로그인 사용자번호
        let url = "/class-app/v1/comps/" + compNo + "/dtms/" + dtmsFileNo + "?USER_NO=" + userNo;
        
        let response = await $axios.delete(url);
        return response;
    },
    async deleteCanvasDTMS(dtmsFileNo) {
        const userNo = $session.getUserNo(); // 로그인 사용자번호
        let url = "/vision-app/v1/dtm/images/" + dtmsFileNo + "?USER_NO=" + userNo;
        
        let response = await $axios.delete(url);
        return response;
    },
    // hexa를 byte배열로 변환
    hexToBytes(hex) 
    {
        for (var bytes = [], c = 0; c < hex.length; c += 2) {
          bytes.push(parseInt(hex.substr(c, 2), 16));
        }
        return bytes;
    },
}
export {
    file
}
