<template>
  <canvas :id="id" height="77.33" width="116" style="z-index: -1"></canvas>
</template>

<script>
import {fabric} from "fabric";

export default {
  name: "Page",
  props: {
    pixels: Object,
    page: Object,
    id: String
  },
  setup() {
    let thumbnailCanvas = null;
    return {
      thumbnailCanvas
    }
  },
  data() {
    return {
      scale: 0,
      size: 1,
    }
  },
  watch: {
    pixels() {
      this.renderPixels();
    }
  },
  mounted() {
    this.initThumbnail();
    // 라이브러리 DTMS 로드 시 초기 페이지들의 썸네일 표시를 위함.
    this.renderPixels();
  },
  methods: {
    initThumbnail() {
      this.thumbnailCanvas = new fabric.Canvas(this.id);
      this.thumbnailCanvas.selection = false;
      this.thumbnailCanvas.activeDrawing = false;

      // 화면사이즈에 의해 깨짐방지
      if(this.thumbnailCanvas.wrapperEl.parentElement.clientWidth/2 > this.thumbnailCanvas.wrapperEl.parentElement.clientHeight) {
        this.scale = Math.round((this.thumbnailCanvas.wrapperEl.parentElement.clientHeight * 0.9) / (this.$parent.$parent.VOXEL_ROW_NUM * 1));
      } else {
        this.scale = Math.round((this.thumbnailCanvas.wrapperEl.parentElement.clientWidth * 0.9) / (this.$parent.$parent.VOXEL_COL_NUM * 1));
      }
      this.thumbnailCanvas.setWidth(this.$parent.$parent.VOXEL_COL_NUM * this.scale);
      this.thumbnailCanvas.setHeight(this.$parent.$parent.VOXEL_ROW_NUM * this.scale);

      for (let r = 0; r < this.$parent.$parent.VOXEL_ROW_NUM; r++) {
        for (let c = 0; c < this.$parent.$parent.VOXEL_COL_NUM; c++) {
          const pin = new fabric.Circle({
            radius: (this.scale * this.size) / 2 * 0.75,
            fill: "rgba(255, 255, 255, 255)",
            stroke: "",
            strokeWidth: 0.5,
            left: c * this.scale,
            top: r * this.scale,
            objectCaching: false,
            selectable: false,
            hasControls: false,
            hasBorders: false,
            hasRotatingPoint: false,
            hoverCursor: "pointer"
          });
          this.thumbnailCanvas.add(pin);
        }
      }
      this.thumbnailCanvas.requestRenderAll();
    },
    renderPixels() {
      this.clearThumbnail();
      let pins = this.getPinObjects();
      for (let i = 0; i < this.pixels.length; i++) {
        if (this.pixels[i]) {
          pins[i].fill = "rgba(0, 0, 0, 255)";
        }
      }
      this.thumbnailCanvas.requestRenderAll();
    },
    getPinObjects() {
      const objects = this.thumbnailCanvas.getObjects();
      let tmpList = [];
      for (let i = 0; i < 2400; i++) {
        tmpList.push(objects[i]);
      }
      return tmpList;
    },
    clearThumbnail() {
      let pins = this.getPinObjects();
      for (let i = 0; i < this.pixels.length; i++) {
        pins[i].fill = "rgba(255, 255, 255, 255)";
      }
    }
  },
}
</script>

<style scoped>
.lower-canvas{
  z-index:0 !important;
  position:relative !important;
}
</style>