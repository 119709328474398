<template>
  <nav class="navbar navbar-expand navbar-light">
    <div class="container-fluid p-0 gap-3 align-items-stretch">
      <div class="navCont">
        <div class="dbIcon">
          <p style="margin-bottom: 0;">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_572_3836)">
              <path d="M25.4495 0H47.5491C54.8783 0 58.5412 0 62.4863 1.24685C66.7936 2.81449 70.1855 6.20714 71.7524 10.5137C73 14.4588 73 18.1224 73 25.4509V47.5491C73 54.8776 73 58.5412 71.7524 62.4863C70.1855 66.7936 66.7929 70.1855 62.4863 71.7524C58.5419 73 54.8783 73 47.5491 73H25.4495C18.1217 73 14.4581 73 10.5137 71.7524C6.20643 70.1855 2.81449 66.7929 1.24756 62.4863C0 58.5412 0 54.8776 0 47.5491V25.4509C0 18.1231 0 14.4596 1.24756 10.5137C2.81449 6.20714 6.20714 2.81449 10.5137 1.24685C14.4588 0 18.1217 0 25.4495 0Z" fill="#EAEAEA"/>
              <path d="M40.2926 61.2216C41.7474 61.2216 42.9267 50.1534 42.9267 36.5C42.9267 22.8466 41.7474 11.7784 40.2926 11.7784C38.8378 11.7784 37.6584 22.8466 37.6584 36.5C37.6584 50.1534 38.8378 61.2216 40.2926 61.2216Z" fill="#44403F"/>
              <path d="M47.1462 58.7493C48.4554 58.7493 49.5166 48.788 49.5166 36.5C49.5166 24.212 48.4554 14.2507 47.1462 14.2507C45.8371 14.2507 44.7759 24.212 44.7759 36.5C44.7759 48.788 45.8371 58.7493 47.1462 58.7493Z" fill="#44403F"/>
              <path d="M52.8858 54.2995C53.9331 54.2995 54.7821 46.3304 54.7821 36.5C54.7821 26.6696 53.9331 18.7006 52.8858 18.7006C51.8385 18.7006 50.9895 26.6696 50.9895 36.5C50.9895 46.3304 51.8385 54.2995 52.8858 54.2995Z" fill="#44403F"/>
              <path d="M57.4255 47.1798C58.0539 47.1798 58.5633 42.3983 58.5633 36.5C58.5633 30.6017 58.0539 25.8202 57.4255 25.8202C56.7971 25.8202 56.2877 30.6017 56.2877 36.5C56.2877 42.3983 56.7971 47.1798 57.4255 47.1798Z" fill="#44403F"/>
              <path d="M60.7661 40.7716C61.0173 40.7716 61.2209 38.8592 61.2209 36.5C61.2209 34.1408 61.0173 32.2284 60.7661 32.2284C60.5149 32.2284 60.3113 34.1408 60.3113 36.5C60.3113 38.8592 60.5149 40.7716 60.7661 40.7716Z" fill="#44403F"/>
              <path d="M32.8036 61.2216C34.2584 61.2216 35.4377 50.1534 35.4377 36.5C35.4377 22.8466 34.2584 11.7784 32.8036 11.7784C31.3488 11.7784 30.1694 22.8466 30.1694 36.5C30.1694 50.1534 31.3488 61.2216 32.8036 61.2216Z" fill="#44403F"/>
              <path d="M25.8536 58.7493C27.1628 58.7493 28.224 48.788 28.224 36.5C28.224 24.212 27.1628 14.2507 25.8536 14.2507C24.5445 14.2507 23.4833 24.212 23.4833 36.5C23.4833 48.788 24.5445 58.7493 25.8536 58.7493Z" fill="#44403F"/>
              <path d="M20.1142 54.2995C21.1615 54.2995 22.0105 46.3304 22.0105 36.5C22.0105 26.6696 21.1615 18.7006 20.1142 18.7006C19.0669 18.7006 18.2179 26.6696 18.2179 36.5C18.2179 46.3304 19.0669 54.2995 20.1142 54.2995Z" fill="#44403F"/>
              <path d="M15.5745 47.1798C16.2029 47.1798 16.7123 42.3983 16.7123 36.5C16.7123 30.6017 16.2029 25.8202 15.5745 25.8202C14.9462 25.8202 14.4368 30.6017 14.4368 36.5C14.4368 42.3983 14.9462 47.1798 15.5745 47.1798Z" fill="#44403F"/>
              <path d="M12.2339 40.7716C12.4851 40.7716 12.6887 38.8592 12.6887 36.5C12.6887 34.1408 12.4851 32.2284 12.2339 32.2284C11.9827 32.2284 11.7791 34.1408 11.7791 36.5C11.7791 38.8592 11.9827 40.7716 12.2339 40.7716Z" fill="#44403F"/>
              </g>
              <defs>
              <clipPath id="clip0_572_3836">
              <rect width="73" height="73" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </p>
        </div>

        <div class="nav">
          <span class="my-auto" id="title">{{ getDTMSTitle }}</span>
          <ul style="flex-basis: 60%; padding: 0;">
            <li class="nav-item" v-if="$parent.OPTIONS.menu.new">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      @click.prevent.stop="onClickNewBtn">
                {{ $t('새로 만들기') }}
              </a>
            </li>
            <li class="nav-item" v-if="$parent.OPTIONS.menu.open">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :data-bs-original-title="$t('열기 (Ctrl + O)')"
                      @click.prevent.stop="onClickOpenBtn">
                {{ $t('열기') }}
              </a>
            </li>
            <!-- 기존 가져오기, 업로드 버튼 통합 -->
            <!-- 추가하기 버튼 추가 (2023-02-09, 이성준) -->
            <li class="nav-item dropdown" v-if="$parent.OPTIONS.menu.add" ref="add-button">
              <a class="nav-link link-dark fw-bold dropdown-toggle" href="#" role="button"
                data-bs-toggle="dropdown"
                @click.prevent.stop="toggleDropdown"
                aria-expanded="false">
                {{ $t('추가하기') }}
              </a>
              <ul class="dropdown-menu bg-dot-gray">
                <li><a class="dropdown-item" role="button" href="#" @click.stop="onClickImportBtn">{{ $t('닷드라이브') }}</a></li>
                <li><a class="dropdown-item" role="button" href="#" @click.stop="onClickUploadBtn">{{ $t('로컬드라이브') }}</a></li>
              </ul>
            </li>
            <!-- Export 추가 (2023-02-09, 이성준) -->
            <li class="nav-item" v-if="$parent.OPTIONS.menu.export">
              <a href="#" class="nav-link link-dark fw-bold" role="button" @click.prevent.stop="onClickExportBtn">{{ $t('내보내기') }}</a>
            </li>
            <li class="nav-item" v-if="($parent.OPTIONS.menu.save && $parent.dtmsOpenData.dtmsFileNo === '') || ($parent.OPTIONS.menu.save && $parent.dtmsOpenData.dtmsGubun === 'PRIVATE' && $parent.dtmsOpenData.dtmsFileNo != '') || ($parent.OPTIONS.menu.save && $parent.dtmsOpenData.dtmsGubun === 'PUBLIC' && $parent.isDotAdmin())">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :data-bs-original-title="$t('저장 (Ctrl + S)')"
                      @click.prevent.stop="onClickSaveBtn">
                {{ $t('저장') }}
              </a>
            </li>
            <li class="nav-item" v-if="$parent.OPTIONS.menu.delete && $parent.dtmsOpenData.dtmsFileNo != '' && ($parent.dtmsOpenData.dtmsGubun === 'PRIVATE' || ($parent.dtmsOpenData.dtmsGubun === 'PUBLIC' && $parent.isDotAdmin()))">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                      @click.prevent.stop="onClickDeleteBtn">
                {{ $t('삭제') }}
              </a>
            </li>
            <li class="nav-item" v-show="($parent.OPTIONS.menu.saveAs && $parent.dtmsOpenData.dtmsFileNo != '')">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      :data-bs-original-title="$t('다른 이름으로 저장 (Ctrl + Shift + S)')"
                      @click.prevent.stop="onClickSaveAsBtn"
              >
                {{ $t('다른 이름으로 저장') }}
              </a>
            </li>
            <!-- Print Dotpad Button -->
            <li class="nav-item" v-if="$parent.OPTIONS.menu.padPrint">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                :data-bs-original-title="$t('프린트 (Alt + P)')"
                @click.prevent.stop="$parent.printDTM()"
              >
                {{ $t('닷패드출력') }}
              </a>
            </li>
            <!-- 2022-08-18 / 정태화 / 전송,닷패드 버튼 상태값 표시 추가 -->
            <li class="nav-item" v-if="$parent.OPTIONS.menu.remoteSend">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Share (Alt + S)"
                @click.prevent.stop="$parent.shareScreen()"
              >
                {{ $t('원격전송') }}
                <h6 :style="{'font-size':'0.4em', 'color':classroomColor }" class="fw-bold">{{ classroomConnect() }}</h6>
              </a>
            </li>
            <!-- Participant(Viewer) Button -->
            <li class="nav-item" v-if="$parent.OPTIONS.menu.participant">
              <a href="#" class="nav-link link-dark fw-bold" role="button"
                data-bs-toggle="modal"
                data-bs-target="#viewerModal"
              >
                {{ $t('참가자') }}
                <h6 :style="{'font-size':'0.4em', 'color': 'black' }" class="fw-bold">
                  {{ participants ? participants.length : '0' }}</h6>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="d-flex bg-light rounded-4" style="flex-basis: 40%"></div> -->
    </div>
  </nav>

  <!-- DTMS 파일 업로드 -->
  <input type="file" ref="UPLOAD_DTM" accept=".dtms" @change="uploadDtmFile" enctype="multipart/form-data" hidden/>

  <!-- Device Dialog -->
  <!-- <Device @connect-result="connectResult"></Device> -->

  <!-- Class Participant Dialog -->
  <div id="viewerModal" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
    <ViewerDialog :participants="participants"></ViewerDialog>
  </div>

  <!-- 2022-08-18 / 정태화 / 팝업창 header 태그 바깥으로 이동 -->
  <!-- 갤러리 불러오기 다이얼로그 -->
  <GalleryViewDialog ref="openDialog"
                     :library-open="isLibraryOpen"
                     @get-dtms-detail="getDTMSDetail"
                     @close-library="closeLibrary"
  ></GalleryViewDialog>
  <!-- 파일 저장하기 다이얼로그 -->
  <FileBrowser ref="fileBrowser"
               v-if="fileBrowserOpen"
               @close="fileBrowserOpen = false" :mode="mode" :web_mode="web_mode"
               @get-dtms-detail="getDTMSDetail"></FileBrowser>
  <!-- 엣지처리 다이얼로그 -->
  <ImageEdgeDialog ref="edgeDialog" style="position:fixed; z-index:9999; right:30%;"
                   @set-image-mat="setImageMat">
  </ImageEdgeDialog>
  <!-- 로케일 다이얼로그 -->
  <Locale v-if="localeOpen" @close="closeLocale"></Locale>

  <!-- 파일 삭제 다이얼로그 -->
  <!-- <DeleteFileDialog ref="deleteDialog" :type="type" :web_mode="web_mode"></DeleteFileDialog> -->
</template>

<script>
import {defineAsyncComponent} from 'vue'
import Locale from "./dialog/Locale";
import FileBrowser from "./dialog/FileBrowser";
import ViewerDialog from "./dialog/ViewerDialog";
import {DTMS, Page} from "./js/common";
import {$axios, $session} from "dot-framework";
import {Dotpad} from "./js/Dotpad.js";
import {DotpadSDK} from "./js/DotpadSDK.js";
import {SocketClient} from "./js/SocketClient.js";
import {file} from "./js/file.js";
//import global from "@/js/global";
import {Dropdown, Tooltip} from "bootstrap/dist/js/bootstrap";
import {mapGetters, mapMutations} from "vuex";

const GalleryViewDialog = defineAsyncComponent(() => import("./dialog/GalleryViewDialog"));
// const DeleteFileDialog = defineAsyncComponent(() => import("./dialog/DeleteFileDialog"));
const ImageEdgeDialog = defineAsyncComponent(() => import ("./dialog/ImageEdgeDialog"));

export default {
  components: {
    FileBrowser,
    // Device,
    GalleryViewDialog,
    // DeleteFileDialog,
    ImageEdgeDialog,
    Locale,
    ViewerDialog,
  },
  props: {
    web_mode:String
  },
  data() {
    return {
      isLibraryOpen: false,
      dotpadsdk: new DotpadSDK(),
      dotpad: new Dotpad(),
      socketclient: new SocketClient(),
      // showDevice: false,
      // isConnect: false,
      dotpadConnect: "disConnected",
      dotpadColor: "black",
      classroomColor: "black",
      // 2022-08-18 / 정태화 / 불러오기 팝업 구분자(OPEN, IMPORT)
      openImportGubun: "OPEN",
      localeOpen: false,
      mode: "",
      fileBrowserOpen: false,
      fileNo: "",
      type: "P",
      participants: [],
      isSaving: false, // 2023-10-11 / 이성준 / 저장 중 잠금 플래그
    }
  },
  computed: {
    ...mapGetters("braille", ["kind", "language", "grade", "rule", "pin"]),
    ...mapGetters("canvasPage", ["maxPage"]),
    getDTMSTitle() {
      const title = (this.$parent.dtms.title == '' || this.$parent.dtms.title == undefined) ?
          'Untitled' : this.$parent.dtms.title;
      return title;
    },
    workflow() {
      return this.$t('열기 (Ctrl + O)');
    }
  },
  mounted() {
    const tooltipElements = document.querySelectorAll('a[data-bs-toggle*="tooltip"]');
    tooltipElements.forEach(element => {
      new Tooltip(element);
    });

    const dropDownElements = document.querySelectorAll('a.dropdown-toggle');
    dropDownElements.forEach(element => {
      new Dropdown(element);
    });
    // 클래스룸에서만 사용하는 소스
    if (this.$parent.OPTIONS.menu.remoteSend) {
      this.getLibrary();
    }
    if(this.$parent.OPTIONS.menu.participant) {
      this.getParticipants();
    }
  },
  methods: {
    ...mapMutations("braille", ["setKind", "setLanguage", "setGrade", "setRule", "setPin"]),
    // 라이브러리 조회
    getLibrary() {
      this.fileNo = this.socketclient.dtmsFileNo;
      this.type = this.socketclient.type;
      if (this.socketclient.type != "") {
        console.log("Get", this.fileNo, this.type)
        this.getDTMSDetail(this.socketclient.dtmsFileNo, this.socketclient.type)
        this.socketclient.type = "";
        this.socketclient.dtmsFileNo = "";
      } else {
        this.socketclient.type = "";
        this.socketclient.dtmsFileNo = "";
      }
    },
    // 새파일 버튼
    onClickNewBtn() {
      this.$parent.newPage();
    },
    // 파일오픈 버튼
    async onClickOpenBtn() {
      const message = this.$t("현재 DTMs 파일을 저장하시겠습니까?");

      if (this.$parent.dtmsOpenData.dtmsFileNo !== "" && (this.$parent.isCanvasDirty || this.$parent.modified)) // 이전 문서가 이미 저장되어 있으며 편집된 상태일 경우:
      {
        // 저장할지 말지 묻는 메시지 표시되며, 저장하면 저장이되고 파일을 열 수 있는 팝업이 실행된다. 저장하지 않으면 편집된 내용은 저장되지 않고 팝업이 열린다.
        const result = await this.$swal({
          title: this.$t("저장"),
          text: message,
          showCancelButton: true,
          confirmButtonText: this.$t("저장"),
          cancelButtonText: this.$t("취소"),
          reverseButtons: true
        });

        if (result.isConfirmed) {
          await this.onClickSaveBtn();
        }
      } else if (this.$parent.dtmsOpenData.dtmsFileNo === "" && (this.$parent.isCanvasDirty || this.$parent.modified)) // 이전 문서가 저장되어 있지 않으며 편집된 상태일 경우
      {
        // 저장할지 말지 묻는 메시지가 표시되며, 저장하면 '다른 이름으로 저장'한 뒤 팝업이 실행된다. 저장하지 않으면 편집된 내용이 '다른 이름으로 저장'되지 않고 팝업이 열린다.
        const result = await this.$swal({
          title: this.$t("저장"),
          text: message,
          showCancelButton: true,
          confirmButtonText: this.$t("저장"),
          cancelButtonText: this.$t("취소"),
          reverseButtons: true
        });

        if (result.isConfirmed) {
          await this.onClickSaveAsBtn();
        }
      } else // 이전 문서가 이미 저장되어 있으며 편집되지 않은 상태일 경우, 이전 문서가 저장되어 있지 않으며 편집되지 않은 상태일 경우
      {
        // 저장할지 말지 묻는 메시지 표시하지 않고 팝업이 열린다.
      }

      this.mode = "open";
      this.fileBrowserOpen = true;
      this.openImportGubun = "OPEN";
    },
    // 2022-08-18 / 정태화 / 가져오기 버튼 추가
    onClickImportBtn() {
      this.mode = "import";
      // this.isLibraryOpen = true;
      this.fileBrowserOpen = true;
      this.openImportGubun = "IMPORT";
      // this.$refs.openDialog.getGroupList();
    },
    // 업로드 버튼 추가 (2022-11-11, 정태화)
    onClickUploadBtn() {
      let fileInput = this.$refs.UPLOAD_DTM;
      fileInput.click();
    },
    // DTM 파일 업로드 처리 (2022-11-11, 정태화)
    async uploadDtmFile(e) {
      const formData = new FormData();
      let dtmFile = e.target.files[0];
      formData.append('DTMS_FILE', dtmFile);

      let url = "/vision-app/v1/dtms/images/upload-to-json";
      let response = await $axios.post(url, formData)
        .finally(()=> e.target.value = "");
      let dtmsJson = response.data.DTMS_JSON;
      // this.$parent.pages 데이타 생성
      this.bindDtmsData(dtmsJson);
      // Canvas에 첫번째 페이지 데이타 표시
      this.$parent.loadPage(0);
    },
    /**
     * Export 변경
     * @author 이성준
     * @since 2023. 2. 9.
     * @version 2
     */
    async onClickExportBtn() {
      const title = this.$parent.dtms.title || "Untitled";
      const brailleLang = this.language;
      const langOption = this.grade;
      const dtms = new DTMS({title: title, lang: brailleLang, lang_option: langOption});

      for (let i = 0; i < this.$parent.pages.length; i++) {
        const page = this.$parent.pages[i];
        let hexData = await this.$parent.fetchTextToBraille("altText", page.text.plain);
        hexData = await this.$parent.textToBraille("", hexData);
        page.text.data = hexData;
        dtms.items.push(page.makeJson());
      }

      const exportObj = dtms.makeJson(); // DTMS에 대한 JSON String
      const fileName = exportObj.title || 'Untitled';
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
      const a = document.createElement('a');
      a.href = dataStr;
      a.download = `${fileName}.dtms`;
      a.click();
    },
    async onClickSaveBtn() {
      let dtmsGubun = this.$parent.dtmsOpenData.dtmsGubun;
      let dtmsFileNo = this.$parent.dtmsOpenData.dtmsFileNo;
      if (dtmsFileNo != "" && (dtmsGubun === "PRIVATE" || (dtmsGubun === "PUBLIC" && this.$parent.isDotAdmin()))) {
        // 만약 이미 실행 중이면 클릭을 무시
        if (this.isSaving) {
          return;
        }

        this.isSaving = true; // 저장 중 잠금 플래그 활성화
        try {
          let dtmsGroupNo = this.$parent.dtmsOpenData.dtmsGroupNo;
          await this.$parent.uploadDTMS(dtmsGroupNo);
        } finally {
          this.isSaving = false; // 작업이 완료되면 플래그를 다시 false로 설정
        }
      } else {
        this.mode = "save";
        this.fileBrowserOpen = true;
      }
    },
    async onClickDeleteBtn() {
      //this.$refs.deleteDialog.open();
      if(confirm(this.$t('삭제설명'))){
        let dtmsFileNo = this.$parent.dtmsOpenData.dtmsFileNo;
        let response = await file.dtmsDataDelete(this.web_mode, dtmsFileNo);
        if (response.status == 200) {
            this.onClickNewBtn();
        }
      }
    },
    onClickSaveAsBtn() {
      this.mode = "saveAs";
      this.fileBrowserOpen = true;
      return new Promise(resolve => {
        this.$nextTick(() => {
          const unwatch = this.$watch('fileBrowserOpen', () => {
            // 감시자를 중지
            unwatch();
            resolve();
          });
        });
      });
    },
    closeLibrary() {
      this.isLibraryOpen = false;
    },
    // 불러오기 팝업 목록의 항목 클릭시 호출
    async getDTMSDetail(fileno, type, dtmGroupNo, fileName, driveType) {
      if (fileno.length === 0) {
        console.log("DTM file number is not specified.");
        return;
      }

      const compNo = $session.getCompNo(); // 업체번호 C220512001

      let url = "";
      let param = "";
      if(this.web_mode === "CLASSROOM"){
        url = "/class-app/v1/comps/" + compNo + "/dtms/" + fileno;
        param = "?DATA_TYPE=" + type;
      } else if(this.web_mode === "CANVAS") {
        url = "/vision-app/v1/dtm/images/" + fileno + "/device/300/to-dtms";
      }

      let response = await $axios.get(url + param);

      if (response.status == 200) {
        let jsonObj = "";

        if(this.web_mode === "CLASSROOM"){
          jsonObj = response.data.DTMS_SAVE_CONTENTS;
        } else if(this.web_mode === "CANVAS") {
          jsonObj = response.data.DTMS_JSON;
          if(fileName) {
            jsonObj.title = fileName;
          }
        }

        // 2022-08-18 / 정태화 / 불러오기 구분자 처리
        if (this.openImportGubun == "OPEN") {
          this.$parent.pages.splice(0, this.$parent.pages.length);
        }

        // 함수 분리 처리 (2022-11-11, 정태화)
        this.bindDtmsData(jsonObj);

        // 불러오기 구분자 처리 (2022-08-18, 정태화)
        if (this.openImportGubun == "OPEN") {
          let newDtms = new DTMS({
            title: jsonObj.title,
            lang: jsonObj.lang,
            lang_option: jsonObj.lang_option || jsonObj.lang_Option,
            device: jsonObj.device
          });
          this.$parent.dtms = newDtms;
          this.$parent.loadPage(0);
          this.$parent.dtmsOpened = true;

          // 점역 언어세팅
          if (jsonObj.lang) {
            this.setLanguage(jsonObj.lang);
            this.setGrade(jsonObj.lang_option || jsonObj.lang_Option);
          }

          // DTMS정보 변수 추가 (2022-08-02, 정태화)

          let dtmsOpenData = {};

          if(this.web_mode === "CLASSROOM"){
            dtmsOpenData = {
              dtmsGubun: (response.data.COMP_NO == "" ? "PUBLIC" : "PRIVATE"),
              dtmsFileNo: fileno,
              dtmsGroupNo: response.data.DTMS_GROUP_NO,
            };
          } else if(this.web_mode === "CANVAS") {
            dtmsOpenData = {
              dtmsGubun: (type == "O" || driveType == "D" ? "PUBLIC" : "PRIVATE"),
              dtmsFileNo: fileno,
              dtmsGroupNo: dtmGroupNo
            };
          }

          this.$parent.dtmsOpenData = dtmsOpenData;
        } else if (this.openImportGubun === "IMPORT") {
          this.$parent.loadPage(0);
        }
      } else {
        console.log("Failed to load DTMS: ", response.data);
      }
    },
    // 함수 분리 처리 (2022-11-11, 정태화)
    bindDtmsData(dtmsJson) {
      const items = dtmsJson?.items;
      const pageLength = (this.$parent.pages?.length || 0) + (items?.length || 0);
      let pno = 1;

      if (pageLength > this.maxPage) {
        const message = this.$t("최대 100페이지까지 가능합니다.");

        this.$swal({
          title: message,
          showConfirmButton: false,
          timer: 3000
        });

        return false;
      }

      for (let i = items.length - 1; i >= 0; i--) {
        const page = items[i];
        const _graphicData = page.graphic.data; // 600자 Hex String
        const byteArr = this.dotpadsdk.hexToBytes(_graphicData); // 300 Bytes Array
        const tmpArr = [];

        for (let i = 0; i < byteArr.length; i++) {
          let start_index = parseInt(i / 30) * 60 * 4 + (i % 30) * 2;
          tmpArr[start_index] = !!(byteArr[i] & (0x01 << 0));
          tmpArr[start_index + 60] = !!(byteArr[i] & (0x01 << 1));
          tmpArr[start_index + 120] = !!(byteArr[i] & (0x01 << 2));
          tmpArr[start_index + 180] = !!(byteArr[i] & (0x01 << 3));
          tmpArr[start_index + 1] = !!(byteArr[i] & (0x01 << 4));
          tmpArr[start_index + 61] = !!(byteArr[i] & (0x01 << 5));
          tmpArr[start_index + 121] = !!(byteArr[i] & (0x01 << 6));
          tmpArr[start_index + 181] = !!(byteArr[i] & (0x01 << 7));
        }
        page.graphic.data = tmpArr;

        // Public 불러오기 오류 수정 (2022-08-02, 정태화)
        //const pageObject = new Page(page.title, pno, page.graphic, page.text, [], []);
        let textName = page.text?.name || "";
        let textData = page.text?.data || "";
        let textPlain = page.text?.plain || "";

        let pageObject = new Page(page.title, pno,
          {
            name: page.graphic.name,
            data: page.graphic.data
          },
          {
            name: textName,
            data: textData,
            plain: textPlain
          },
          [], []);

        // 첫번쨰 인덱스에 페이지 삽입
        this.$parent.pages.unshift(pageObject);
        pno++;
      }
    },
    // Index.vue > uploadDTMS에서 insert/update 처리 (2022-08-16, 정태화)
    // async editDTMS(dtmsGroupNo, dtmsFileNo) {
    //   const compNo = $session.getCompNo(); // 업체번호 C220512001
    //   const userNo = $session.getUserNo(); // 로그인 사용자번호

    // console.log("Pages: ", this.$parent.pages);
    //   for (const page of this.$parent.pages) {
    //     if (this.$parent.dtms.items == undefined) this.$parent.dtms.items = [];
    //     // console.log("Page: ", page, typeof (page));
    //     this.$parent.dtms.items.push(page.makeJson());
    //   }

    //   const PARAMS_BODY = {
    //     "USER_NO": userNo, // 필수
    //     "DTMS_GROUP_NO": dtmsGroupNo,
    //     "DTMS_SAVE_CONTENTS": this.$parent.dtms.makeJson(), // DTMS에 대한 JSON String
    //     "FILE_NAME": this.$parent.dtms.title,
    //     "FILE_DESC": this.$parent.dtms.file_description
    //   };

    //   let url = "/class-app/v1/comps/" + compNo + "/dtms/" + dtmsFileNo;
    //   let response = await $axios.put(url, PARAMS_BODY);
    //   if (response.status == 200) {
    //     alert("수정 성공했습니다.");
    //   } else {
    //     alert("수정 실패했습니다.");
    //   }
    // },
    // 이미지에서 추출된 엣지 데이터를 화이트보드로 불러오는 함수
    setImageMat(mat) {
      this.$parent.pages[this.$parent.curPage - 1].graphic.data = mat;
      this.$parent.pixels = this.$parent.pages[this.$parent.curPage - 1].graphic.data;
      this.$parent.drawPixels();
      this.$parent.savePage();
      this.$parent.endDrawing();
      this.$refs.edgeDialog.close();
    },
    // 기기연결 결과 받기
    connectResult(_connectCount) {
      this.$parent.connectCount = _connectCount;
      if (_connectCount == 0) {
        this.dotpadConnect = "disConnected";
        this.dotpadColor = "black";
      } else {
        this.dotpadConnect = _connectCount + " connected";
        this.dotpadColor = "red";
      }
    },
    // 클래스룸 접속여부
    classroomConnect() {
      let result = "disconnect";
      const socketClient = new SocketClient();
      if (socketClient) {
        if (socketClient.isConnect) {
          result = "connected";
          this.classroomColor = "red";
        } else {
          result = "disConnected";
          this.classroomColor = "black";
        }
      }
      return result;
    },
    closeLocale() {
      this.localeOpen = false;
    },
    // 클래스룸 참가자
    async getParticipants() {
      const compNo = $session.getCompNo(); // 업체번호 C220512001
      const classNo = this.socketclient.roomId;
      let url = "/class-app/v1/comps/" + compNo + "/classes/" + classNo + "/users";
      console.log("getPariticipants: ", url);
      let response = await $axios.get(url);
      if (response.status == 200) {
        this.participants = response.data.items;
      }
    },
    // 콤포넌트 외부에서 권한처리 추가
    // checkPermission() {
    //    return global.isTeacher() || global.isSchoolAdmin();
    // },
    async download() {
      const compNo = $session.getCompNo(); // 업체번호 C220512001

      if (this.$parent.dtmsOpenData != null && this.$parent.dtmsOpenData.dtmsFileNo.length === 0) return;

      let url = `/class-app/v1/comps/${compNo}/dtms/${this.$parent.dtmsOpenData.dtmsFileNo}`;
      let params = `?DATA_TYPE=${this.type}`;
      let response = await $axios.get(url + params);
      if (response.status === 200) {
        console.log(response);
        window.open(response.data.DTMS_URL);
      }
    },
    toggleDropdown(event) {
      const nodeList = document.querySelectorAll('.nav-link.dropdown-toggle');
      nodeList.forEach((node) => { if(event.target !==  node) { Dropdown.getInstance(node).hide() } });
      Dropdown.getInstance(event.target)?.toggle();
    }
  }
}
</script>

<style scoped>
nav {
  /* height: 118px; */
  background: #f7f7f7;
  padding: 0;
}

#title {
  font-size: 32px;
  padding-left: 10px;
}
.navCont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 2%;
}
.nav {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: #44403F;
  padding-left: 20px;
}
.nav ul:after {
  content: "";
  clear: both;
  display: block;
}
.nav ul {
  margin-bottom: 0;
}
.nav ul li {
  float: left;
  list-style: none;
}
.nav-item a {
  font-size: 20px;
}
.nav-link {
  padding: 0 10px;
}
.nav-link:hover {
  color: var(--dot-gray) !important;
}

.dropdown-toggle::after {
  display: none;
}
.dropdown-menu > li {
  width: 100%;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #EA5414;
}

.dropdown-menu a {
    color:#f7f7f7;
}

</style>
