import {Line} from "./Line";

class DottedLine extends Line {
  constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
    super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
  }

  figureDraw() {
    return this.drawLine(2, 1);
  }
}

export {
  DottedLine
};
