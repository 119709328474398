import {Shapes} from "./Shapes";

class Star extends Shapes {
    constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
        super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
    }

    figureDraw() {
        let xC;
        let yC;
        let r;
        let angle;
        let resultArr = [];
        let tmpArr = [];
        let tmpSet = new Set();

        if (Math.abs(this.startPoint.cx - this.endPoint.cx) > 1 && Math.abs(this.startPoint.cy - this.endPoint.cy) > 1) {
            xC = Math.round((this.x0 + this.x1) / 2); // 중심 x 좌표
            yC = Math.round((this.y0 + this.y1) / 2); // 중심 y 좌표
            r = Math.min(Math.abs(xC - this.startPoint.cx), Math.abs(yC - this.startPoint.cy)); // 반지름
            const angles = [0, 72, 144, 216, 288]; // 5개 꼭짓점의 각도 (36도씩 차이)

            for (const a of angles) {
                angle = (a - 90) * Math.PI / 180;
                const x = Math.round(r * Math.cos(angle) + xC);
                const y = Math.round(r * Math.sin(angle) + yC);
                resultArr.push(this.index(x, y, this.VOXEL_COL_NUM));
            }

            for (let i = 0; i < angles.length - 1; i++) {
                const a1 = angles[i];
                const a2 = angles[i + 1];
                const midAngle = (a1 + a2) / 2;
                angle = (midAngle - 90) * Math.PI / 180;
                const x = Math.round(r / 2 * Math.cos(angle) + xC);
                const y = Math.round(r / 2 * Math.sin(angle) + yC);
                resultArr.push(this.index(x, y, this.VOXEL_COL_NUM));
            }

            for (const pin of Array.from(resultArr)) {
                if (pin % this.VOXEL_COL_NUM < this.x0 || pin % this.VOXEL_COL_NUM > this.x1) continue;
                tmpArr.push(pin);
            }
            resultArr = tmpArr;
        }

        for (const pin of Array.from(resultArr)) {
            if (pin % this.VOXEL_COL_NUM < this.x0 || pin % this.VOXEL_COL_NUM > this.x1) continue;
            if (tmpSet.has(pin)) continue;
            tmpSet.add(pin);
        }

        return Array.from(tmpSet);
    }
}

export {
    Star
}
