let SocketClient_Instance = null;

class SocketClient {

    constructor() {
        if (SocketClient_Instance) return SocketClient_Instance;
        this.stompClient = null;
        this.message = ""; // 송신 메세지
        this.roomId = ""; // 클래스룸 번호
        this.receivedMessages = null; // 수신 메세지 목록
        this.isConnect = false; // 웹소켓 연결여부
        this.CallbackFtn = null;
        this.teacher = true;
        this.dtmsFileNo = "";
        this.type = "";
        SocketClient_Instance = this;
    }

    // 클래스룸 참가자에게 메세지 전송
    sendMessage() {
        this.stompClient.send('/ws/chat.message', {}, JSON.stringify({
            message: this.message,
            roomId: this.roomId,
            sender: 'student01'
        }));
    }

    // 클래스룸 참가자에게 메세지 전송
    sendDTM(dtm_json) {
        if (this.isConnect)
            this.stompClient.send('/ws/chat.message', {}, JSON.stringify({
                message: dtm_json,
                roomId: this.roomId,
                sender: 'student01'
            }));
        else
            console.log("[sendDTM] : stompClient is Not Connected");
    }

    // 메세지 수신
    onMessageReceived(payload) {
        console.log("[payload] : " + payload);
        let jsonString = JSON.parse(payload.body).message;
        let graphic = JSON.parse(jsonString).graphic.data;
        let braille = JSON.parse(jsonString).text.data;
        let text = JSON.parse(jsonString).text.plain;

        
        // console.log("[this.CallbackFtn] : ", SocketClient_Instance.CallbackFtn);
        if (SocketClient_Instance.CallbackFtn && !SocketClient_Instance.teacher) {
            SocketClient_Instance.CallbackFtn(graphic, braille, text);
        }
        //this.receivedMessages = JSON.stringify(jsonString);
        //console.log("[receivedMessages] : ", this.receivedMessages);
    }

}

export {
    SocketClient
}
