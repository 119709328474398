const index = 
{
  // col size 계산
  getColSize(options)
  {
    let result = "col-non-text";
    //let showPage = options.showPage;
    let showText = options.showText;

    if (showText) {
      result = "col-text";
    }

    return result;
  }
}

export {
  index
}