<!--
 * =====================================================================
 * 작 업 명  : 닷 캔버스
 * 파 일 명  : DotCanvas.vue
 * 변경이력
 * 2022-12-12 / 박승현 / 최초작성
 * =====================================================================
 -->
<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <DtmsEditor ref="editor"
                            :options="options"
                            :web_mode="web_mode"
                ></DtmsEditor>
            </div>
        </div>
    </div>
</template>

<script>
import {$session} from "dot-framework";
import DtmsEditor from "@/components/dtms-editor-v1.7/Index";

export default {
    components:
        {
            DtmsEditor
        },
    data() {
        return {
            web_mode: "CANVAS",
            // DTMS에디터 옵션 설정
            options: {
                showPage: true,
                showText: true,
                showPadPrint: true,
                showBrailleBtn: false,
                menu: {
                    open: true,
                    add: true,
                    import: false,
                    upload: false,
                    save: true,
                    saveAs: true,
                    delete: false,
                    padPrint: false,
                    export: true
                }
            },
        }
    },
    mounted() {
        this.options.menu.save = !!$session.isLogin();

        if (this.$route.query.DTM_NO !== undefined && this.$route.query.DTM_NO !== '') {
            const fileNo = this.$route.query.DTM_NO;
            const dtmGroupNo = this.$route.query.DTM_GROUP_NO;
            const type = this.$route.query.TYPE;
            const fileName = this.$route.query.DTM_NAME;

            this.$refs.editor.$refs.navigation.getDTMSDetail(fileNo, "P", dtmGroupNo, fileName, type);
        }
    },
    beforeRouteLeave(to, from, next) {
        const message = this.$t("현재 DTMs 파일을 저장하시겠습니까?");

        if (this.web_mode === "CANVAS") {
            if (this.$refs.editor.dtmsOpenData.dtmsFileNo !== "" && (this.$refs.editor.isCanvasDirty || this.$refs.editor.modified)) // 이전 문서가 이미 저장되어 있으며 편집된 상태일 경우
            {
                // 저장할지 말지 묻는 메시지 표시되며, 저장하면 저장이되고 이동한다. 저장하지 않으면 편집된 내용은 저장되지 않고 이동한다.
                this.$swal({
                    title: this.$t("저장"),
                    text: message,
                    showCancelButton: true,
                    confirmButtonText: this.$t("저장"),
                    cancelButtonText: this.$t("취소"),
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        return this.$refs.editor.$refs.navigation.onClickSaveBtn()
                    }
                }).finally(next);
            } else if (this.$refs.editor.dtmsOpenData.dtmsFileNo === "" && (this.$refs.editor.isCanvasDirty || this.$refs.editor.modified)) // 이전 문서가 저장되어 있지 않으며 편집된 상태일 경우
            {
                // 저장할지 말지 묻는 메시지가 표시되며, 저장하면 '다른 이름으로 저장'한 뒤 이동한다. 저장하지 않으면 편집된 내용이 '다른 이름으로 저장'되지 않고 이동한다.
                this.$swal({
                    title: this.$t("저장"),
                    text: message,
                    showCancelButton: true,
                    confirmButtonText: this.$t("저장"),
                    cancelButtonText: this.$t("취소"),
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        return this.$refs.editor.$refs.navigation.onClickSaveAsBtn()
                    }
                }).finally(next);
            } else  // 이전 문서가 이미 저장되어 있으며 편집되지 않은 상태일 경우, 이전 문서가 저장되어 있지 않으며 편집되지 않은 상태일 경우
            {
              // 저장할지 말지 묻는 메시지 표시하지 않고 이동한다.
              next();
            }
        }
    }
}
</script>

<style scoped>

</style>
