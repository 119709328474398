<template>
  <div class="modal-mask d-flex justify-content-center">
    <div class="modal-container d-flex flex-column justify-content-start">
      <div class="modal-header">
        <h4 class="fw-bold">Language</h4>
        <button class="btn-close float-end mx-3" type="button" @click="$emit('close')"></button>
      </div>
      <div class="modal-body  p-2" style="background-color:#F6F6F6">
        <div class="row  m-1">
          <div class="form-check col">
            <input id="flexRadioDefault1" v-model="locale" :checked="$parent.$parent.locale == 'en'" class="form-check-input" name="flexRadioDefault"
                   type="radio" value="en">
            <label class="form-check-label" for="flexRadioDefault1">English</label>
          </div>
          <div class="form-check col">
            <input id="flexRadioDefault2" v-model="locale" :checked="$parent.$parent.locale == 'ko'" class="form-check-input" name="flexRadioDefault" type="radio"
                   value="ko">
            <label class="form-check-label" for="flexRadioDefault2">Korea</label>
          </div>
        </div>
      </div>
      <div id="role" class="border mt-auto">
        <!-- role position -->
      </div>
      <div class="modal-footer mt-2">
        <button class="btn btn-cancel mx-2 border col" type="button" @click="$emit('close')">Cancel</button>
        <button class="btn btn-set mx-2 border col" type="button" @click="localeChange">Set</button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      locale: "en",
    }
  },
  methods: {
    localeChange() {
      console.log("checked Language", this.locale);
      this.$i18n.locale = this.locale;
      this.$parent.localeOpen = false;
      this.$parent.$parent.setLocale(this.locale);
    }
  }
}
</script>
<style scoped>
.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  display: table;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-body {
  flex: none;
}
.modal-container {
  width: 25vw;
  min-width: 500px;
  height: 400px;
  margin-top: 50px;
  padding: 30px 40px;
  border-radius: 60px;
  background-color: #F7F7F7;
}

h4 {
  margin: 1.5rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.btn-cancel {
  color:#FFFFFF;
  background: #AAABAB;
  border-radius: 60px;
  padding-top:12px;
  padding-bottom:12px;
  margin-left:20px;
  margin-right:20px;
}

.btn-set {
  color:#FFFFFF;
  background: #EA5414;
  border-radius: 60px;
  padding-top:12px;
  padding-bottom:12px;
  margin-left:20px;
  margin-right:20px;
}

.form-check-input:checked {
  background-color: #EA5414;
  border-color: #EA5414;
  background-image:none;
}
</style>
