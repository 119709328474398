import {Shapes} from "./Shapes";

class Triangle extends Shapes {
  #resultArr;
  #x0;
  #x1;
  #y0;
  #y1;
  #dx;
  #dy;
  #sx;
  #sy;
  #err;
  #e2;

  constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
    super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
    this.#resultArr = [];
  }

  figureDraw() {
    const tmpSet = new Set();
    // let resultArr = [];

    this.squareAspectRatio();

    if (Math.abs(this.x0 - this.x1) <= 3) {
      const height = Math.abs(this.y0 - this.y1);
      if (height <= 3) {
        return [];
      }

      let xMargin = Math.floor(height * 0.5767);
      if (this.startPoint.cx < this.endPoint.cx && this.startPoint.cy < this.endPoint.cy || this.startPoint.cx > this.endPoint.cx && this.startPoint.cy < this.endPoint.cy) {
        // 아래 이등변 삼각형
        this.downPointingIsoscelesTriangle(xMargin);
      } else if (this.startPoint.cx < this.endPoint.cx && this.startPoint.cy > this.endPoint.cy || this.startPoint.cx > this.endPoint.cx && this.startPoint.cy > this.endPoint.cy) {
        // 위 이등변 삼각형
        this.upPointingIsoscelesTriangle(xMargin);
      }
    } else if (Math.abs(this.y0 - this.y1) <= 3) {
      const width = Math.abs(this.x0 - this.x1);
      if (width <= 3) {
        return [];
      }

      let yMargin = Math.floor(width * 0.5767);
      if (this.startPoint.cx < this.endPoint.cx && this.startPoint.cy < this.endPoint.cy || this.startPoint.cx < this.endPoint.cx && this.startPoint.cy > this.endPoint.cy) {
        // 우향 이등변 삼각형
        this.rightPointingIsoscelesTriangle(yMargin);
      } else if (this.startPoint.cx > this.endPoint.cx && this.startPoint.cy < this.endPoint.cy || this.startPoint.cx > this.endPoint.cx && this.startPoint.cy > this.endPoint.cy) {
        // 좌향 이등변 삼각형
        this.leftPointingIsoscelesTriangle(yMargin);
      }
    } else {
      if (this.startPoint.cx < this.endPoint.cx && this.startPoint.cy < this.endPoint.cy) {
        this.lowerLeftTriangle();
      } else if (this.startPoint.cx < this.endPoint.cx && this.startPoint.cy > this.endPoint.cy) {
        this.upperLeftTriangle();
      } else if (this.startPoint.cx > this.endPoint.cx && this.startPoint.cy < this.endPoint.cy) {
        this.lowerRightTriangle();
      } else if (this.startPoint.cx > this.endPoint.cx && this.startPoint.cy > this.endPoint.cy) {
        this.upperRightTriangle();
      }
    }

    // 중복 핀 제거 후 선택된 핀 인덱스 리턴
    for (const pin of Array.from(this.#resultArr)) {
      if (tmpSet.has(pin)) continue;
      tmpSet.add(pin);
    }
    this.#resultArr = Array.from(tmpSet);

    return this.#resultArr;
  }

  downPointingIsoscelesTriangle(xMargin) {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        // 밑변
        if (this.startPoint.cx - xMargin >= 0 && this.startPoint.cx + xMargin < this.VOXEL_COL_NUM) {
          if (row === this.y1) {
            if (col >= (this.startPoint.cx - xMargin) && col <= (this.startPoint.cx + xMargin)) {
              const index = row * this.VOXEL_COL_NUM + col;
              this.#resultArr.push(index);
            }
          }
          // 빗변
          // LeftSide
          this.#x0 = this.startPoint.cx;
          this.#y0 = this.y0;

          this.#x1 = this.normalize(this.#x0 - xMargin, 0);
          this.#y1 = this.normalize(this.y1, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
          // RightSide
          this.#x0 = this.startPoint.cx;
          this.#y0 = this.y0;

          this.#x1 = this.normalize(this.#x0 + xMargin, 0);
          this.#y1 = this.normalize(this.y1, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
        }
      }
    }
  }

  upPointingIsoscelesTriangle(xMargin) {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        // 밑변
        if (this.startPoint.cx - xMargin >= 0 && this.startPoint.cx + xMargin < this.VOXEL_COL_NUM) {
          if (row === this.y0) {
            if (col >= (this.startPoint.cx - xMargin) && col <= (this.startPoint.cx + xMargin)) {
              const index = row * this.VOXEL_COL_NUM + col;
              this.#resultArr.push(index);
            }
          }
          // 빗변
          // LeftSide
          this.#x0 = this.startPoint.cx;
          this.#y0 = this.y1;

          this.#x1 = this.normalize(this.#x0 - xMargin, 0);
          this.#y1 = this.normalize(this.y0, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
          // RightSide
          this.#x0 = this.startPoint.cx;
          this.#y0 = this.y1;

          this.#x1 = this.normalize(this.#x0 + xMargin, 0);
          this.#y1 = this.normalize(this.y0, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
        }
      }
    }
  }

  rightPointingIsoscelesTriangle(yMargin) {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        if (this.startPoint.cy - yMargin >= 0 && this.startPoint.cy + yMargin < this.VOXEL_ROW_NUM) {
          // 밑변
          if (col === this.x0) {
            if (row >= this.startPoint.cy - yMargin && row <= this.startPoint.cy + yMargin) {
              const index = row * this.VOXEL_COL_NUM + col;
              this.#resultArr.push(index);
            }
          }
          // 빗변
          // UpSide
          this.#x0 = this.x0;
          this.#y0 = this.startPoint.cy - yMargin;

          this.#x1 = this.normalize(this.x1, 0);
          this.#y1 = this.normalize(this.startPoint.cy, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            if (this.isWithinBounds(this.#x0)) {
              this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            }
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
          // DownSide
          this.#x0 = this.x0;
          this.#y0 = this.startPoint.cy + yMargin;

          this.#x1 = this.normalize(this.x1, 0);
          this.#y1 = this.normalize(this.startPoint.cy, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            if (this.isWithinBounds(this.#x0)) {
              this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            }
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
        }
      }
    }
  }

  leftPointingIsoscelesTriangle(yMargin) {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        if (this.startPoint.cy - yMargin >= 0 && this.startPoint.cy + yMargin < this.VOXEL_ROW_NUM) {
          // 밑변
          if (col === this.x1) {
            if (row >= this.startPoint.cy - yMargin && row <= this.startPoint.cy + yMargin) {
              const index = row * this.VOXEL_COL_NUM + col;
              this.#resultArr.push(index);
            }
          }
          // 빗변
          // UpSide
          this.#x0 = this.x1;
          this.#y0 = this.startPoint.cy - yMargin;

          this.#x1 = this.normalize(this.x0, 0);
          this.#y1 = this.normalize(this.startPoint.cy, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            if (this.isWithinBounds(this.#x0)) {
              this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            }
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
          // DownSide
          this.#x0 = this.x1;
          this.#y0 = this.startPoint.cy + yMargin;

          this.#x1 = this.normalize(this.x0, 0);
          this.#y1 = this.normalize(this.startPoint.cy, 0);

          this.#dx = Math.abs(this.#x1 - this.#x0);
          this.#dy = Math.abs(this.#y1 - this.#y0);

          this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
          this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

          this.#err = this.#dx - this.#dy;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            if (this.isWithinBounds(this.#x0)) {
              this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
            }
            if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
              break;
            }
            this.#e2 = 2 * this.#err;
            if (this.#e2 > -this.#dy) {
              this.#err -= this.#dy;
              this.#x0 += this.#sx;
            }
            if (this.#e2 < this.#dx) {
              this.#err += this.#dx;
              this.#y0 += this.#sy;
            }
          }
        }
      }
    }

  }

  lowerLeftTriangle() {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        // 삼각형 밑변
        if (row === this.y1) {
          if (col >= this.x0 && col <= this.x1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 삼각형의 높이
        if (col === this.x0) {
          if (row >= this.y0 && row <= this.y1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 삼각형의 빗변
        this.#x0 = this.x0;
        this.#y0 = this.y0;

        this.#x1 = this.normalize(this.x1, 0);
        this.#y1 = this.normalize(this.y1, 0);

        this.#dx = Math.abs(this.#x1 - this.#x0);
        this.#dy = Math.abs(this.#y1 - this.#y0);

        this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
        this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

        this.#err = this.#dx - this.#dy;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (this.isWithinBounds(this.#x0)) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
          }
          if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
            break;
          }
          this.#e2 = 2 * this.#err;
          if (this.#e2 > -this.#dy) {
            this.#err -= this.#dy;
            this.#x0 += this.#sx;
          }
          if (this.#e2 < this.#dx) {
            this.#err += this.#dx;
            this.#y0 += this.#sy;
          }
        }
      }
    }
  }

  upperLeftTriangle() {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        // 밑변
        if (row === this.y0) {
          if (col >= this.x0 && col <= this.x1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 높이
        if (col === this.x0) {
          if (row >= this.y0 && row <= this.y1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 빗변
        this.#x0 = this.x0;
        this.#y0 = this.y1;

        this.#x1 = this.normalize(this.x1, 0);
        this.#y1 = this.normalize(this.y0, 0);

        this.#dx = Math.abs(this.#x1 - this.#x0);
        this.#dy = Math.abs(this.#y1 - this.#y0);

        this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
        this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

        this.#err = this.#dx - this.#dy;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (this.isWithinBounds(this.#x0)) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
          }
          if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
            break;
          }
          this.#e2 = 2 * this.#err;
          if (this.#e2 > -this.#dy) {
            this.#err -= this.#dy;
            this.#x0 += this.#sx;
          }
          if (this.#e2 < this.#dx) {
            this.#err += this.#dx;
            this.#y0 += this.#sy;
          }
        }
      }
    }
  }

  lowerRightTriangle() {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        // 밑변
        if (row === this.y1) {
          if (col >= this.x0 && col <= this.x1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 높이
        if (col === this.x1) {
          if (row >= this.y0 && row <= this.y1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 빗변
        this.#x0 = this.x1;
        this.#y0 = this.y0;

        this.#x1 = this.normalize(this.x0, 0);
        this.#y1 = this.normalize(this.y1, 0);

        this.#dx = Math.abs(this.#x1 - this.#x0);
        this.#dy = Math.abs(this.#y1 - this.#y0);

        this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
        this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

        this.#err = this.#dx - this.#dy;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (this.isWithinBounds(this.#x0)) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
          }
          if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
            break;
          }
          this.#e2 = 2 * this.#err;
          if (this.#e2 > -this.#dy) {
            this.#err -= this.#dy;
            this.#x0 += this.#sx;
          }
          if (this.#e2 < this.#dx) {
            this.#err += this.#dx;
            this.#y0 += this.#sy;
          }
        }
      }
    }

  }

  upperRightTriangle() {
    for (let row = 0; row < this.VOXEL_ROW_NUM; row++) {
      for (let col = 0; col < this.VOXEL_COL_NUM; col++) {
        // 밑변
        if (row === this.y0) {
          if (col >= this.x0 && col <= this.x1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 높이
        if (col === this.x1) {
          if (row >= this.y0 && row <= this.y1) {
            const index = row * this.VOXEL_COL_NUM + col;
            this.#resultArr.push(index);
          }
        }
        // 빗변
        this.#x0 = this.x1;
        this.#y0 = this.y1;

        this.#x1 = this.normalize(this.x0, 0);
        this.#y1 = this.normalize(this.y0, 0);

        this.#dx = Math.abs(this.#x1 - this.#x0);
        this.#dy = Math.abs(this.#y1 - this.#y0);

        this.#sx = (this.#x0 < this.#x1) ? 1 : -1;
        this.#sy = (this.#y0 < this.#y1) ? 1 : -1;

        this.#err = this.#dx - this.#dy;
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (this.isWithinBounds(this.#x0)) {
            this.#resultArr.push(this.index(this.#x0, this.#y0, this.VOXEL_COL_NUM));
          }
          if ((this.#x0 === this.#x1) && (this.#y0 === this.#y1)) {
            break;
          }
          this.#e2 = 2 * this.#err;
          if (this.#e2 > -this.#dy) {
            this.#err -= this.#dy;
            this.#x0 += this.#sx;
          }
          if (this.#e2 < this.#dx) {
            this.#err += this.#dx;
            this.#y0 += this.#sy;
          }
        }
      }
    }
  }
}

export {
  Triangle
}
