<template>
  <div>
    <div :class="{on : $parent.useGrid}"
         class="btn-grid d-flex align-items-center justify-content-center"
         :disabled="!$parent.gridReady"
         @click.stop="$parent.useGrid = !$parent.useGrid">
      <div class="btn-icon me-1">
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.03487 8.45041H24.3814M1.03487 16.4739H24.3814M8.61052 23.9129V1.01146M16.7941 23.9129V1.01146M9.18337 23.9243H16.1979C22.0433 23.9243 24.3814 21.6319 24.3814 15.9008V9.02351C24.3814 3.29243 22.0433 1 16.1979 1H9.18337C3.33796 1 0.999794 3.29243 0.999794 9.02351V15.9008C0.999794 21.6319 3.33796 23.9243 9.18337 23.9243Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <span class="ms-1">{{ $t(getGridButtonLabel) }}</span>
      <div class="tooltips">
        <span class="tooltiptext tooltip-right">{{ $t('그리드 켜기/끄기 (Ctrl + G)') }}</span>
      </div>
    </div>
    <ul id="pages">
      <li v-for="(page, index) in pages" v-bind:key="index">
        <div class="page-item"
             v-bind:class="{active: this.curPage === index + 1}"
             @click.stop="onClickPageItem(index)">
          <page :id="'t_' + index" :page="pages[index]" :pixels="pages[index].graphic.data"></page>
          <button v-if="pages.length != 1" class="btn-page-delete ms-auto" @click.stop="onClickDelete(index)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#EA5414" class="bi bi-dash-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </button>
          <div class="page-number">
            <p>{{ index + 1 }}/{{ pages.length }}</p>
          </div>
          <!-- 2022-08-18 / 정태화 / 위아래 이동버튼 추가 -->
          <div class="page-btn-container">
            <button v-if="pages.length > 1" :disabled="pages.length === 1 || index === 0" class="btn-page-up"
                    @click.stop="onClickMoveUp(index)"
                    tabindex="0">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
              </svg>
            </button>
            <button v-if="pages.length > 1" :disabled="pages.length === 1 || index === pages.length - 1"
                      class="btn-page-down" @click.stop="onClickMoveDown(index)"
                      tabindex="0">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
              </svg>
            </button>
          </div>
        </div>
      </li>
      <!-- 페이지 추가 버튼 -->
      <li>
        <div class="page-item page-item-plus" :class="{disable: pages.length >= maxPage}">
          <button class="btn-icon" @click="$emit('add-page')" :disabled="pages.length >= maxPage">
            <svg v-if="pages.length < maxPage" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 13H25M13 25V1" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span v-else class="text">{{ $t('마지막 페이지입니다.') }}</span>
          </button>
        </div>
      </li>
    </ul>
  </div>

</template>
<script>
import Page from "./Page";
import {mapGetters} from "vuex";

export default {
  name: "PageItem",
  // eslint-disable-next-line vue/no-unused-components
  components: {Page},
  props:
      {
        curPage: Number,
        pages: Object
      },
  computed: {
    ...mapGetters("canvasPage", ["maxPage"]),
    getGridButtonLabel() {
      return this.$parent.useGrid ? "그리드 끄기" : "그리드 켜기";
    }
  },
  methods:
      {
        // 삭제 처리
        onClickDelete(idx) {
          this.$emit("load-page-after-delete", idx);
          document.activeElement.blur()
        },
        // 2022-08-18 / 정태화 / 위로 이동
        onClickMoveUp(idx) {
          if (idx > 0) {
            let from = idx;
            let to = idx - 1;
            this.$emit("load-page-after-move", from, to);
            document.activeElement.blur()
          }
        },
        // 2022-08-18 / 정태화 / 아래로 이동
        onClickMoveDown(idx) {
          if (idx < this.pages.length - 1) {
            let from = idx;
            let to = idx + 1;
            console.log("movedown");
            this.$emit("load-page-after-move", from, to);
            document.activeElement.blur()
          }
        },
        // 페이지 항목 클릭
        onClickPageItem(idx) {
          this.$emit("load-page", idx);
          document.activeElement.blur()
        }
      },
}
</script>
<style scoped>
ul#pages {
  overflow-y: auto;
  width: 100%;
  height: auto;
  max-height: 60vh;
  padding: 8px 8px 0;
  list-style: none;
  background-color: #F7F7F7;
  /* box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.12); */
}

#pages li:hover .page-item:not(.active) {
  opacity: 0.8;
  cursor: pointer;
}

.page-item {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height:131px;
  /* height: 13.5vh;
  min-height: 93px; */
  margin-top: 12px;
  /* margin-bottom: 7px; */
  border-radius: 15px;
  background-color:white;
  border:1px solid #fff;
}

.page-number {
  position: absolute;
  top:0;
  display: block;
  width: 100%;
}

.page-number > p {
  font-size: 1rem;
  color: #333;
  margin-top: 8px;
  background: linear-gradient(180deg, #fff, rgba(255,255,255,0));
  border-radius: 15px;
  font-weight: 200;
}

.page-item.active {
  border: 1px solid #EA5414;
}

.page-item:hover:not(.active) {
  /* border: #999 1px solid; */
}

.page-item-plus {
    border:1px solid #ccc;
    background-color: transparent;
}
.page-item-plus .text {
  font-size: 1rem;
  color: #ccc;
  margin-bottom: 0;
}
.disable {
  background: #eee;
  border: none;
}
.info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 34px;
  background-color: #E4E4E4;
}

.page-num {
  margin-left: 7px;
}
.btn-page-delete {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 4px;
  border: 0;
  background-color: transparent;
}

.page-btn-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: grid;
  justify-content: start;
}

.btn-page-up {
  margin-bottom: 4px;
  border: 0;
  background-color: transparent;
}

.btn-page-down {
  margin-bottom: 4px;
  border: 0;
  background-color: transparent;
}

div.pages {
  padding: 0px 8px;
}

button.btn-icon {
  position: relative;
  right: 0;
  bottom: 0;
  margin-bottom: 4px;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/**
  Tooltip Styles
 */
.tooltips {
  position: absolute;
  display: block;
  float: left;
  width: 100%;
  height: 100%;
}

.tooltips .tooltiptext {
  position: absolute;
  z-index: 999;
  visibility: hidden;
  width: max-content; /*120px;*/
  padding: 5px 10px;
  text-align: start;
  color: #fff;
  border-radius: 0px;
  background-color: black;
}

.tooltips:hover .tooltiptext {
  visibility: visible;
}

.tooltips .tooltip-right {
  top: 20px;
  left: 105%;
}

.tooltips .tooltip-right > .hint {
  font-size: small;
  font-weight: lighter;
  display: block;
  margin-top: 4px;
  color: #cbcbcb;
}

/* Button Spinner */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spinner 2s linear infinite;
}

.btn-grid {
  font-size: 1rem;
  position: relative;
  display: block;
  /*overflow: hidden;*/
  width: calc(100% - 16px);
  height: 64px;
  margin: 0px 8px 0px 8px;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 15px;
  color: #44403F;
  /* box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.12); */
}

.btn-grid.on .btn-icon > svg > path {
  stroke: rgba(234, 234, 234,1);
}

.btn-grid .btn-icon > svg > path {
  stroke: rgba(68, 64, 63,1);
}

.btn-grid .btn-icon > svg {
  width: 24px;
  height: 24px;
}

.btn-grid:hover {
  background: rgba(255,255,255,0.8);
}

.btn-grid:hover > span {
  opacity: 0.8;
}

.btn-grid.on:hover .btn-icon > svg > path {
  stroke: rgba(234, 234, 234,0.8);
}

.btn-grid:hover .btn-icon > svg > path {
  stroke: rgba(68, 64, 63,0.8);
}
/* @media (min-width: 992px)
{
  .tooltips .tooltip-right {
    top: 20px;
    left: 105%;
  }
} */

</style>

<style>
.canvas-container {
  padding-top:2.5vh !important;
}
</style>
