<template>
  <div id="fileBrowser" aria-hidden="true" class="modal d-block" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <form ref="fileBrowserForm" v-on:submit.prevent="onClickConfirmButton">
            <div class="div-move-btn">
                <button type="button" class="btn btn-prev" @click="goPrev">
                  <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.3012 1.27195L1.57324 13.9999L14.3012 26.7278" stroke="white" stroke-width="2"/>
                  </svg>
                </button>

                <button type="button" class="btn btn-next" @click="goNext">
                  <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.69884 26.728L14.4268 14.0001L1.69884 1.2722" stroke="white" stroke-width="2"/>
                  </svg>
                </button>
                <div class="div-path">
                  <!-- Set Current Path -->
                  <nav
                    style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                    aria-label="breadcrumb">
                    <ol class="breadcrumb m-auto">
                      <li v-for="(item, index) in folderPath" v-bind:key="index"
                          class="breadcrumb-item"
                          :class="{active: index === path.length - 1}"
                          v-bind="{ 'aria-current': index === path.length - 1 ? 'page' : null }">
                        <a href="#" class="" @click.prevent="goMove(item)">
                          <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 4C0 1.79086 1.79086 0 4 0H10.3712C12.5804 0 14.3713 1.79086 14.3713 4V9.12574H0V4Z" fill="#EAEAEA"/>
                            <rect y="2.44312" width="28.7425" height="21.5569" rx="6" fill="#AAABAB"/>
                          </svg>
                          <span>{{ item.name }}</span>
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            <div class="modal-header border-bottom-0 d-block"
                 v-if="mode === 'open' || mode === 'import' || (mode === 'save' && $parent.$parent.isDotAdmin())">
              <!-- Select Drive -->
              <button type="button"
                      class="btn btn-drive"
                      :class="{active: type === 'P'}"
                      @click="selectSchoolDrive">
                {{ `${userName}'s Drive` }}
              </button>
              <button type="button"
                      class="btn btn-drive"
                      :class="{active: type === 'O'}"
                      @click="selectDotDrive">
                {{ $t('공용 드라이브') }}
              </button>
            </div>
            <div class="div-contents">
              <!-- Item List -->
              <div class="contents-list flex-fill" v-if="dataLoaded">
                <div class="contents-item" v-for="item in groupInfo.items" :key="item.no"
                     role="listitem"
                     :class="{active: item.no === selectedNode.no}"
                     @click="selectNode(item)">
                  <div class="folder" v-if="item.type === 'folder'">
                    <svg width="84" height="70" viewBox="0 0 84 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 10C0 4.47715 4.47715 0 10 0H32C37.5228 0 42 4.47715 42 10V26.6168H0V10Z" fill="#EAEAEA"/>
                      <rect y="7.12573" width="84" height="62.8742" rx="15" fill="#AAABAB"/>
                    </svg>
                    <p class="name text-center">{{ item.name }}</p>
                    <p class="item-count text-center">{{ item.file_count }} items</p>
                  </div>
                  <div class="file" v-else>
                    <canvas class="card-img-top bg-white rounded-5 mx-auto"
                            :id="`canvas-${item.no}-0`"
                            v-bind:aria-label="item.DTM_DESC" role="img"/>
                    <p class="name text-center">{{ item.name }}</p>
                  </div>
                </div>
              </div>
              <!-- Add New Folder -->
            </div>
            <!-- Set Button Area -->
            <div class="div-create-folder" v-if="mode === 'save' || mode === 'saveAs'">
              <button type="button" class="btn-create-folder" @click="createFolder">{{ $t('새 폴더 만들기') }}</button>
            </div>
            <div class="div-button-area">
              <div v-if="mode === 'save' || mode === 'saveAs'">
                <input type="text" class="text-folder-name" :placeholder="this.$t('파일명을 입력하세요')" v-model="saveFileName"/>
              </div>
              <div class="div-active">
                <button type="button" class="btn btn-cancel flex-fill" data-bs-dismiss="modal" @click="closeDialog">
                  {{ $t('취소') }}
                </button>
                <button type="submit" class="btn btn-action flex-fill" data-bs-dismiss="modal" ref="confirmButton" :disabled="isValidSaveFileName()">
                  {{ $t(btnLabel[mode]) }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {$axios, $session} from "dot-framework";
import DTMSCanvas from "@/js/DTMSCanvas";

export default {
  name: "FileBrowser",
  props: {
    mode: String,
    web_mode: String
  },
  data() {
    return {
      WEB_MODE:"",
      DRIVE_NAME:"",
      btnLabel: {
        open: "열기",
        import: "가져오기",
        saveAs: "저장",
        save: "저장",
      },
      // 현재 선택된 그룹 정보
      groupInfo: {
        groupNo: '',
        groupName: '',
        items: [], // 해당 폴더의 하위 오브젝트 리스트
      },
      selectedNode: {
        no: '',
        name: '',
      },
      path: [],
      nextPath: [], // prev를 이용했을때 경로기록
      folderPath: [], // 현재 폴더 경로
      dataLoaded: false,
      type: 'P',
      userName: $session.getUserInfo().NAME,
      saveFileName: ''
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
    // 웹모드
    if (this.web_mode === "CLASSROOM") {
      this.DRIVE_NAME = "School Drive";
    } else if (this.web_mode === "CANVAS") {
      this.DRIVE_NAME = "Private Drive";
    }

    // 현재 오픈된 DTMS 그룹 기준 조회.
    // if (this.mode === 'saveAs') {
    //   this.groupInfo.groupNo = this.$parent.$parent.dtmsOpenData.dtmsGroupNo;
    //   this.groupInfo.groupName = 'Location';
    // } else {
      this.groupInfo.groupNo = 'ROOT';
      this.groupInfo.groupName = '/';
      this.path.push({no: 'ROOT', name: '/'});
      this.folderPath.push({no: 'ROOT', name: '/'});
    // }
    this.getDtmsGroupList(this.groupInfo.groupNo, '');
  },
  unmounted() {
    // ⚠️ 컴포넌트 제거 됐을 때 동작
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === "Escape") {
        this.closeDialog();
      } else if (event.key === "Enter") {
        this.onClickConfirmButton();
      }
    },
    async getDtmsGroupList(dtmsGroupNo){
      await this.getDtmsGroupListAxios(dtmsGroupNo);

      for(let item of this.groupInfo.items){
        this.createDTMSThumbnail(item);
      }
    },
    async getDtmsGroupListAxios(dtmsGroupNo) {
      const compNo = $session.getCompNo();
      const userNo = $session.getUserNo();
      let url = "";
      let params = "";

      if (this.web_mode === "CLASSROOM") {
        url = `/class-app/v1/comps/${compNo}/dtmsgroup`;
        params = `?PAGE_NO=1&PAGE_SIZE=1000&USER_NO=${userNo}&PARENT_GROUP_NO=${dtmsGroupNo}&USER_GUBUN=O&DATA_TYPE=${this.type}`;
      } else if (this.web_mode === "CANVAS") {
        url = "/vision-app/v1/dtms/groups";
        params = `?PARENT_GROUP_NO=${dtmsGroupNo}`;

        if (compNo !== null) {
          params += `&COMP_NO=${compNo}`;
        }

        if(this.type === "P"){
          params += `&DRIVER_KIND=P&USER_NO=${userNo}`;
        } else if(this.type === "O"){
          params += "&DRIVER_KIND=D";
        }
      }

      let response = await $axios.get(url + params);

      if (response.status === 200) {
        // console.log("선택된 그룹의 하위 폴더 목록 가져옴.");

        this.groupInfo.items = [];
        for (const group of response.data.items) {
          let item = {};

          if(this.web_mode === "CLASSROOM") {
            item = {
              type: 'folder',
              name: group.GROUP_NAME,
              no: group.DTMS_GROUP_NO,
            };
          } else if(this.web_mode === "CANVAS") {
            if(group.FILE_TYPE === "FOLDER"){
              item = {
                type: 'folder',
                name: group.FILE_NAME,
                no: group.FILE_KEY,
                file_count: group.FILE_COUNT
              };
            } else if(group.FILE_TYPE === "FILE"){
              item = {
                type: 'file',
                name: group.FILE_NAME,
                no: group.FILE_KEY,
                groupNo: group.PARENT_GROUP_NO,
                dtmsJson: group.DTMS_JSON
              };
            }
          }

          this.groupInfo.items.push(item);
        }

        // FileBrowser Open or Import 모드에서 열린 경우에만 파일 목록을 가져옴.
        if (this.mode === "open" || this.mode === "import") {
          if(this.web_mode === "CLASSROOM") {
            await this.getGroupItems(dtmsGroupNo);
          } else if(this.web_mode === "CANVAS") {
            this.dataLoaded = true;
          }
        } else {
          this.dataLoaded = true;
        }
      }
    },
    async getGroupItems(groupNo) {
      const compNo = $session.getCompNo();

      let url = "";
      let params = "";

      if(this.web_mode === "CLASSROOM") {
        url = `/class-app/v1/comps/${compNo}/dtms`;
        params = `?PAGE_NO=1&PAGE_SIZE=1000&DTMS_GROUP_NO=${groupNo}&DATA_TYPE=${this.type}`;
      } else if(this.web_mode === "CANVAS") {
        url = "/vision-app/v1/dtm/images";
        params = `?PAGE_NO=1&PAGE_SIZE=1000&DEVICE_KIND=300&DTM_GROUP_NO=${groupNo}`;
      }

      let response = await $axios.get(url + params);
      if (response.status === 200) {
        // console.log("해당 그룹의 아이템 목록 가져옴");
        for (const file of response.data.items) {
          let item = {};
          if(this.web_mode === "CLASSROOM") {
            item = {
              type: 'file',
              name: file.FILE_NAME,
              no: file.DTMS_FILE_NO,
            };
          } else if(this.web_mode === "CANVAS") {
            item = {
              type: 'file',
              name: file.DTM_NAME,
              no: file.DTM_NO,
              groupNo: file.DTM_GROUP_NO
            };
          }

          this.groupInfo.items.push(item);
        }
        this.dataLoaded = true;
      }
    },
    selectSchoolDrive() {
      this.type = 'P';
      this.path = [{no: 'ROOT', name: '/'}];
      this.folderPath = [{no: 'ROOT', name: '/'}];
      this.initGroupInfo();
      this.getDtmsGroupList(this.groupInfo.groupNo);
    },
    selectDotDrive() {
      this.type = 'O';
      this.path = [{no: 'ROOT', name: '/'}];
      this.folderPath = [{no: 'ROOT', name: '/'}];
      this.initGroupInfo();
      this.getDtmsGroupList('ROOT');
    },
    initGroupInfo() {
      if (this.mode === "open" || this.mode === "import" || this.mode === "save" || this.mode === "saveAs") {
        this.groupInfo.groupNo = 'ROOT';
        this.groupInfo.groupName = '/';
        this.groupInfo.items = [];
      } else {
        this.groupInfo.groupNo = this.$parent.$parent.dtmsOpenData.dtmsGroupNo;
        this.groupInfo.groupName = '';
        this.groupInfo.items = [];
      }

    },
    selectNode(node) {
      this.selectedNode = node;

      if (node.type === 'folder') {
        // 현재 그룹 정보를 부모 노드로 설정
        this.groupInfo.groupName = node.name;
        this.groupInfo.groupNo = node.no;
        this.path.push({no: node.no, name: node.name});
        this.nextPath = [];
        this.getFolderPath();
        if(this.web_mode === "CLASSROOM"){
          if (this.type === 'P') {
            // 폴더 표시 오류 수정 (2022-11-09, 정태화)
            //this.getDtmsGroupList(node.no);
            this.groupInfo.items = [];
            this.getGroupItems(this.groupInfo.groupNo);
          } else {
            this.groupInfo.items = [];
            this.getGroupItems(this.groupInfo.groupNo);
          }
        } else if(this.web_mode === "CANVAS"){
          this.getDtmsGroupList(node.no);
        }

      }
    },
    goPrev() {
      if (this.path.length > 1) {
        // prev시 이동 경로 기록
        this.nextPath.push(this.path[this.path.length - 1]);

        // 이전으로 돌아가면서 마지막 기록 삭제
        this.path.pop()
        this.goPath();
      }
    },
    goNext(){
      if (this.nextPath.length > 0) {
        // 기록해둔 이동 경로 가져오기
        this.path.push(this.nextPath[this.nextPath.length - 1]);
        this.nextPath.pop();

        this.goPath();
      }
    },
    goMove(data){
      this.path.push(data);
      this.nextPath = [];
      this.goPath();
    },
    async goPath() {
      const parent = this.path[this.path.length - 1];
      this.groupInfo.groupNo = parent.no;
      this.groupInfo.groupName = parent.name;
      this.getDtmsGroupList(parent.no);

      await this.getFolderPath();
    },
    // TODO CLASSROOM용 추가 필요
    async getFolderPath() {
      const parent = this.path[this.path.length - 1];
      const query = '?DTM_GROUP_NO=' + parent.no + '&USER_NO=' + $session.getUserNo() + '&DRIVER_KIND=' + (this.type === "O"? "D":"P");
      const url = `/vision-app/v1/dtms/group/path${query}`;
      const promise = $axios.get(url);
      await promise.then((response) => {
        if (response.status === 200) {
          this.folderPath = [];
          for (const group of response.data.items) {
            let item = {};
            item = {
              type: 'folder',
              name: group.DTM_GROUP_NAME,
              no: group.DTM_GROUP_NO
            };

            this.folderPath.push(item);
          }
        }
      });
    },
    onClickConfirmButton() {
      switch (this.mode) {
        case "saveAs":
        case "save":
          this.saveAs();
          break;
        case "open":
        case "import":
          this.$parent.type = this.type;
          this.$emit('get-dtms-detail', this.selectedNode.no, this.type, this.selectedNode.groupNo, this.selectedNode.name);
          this.$emit('close');
          break;
      }

      if (this.mode === "import") {
        this.$parent.$parent.isCanvasDirty = true;
      }
    },
    saveAs() {
      const newName = this.saveFileName;

      if (newName === '') {
        alert(this.$t('파일명을 입력하세요'));
        return;
      }

      this.$parent.$parent.dtms.title = newName;

      this.$parent.$parent.uploadDTMS(this.groupInfo.groupNo, this.mode);

      this.$emit('close');
    },
    closeDialog() {
      this.$emit('close');
    },
    createDTMSThumbnail(item) {
        const DTMSJson = item?.dtmsJson;

        if (DTMSJson) {
            const items = DTMSJson.items;
            const canvasWidth = 84;
            const canvasHeight = 70;

            const pixelArr = DTMSCanvas.getPixelArray(items[0]);
            const params = {
                pixelArr: pixelArr,
                canvasId: `canvas-${item.no}-0`,
                canvasWidth,
                canvasHeight
            };
            const canvas = DTMSCanvas.createCanvas(params.canvasId, params.canvasWidth, params.canvasHeight);
            canvas.add(DTMSCanvas.drawTactileDataOnTheCanvas(params.pixelArr, params.canvasWidth, params.canvasHeight));
        }
    },
    async createFolder() {
      //현위치에 Untitled 폴더 생성
      const url = `/vision-app/v1/dtm/groups`;
      let params = {};
      Object.assign(params, {
          "DTM_GROUP_NAME": "New Folder",
          "PARENT_GROUP_NO": this.groupInfo.groupNo,
          "USER_NO":$session.getUserNo(),
          "DRIVER_KIND":'P'
      });
      await $axios.post(url, params).then((response) => {
        if (response.status === 201) {
          this.getDtmsGroupList(this.groupInfo.groupNo);
        }
      });
    },
    isValidSaveFileName() {
      const specialCharacters = /[\\/:*?"<>|]/;
      const emptyStringPattern = /^\s*$/;

      if(!(this.mode === "save" || this.mode === "saveAs")) {
        return false;
      }

      if (emptyStringPattern.test(this.saveFileName)) {
        return true;
      } else if (specialCharacters.test(this.saveFileName)) {
        return true;
      }

      return false;
    },
  }
}
</script>

<style scoped>
#fileBrowser {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1081;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/* 레이아웃 위치 변경 (2022-11-09, 정태화) */
#fileBrowser .modal-body {
  padding: 0 !important;
}
#fileBrowser .modal-body form {
  padding: 30px;
}

/* 레이아웃 위치 변경 (2022-11-09, 정태화) */
#fileBrowser .modal-header {
  padding: 0 !important;
  width: auto;
}
.modal-dialog, .modal-content, .modal-body{
  /* max-width: 892px;
  width: 892px; */
  max-width: 600px;
}

.modal-content {
  background: #F7F7F7;
  border-radius: 40px;
}

.btn-drive {
  /* padding-top: 0;
  padding-bottom: 0; */
  font-size: 1.5rem;
  color: #44403F;
  width: 250px;
  height: 48px;
  border-radius: 60px;
  background: #EAEAEA;
  margin:20px 20px 20px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.modal-header > button:nth-last-child(1) {
  margin-right: 0;
}
/*
.btn-drive:active {
  color: #EAEAEA;
  background: #44403F;
} */

.btn-drive.active {
  background-color: #EA5414;
  color: white;
}

.div-button-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0 20px;
}

.btn-cancel {
  width: 160px;
  height: 64px;
  color:#FFFFFF;
  background: #AAABAB;
  border-radius: 60px;
  font-size: 24px;
  line-height: 13px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;
}

.btn-cancel:active {
  color:#AAABAB;
  background: #FFFFFF;
}

.btn-action {
  width: 160px;
  height: 64px;
  color:#FFFFFF;
  background: #EA5414;
  border-radius: 60px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 13px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.05em;
}

.btn-action:active {
  color:#EA5414;
  background: #FFFFFF;
}

/* .btn-prev, .btn-next {
  width: 64px;
  height: 64px;
  background: #AAABAB;
  border-radius: 32px;
  margin-right:35px;
} */

.div-move-btn {
  width: 100%;
  /* float: left; */
}

.div-move-btn button {
  width: 40px;
  height: 40px;
  background: #aaabab;
  border-radius: 32px;
  margin-right: 10px;
}

.div-move-btn button:active {
  border-color:#AAABAB;
}
.div-move-btn button:active > svg > path {
  stroke:#AAABAB;
}

.div-active button {
  margin-right:10px;
  height: 48px;
  width: 128px;
  font: 1.5rem 'Helvetica';
}
.div-active button:nth-last-child(1) {
  margin-right: 0;
}

.div-contents {
  height: 419px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.contents-list {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  /* padding-top: 40px; */
  height: 100%;
}

.contents-item {
  width:calc(100% / 3);
  height: 146px;
  cursor:pointer;
}
.contents-item .folder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contents-item .file {
  text-align: center;
  margin-top: 15px;
  .rounded-5{
    border-radius: 15px !important;
  }
}
.contents-list > .active {
  border: 2px solid #EA5414;
  border-radius: 1rem;
}

.contents-item .name {
  font-size:1.2rem;
  /* line-height: 1.2rem; */
  margin: 8px auto 0 auto;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.contents-item .item-count {
  font-size:1rem;
  color: #AAABAB;
  /* margin-top: 5px; */
  line-height: 1rem;
  margin-bottom: 0;
}
.folder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.file canvas {
  width: 84px;
  height: 70px;
}
.div-path:after {
  content: "";
  clear: both;
  display: block;
}

.div-path {
  float: right;
  background: #FFFFFF;
  border-radius: 40px;
  /* margin:10px; */
  /* margin-top:41px; */
  height: 40px;
  width: 435px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.div-path > nav {
  padding-left: 20px;
}
.breadcrumb-item{
  font-size: 24px;
  color: #44403F;
}

.breadcrumb-item > a {
  text-decoration-line: none;
}

.breadcrumb-item > a > span {
  margin-left: 15px;
   /* identical to box height */
   letter-spacing: -0.05em;
   line-height: 13px;
   color: #44403F;
   vertical-align: middle;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/");
}

.div-create-folder {
  margin:10px;
  text-align: center;
}

.text-folder-name {
  width: 250px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 40px;
  border:0;
  font-size: 1.2rem;
  padding-left:20px;
  margin-right: 20px;
}

.btn-create-folder {
  width: 220px;
  height: 48px;
  background: #EA5414;
  border-radius: 60px;
  border:0;
  color:#FFFFFF;
  font-size: 24px;
  margin-top: 60px;
}

</style>
