// 디폴트 옵션값 처리
let options_default = {
    showMenu: true,
    showPage: true,
    showText: true,
    showBrailleBtn: true,
    menu: {
      new: true,            // 새로 만들기 버튼
      open: true,           // 불러오기 버튼
      add: true,            // 추가하기 버튼
      import: true,         // Import 버튼
      upload: true,         // Upload 버튼
      save: false,          // 저장 버튼
      saveAs: false,        // 다른이름으로 저장 버튼
      delete: false,        // 삭제버튼
      export: false,        // Export 버튼
      padPrint: true,       // 닷패드 출력
      remoteSend: false,    // (클래스룸) 원격전송
      participant: false,   // (클래스룸) 참가자표시
    }
};

const Options =
{
    // 옵션값 설정
    setOptions(options)
    {
        // optons객체 clone
        let optionsClone = {...options};
        if (options.menu) {
            // menu 배열 항목 복사 (같은 속은은 덮어쓰기)
            Object.assign(options_default.menu, optionsClone.menu)
            // menu 배열 항목 삭제
            delete optionsClone.menu;
        }

        // options의 모든 속성을 options_default 복사 (같은 속성은 덮어쓰기)
        let result = Object.assign(options_default, optionsClone);
        return result;
    }
}

export {
    Options
}
