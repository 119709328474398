import {Line} from "./Line";

class SolidLine extends Line {
  constructor(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio) {
    super(startPoint, endPoint, VOXEL_ROW_NUM, VOXEL_COL_NUM, keepRatio);
  }

  figureDraw() {
    return this.drawLine(1, 0);
  }
}

export {
  SolidLine
};
